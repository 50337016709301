import { Banner } from "component/banner.js"
import { Narrow, Pad, PadBox } from "component/basics.js"
import { DemoHeader, DemoSection, SpacedArray } from "system/demo.js"
import { Heading, UtilityText } from "component/text.js"
import { VideoPlayerStateContextProvider } from "../../contrib/zdf/videoPlayerStateContext";
import { View } from "react-native";
import { SimpleVideo } from "../../contrib/zdf/videoPlayer";
import { OverlayScreen } from "../../contrib/zdf/overlay";
import { colorGreyPopupBackground } from "component/color.js"
import { RichText } from "component/richtext.js"

export function PositionableVideoOverlayDemo() {
    return (
        <Narrow>
            <DemoHeader strong text="Positionable Video Overlay Demo" />
            <Pad/>
            <DemoSection text="VideoPlayerStateContextProvider & OverlayScreen">
                <VideoPlayerStateContextProvider>
                    <View style={{height: 300}}>
                        <SimpleVideo
                            src={
                                "https://archive.org/download/BigBuckBunny_124/Content/big_buck_bunny_720p_surround.mp4"
                            }
                            controls
                        />

                        <OverlayScreen>
                            <View style={{ backgroundColor: "white" }}>
                                <UtilityText text={"Default position"} />
                            </View>
                        </OverlayScreen>

                        <OverlayScreen position={{ left: "50px", top: "50px" }}>
                            <View style={{ backgroundColor: "white" }}>
                                <UtilityText text={"Position: left: 50px top: 50px"} />
                                <UtilityText text={"Dimension adapts to content"} />
                            </View>
                        </OverlayScreen>

                        <OverlayScreen position={{ left: "50px", top: "100px" }} dimension={{ height: "100px" }}>
                            <View style={{ height: "100%", backgroundColor: "white" }}>
                                <UtilityText text={"Position: left: 50px top: 100px"} />
                                <UtilityText text={"Dimension: height: 100px"} />
                                <UtilityText text={"Dimension is fixed"} />
                            </View>
                        </OverlayScreen>

                        <OverlayScreen position={{ left: "300px", top: "50px" }} dimension={{ width: "75px" }}>
                            <View style={{ backgroundColor: "white" }}>
                                <UtilityText text={"Position: left: 300px top: 50px"} />
                                <UtilityText text={"Dimension: width: 75px"} />
                            </View>
                        </OverlayScreen>

                        <OverlayScreen
                            position={{ right: "0%", top: "10%" }}
                            dimension={{ width: "20%", height: "60%" }}
                        >
                            <View style={{ height: "100%", backgroundColor: "white" }}>
                                <UtilityText text={"Position: right: 0% top: 10%"} />
                                <UtilityText text={"Dimension: width: 20% height: 60%"} />
                            </View>
                        </OverlayScreen>
                    </View>
                </VideoPlayerStateContextProvider>
            </DemoSection>
            <Banner color={colorGreyPopupBackground}>
                <SpacedArray pad={10}>
                    <Heading text="How to use OverlayScreen" />
                    <UtilityText text={"position - Specify the position of the overlay in px or %"} />
                    <UtilityText
                        text={
                            "dimension - Specify height or width to set a fixed size in px or %. "
                        }
                    />
                    <UtilityText text={"useIframe - determines if communication to the outer iframe should be done"} />
                    <UtilityText text={"pointerEvents - should the iframe be click through or not (true or false)"} />
                    <UtilityText text={"visible - should the iframe be visible or not"} />
                    <UtilityText text={"showBackdrop - should a semi transparent backdrop be shown (true or false)"} />
                    <RichText text="repositionIframe - **Caution:** repositionIframe will cause the whole PSI iframe to act as the inner container" />
                </SpacedArray>
            </Banner>
        </Narrow>
    );
}
