export const ui_human_translations_french = {
    // Mod Dashboard
    "My bookmarks": "Mes marqués",
    "Manually rejected ": "Rejeté manuellement ",
    "Today": "Aujourd'hui",
    "Yesterday": "Hier",
    "Channels:": "Rédaction :",
    "Open Threads": "Ouvert",
    "Closed Threads": "Fermé",
    "Heated Threads": "Intense",
    "Threads with flagged comments": "Commentaires signalés",
    "Open": "Ouvert",
    "Closed": "Fermé",
    "Newest": "Plus récents",
    'Oldest': 'Plus anciens',
    "Heated": "Intense",
    "Author: {author}": "Auteur : {author}",
    "Back to threads": "Retour aux fils de discussion",
    "{numArticles} linked stories": "{numArticles} articles liés",
    "Linked stories": "Articles liés",
    "Origin:": "Origine :",
    "No origin available": "Aucune origine disponible",
    "View": "Voir",
    "Awaiting decision": "En attente de décision",
    "Automatically approved": "Approuvé automatiquement",
    "Manually approved": "Approuvé manuellement",
    "Load more": "Charger plus",
    "No comments": "Pas de commentaires",
    "No comments.": "Aucun commentaire.",
    "Message to moderator": "Message au modérateur",
    "Comment information": "Information sur le commentaire",
    "Submitted": "Soumis",
    "Comment ID": "ID du commentaire",
    "Status": "Statut",
    "Internal Notes": "Notes internes",
    "View in thread": "Voir dans le fil de discussion",
    "Add your notes...": "Ajoutez vos notes...",
    "User report": "Rapport utilisateur",
    "📌 Community guideline violation": "📌 Violation des règles communautaires",
    "Reported by": "Signalé par",
    "Reported by users": "Signalé par les utilisateurs",
    "{count} users": "{count} utilisateurs",
    "User information": "Informations utilisateurs",
    "Member since ": "Membre depuis ",
    "AI flag": "Signalé par l'IA",
    "comments & replies": "Commentaires & réponses",
    "rejected comments": "Commentaires rejetés",
    "reported comments": "Commentaires signalés",
    "flagged comments": "Commentaires marqués",
    "user warnings": "Avertissements à l'utilisateur",
    "comment & reply": "Commentaire & réponse",
    "rejected comment": "Commentaire rejeté",
    "reported comment": "Commentaire signalé",
    "flagged comment": "Commentaire marqué",
    "user warning": "Avertissement à l'utilisateur",
    "Warnings": "Avertissements",
    "Back to selection": "Retour à la sélection ",
    "Which community guideline does this comment violate?": "Quelle règle communautaire ce commentaire enfreint-il?",
    "Want to do anything else?": "Vous voulez faire autre chose?",
    "No thanks": "Non merci",
    "Warn user": "Avertir l'utilisateur",
    "User has already been warned {count} {noun}.": "L'utilisateur a déjà été averti {count} {noun}.",
    "time": "fois",
    "times": "fois",
    "Show user profile": "Afficher le profil de l'utilisateur",
    "Block user": "Bloquer l'utilisateur",
    "User has already been warned {count} {noun} and was blocked by {blockedByName}.": "L'utilisateur a déjà été averti {count} {noun} et bloqué par {blockedByName}.",
    "A user must be warned 2 times before they can be blocked.": "Un utilisateur doit être averti 2 fois avant d'être bloqué.",
    "Block this user?": "Bloquer cet utilisateur?",
    "Do you really want to block this user? You can unblock the user via the profile page.": "Voulez-vous vraiment bloquer cet utilisateur? Vous pouvez débloquer l'utilisateur via la page de profil.",
    "Unblock this user?": "Débloquer cet utilisateur?",
    "Do you really want to unblock this user?": "Voulez-vous vraiment débloquer cet utilisateur?",
    "Blocked": "Bloqué",
    "Warned": "Averti",
    "Unblock user": "Débloquer l'utilisateur",
    "User Profile": "Profil de l'utilisateur",
    "User Settings": "Paramètres",
    "Rejected by": "Rejeté par",
    "Show less": "Montrer moins",
    "Show more": "Afficher plus",
    "Back to comment": "Retour aux commentaires",
    "You are reviewing": "Vous vérifiez",
    "{viewerName} is reviewing": "{viewerName} vérifie actuellement",
    "Only one moderator at a time can work on a comment.": "Un seul modérateur à la fois peut travailler sur un commentaire.",
    "PSI Moderation": "Modération PSI",
    "Threads": "Fils de discussion",
    "Comments": "Commentaires",
    "No user logged in": "Aucun utilisateur connecté",
    'Just now': "À l'instant",
    'Moderation Queue': "File d'attente de modération",
    'Most recent': 'Plus récents',
    "Settings": "Paramètres",
    "Read article": "Consulter l'article",
    "AI review found that your perspective label may be incompatible with the following [Community Guidelines]($onPress): ":"La révision par l'IA a déterminé que votre perspective pourrait être incompatible avec les règles de la [communauté suivantes]($onPress) : ",
    "Your response has been sent to a moderator for review.":"Votre réponse a été envoyée au modérateur pour révision.",
    "What guideline does this violate? Select all that apply.":"Quelle règle de la communauté a été enfreinte? Sélectionnez toutes les options applicables.",
    "Updating...":"Mise à jour en cours...",
    "This discussion is temporarily unavailable. Check back for updates.":"Cette conversation est temporairement indisponible. Revenez plus tard pour des mises à jour.",
    "This custom name is incompatible with our Community Guidelines.":"Ce pseudonyme ne respecte pas les règles de la communauté.",
    "Sent to moderator: {time}":"Envoyé au modérateur : {time}",
    "Sending...":"Envoi en cours...",
    "Send to a moderator for review":"Envoyer à un modérateur pour révision",
    "Send an email to [privacy@newpublic.org](mailto:privacy@newpublic.org) to request a copy of your data associated with this discussion space. It may take up to 30 days to process your request.":"Envoyez un courriel à [privacy@newpublic.org](mailto:privacy@newpublic.org) pour demander une copie de vos données associées à cet espace de conversation. Le traitement de votre demande peut prendre jusqu'à 30 jours.",
    "Saving...":"Sauvegarde en cours...",
    "Save":"Sauvegarder ",
    "Relatable":"Je m'identifie",
    "Provide sources for factual claims":"Fournissez des sources qui étayent vos propos ",
    "Logging in...":"Connexion en cours...",
    "Let's talk about...":"Participez à la conversation",
    "It's complicated":"C'est compliqué",
    "I agree to the Community Guidelines, Privacy Notice, and Terms of Service.":"J'accepte les règles de la communauté, la politique de confidentialité et les conditions d'utilisation.",
    "I agree to the [Community Guidelines]({communityGuidelinesLink}), [Privacy Notice]({privacyNoticeLink}), and [Terms of Service]({termsOfServiceLink}).":"J'accepte les [règles de la communauté]({communityGuidelinesLink}), la [politique de confidentialité]({privacyNoticeLink}), et les [conditions d'utilisation]({termsOfServiceLink}).",
    "Focus on ideas, rather than character judgments":"Concentrez-vous sur les idées, plutôt que sur les jugements de valeur",
    "Discussion History":"Historique des conversations",
    "Did AI get it wrong?":"L'IA a-t-elle fait une erreur?",
    "Deleting profile data. Please wait...":"Suppression des données du profil en cours. Veuillez patienter...",
    "Custom names can only be changed once per week.":"Les pseudonymes ne peuvent être modifiés qu'une fois par semaine.",
    "Custom names are not allowed to look like real names.":"Les pseudonymes ne doivent pas ressembler à de vrais noms.",
    "Consider rewording your response:":"Pensez à reformuler votre réponse :",
    "Community Guidelines":"Règles de la communauté",
    "Choose a name and photo that appears when you post in discussions.":"Choisissez un nom et une photo qui apparaissent lorsque vous publiez dans des conversations.",
    "AI review found that your response may be incompatible with the following [Community Guidelines]($onPress): ":"La révision par l'IA a déterminé que votre publication pourrait être incompatible avec les règles de la [communauté suivantes]($onPress) : ",    
    "AI helps us moderate comments, but sometimes it can make a mistake. If AI is preventing you from sharing a respectful opinion, send your comment to a moderator for review.":"L'intelligence artificielle (IA) nous aide à modérer les commentaires, mais elle peut parfois faire des erreurs. Si l'IA vous empêche de partager une opinion respectueuse, veuillez soumettre votre commentaire à un modérateur pour révision.",
    "Add a custom name":"Ajouter un pseudonyme",
    "🔍 Under Review":"🔍 Révision en cours",
    "**Share details** (required)":"**Partager des détails** (obligatoire)",
    "To maintain a welcoming and respectful environment, this space uses AI to moderate comments. AI may flag your post for your additional review if it does not follow the community guidelines. ":"Pour maintenir un environnement accueillant et respectueux, cet espace utilise l'intelligence artificielle (IA) pour aider à modérer les publications. L'IA peut signaler votre publication pour une révision supplémentaire si elle ne respecte pas les règles de la communauté. ",    
    "To maintain a welcoming and respectful environment, this space uses AI to moderate comments. AI may flag your post for your additional review if it does not follow the community guidelines. Learn more.":"Pour maintenir un environnement accueillant et respectueux, cet espace utilise l'intelligence artificielle (IA) pour aider à modérer les publications. L'IA peut signaler votre publication pour une révision supplémentaire si elle ne respecte pas les règles de la communauté. En savoir plus.",    
    "Learn more.": "En savoir plus.",
    "Add":"Ajouter",
    "Add your own":"Ajouter",
    "Hello everyone!": "Assistant IA",
    "This feature is not implemented yet." : "Cette fonctionnalité n'est pas encore mise en œuvre.",
    "Your Account":"Votre profil",
    "Open Admin Dashboard": "Ouvrir le tableau de bord (admin)",
}