export const firebaseConfig = {
  apiKey: "AIzaSyDX5DlODMm8gYtqT3fj6MuscRlFcys-fDc",
  authDomain: "psi-product-zdfd-deploy-86085.firebaseapp.com",
  databaseURL: "https://psi-product-zdfd-deploy-86085-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "psi-product-zdfd-deploy-86085",
  storageBucket: "psi-product-zdfd-deploy-86085.appspot.com",
  messagingSenderId: "241852901604",
  appId: "1:241852901604:web:da5a2eb5655e46da8524f1"
};


export const deploymentConfigMap = {
  local: {
      host: `http://${window.location.hostname}:5000`,
      firebaseConfig: firebaseConfig,
      emulateFirebase: true,
      defaultToAdminDash: true,
      adminEmailDomain: 'admin.org',
      ssoDebug: true,
      storagePrefix: 'https://firebasestorage.googleapis.com/v0/b/psi-product-zdfd-deploy-86085.appspot.com/o/',
  },
  production: {
      host: 'https://psi.zdf.digital',
      firebaseConfig: firebaseConfig,
      storagePrefix: 'https://firebasestorage.googleapis.com/v0/b/psi-product-zdfd-deploy-86085.firebasestorage.app/o'
  },
};

export const domainToDeploymentMap = {
  'psi.zdf.digital': 'production',
  'localhost': 'local',
  'test': 'production',
};
