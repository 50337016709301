import { ChevronLeft, FaceDissatisfied, FaceSatisfied, Locked, User, ViewFilled } from "@carbon/icons-react";
import React, { useContext, useEffect, useState } from "react";
import { StyleSheet } from "react-native";
import { View } from "react-native-web";
import { GUIDELINES } from "../../../feature/question/CommunityGuidelinesFeature";
import { ToolTip } from "../tooltip";
import { useParentDatastore } from "../parentdatastore";
import { useDatastore, usePersonaKey, usePersonaObject, useServerCallResult } from "../../../util/datastore";
import { colorBlack, colorBlackHover, colorGreen, colorNearBlack, colorPink, colorRed, colorTextBlue, colorTextGrey, colorWhite } from "../../color";
import { CTAButton, SubtleButton, TextButton } from "../../button";
import { HorizBox, Pad, PadBox, Separator } from "../../basics";
import { Heading, UtilityText } from "../../text";
import { Checkbox } from "../../form";
import { Banner } from "../../banner";
import { Modal } from "../../modal";
import { FaceImage, LetterFace } from "../../people";
import { useInstanceParams } from "../../../util/params";
import { gotoInstance } from "../../../util/navigate";
import { setToastMessage } from "../toast";
import { translateLabel } from "../../translation";

const JudgementCardStyle = StyleSheet.create({
    elevatedCard: {
        backgroundColor: colorWhite,
        width: "fit-content",
        height: "fit-content",
        display: "flex",
        flexDirection: "column",
        borderRadius: 8,
        padding: 16,
        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
        borderColor: colorWhite,
        borderWidth: 1,
        gap: 12
    },
    guidelineColumn: {
        margin: 4,
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        justifyContent: "space-evenly"
    }
});


async function setJudgementAsync({ judgement, datastore, modTask, reasoning }) {
    await datastore.callServerAsync('moderation', 'setJudgement', {
        key: modTask.key, judgement, reasoning: reasoning || modTask.reasoning
    });
}

const JudgementFlowState = {
    JUDGE: "JUDGE",
    REJECTREASON: "REJECTREASON",
    WARNANDBLOCK: "WARNANDBLOCK"
}

export function JudgementCard({ modTask, modAuthor, taskWatchState, isSelected }) {
    const datastore = useDatastore();
    const parentDatastore = useParentDatastore()
    const personaKey = usePersonaKey();
    const amIViewing = taskWatchState?.amIViewing
    const [pageIndex, setPageIndex] = useState(JudgementFlowState.JUDGE);

    const [isReduced, setIsReduced] = useState(false);
        
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= window.screen.availWidth / 1.3) {
                setIsReduced(true);
            } else {
                setIsReduced(false);
            }
        };
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    async function onApprove() {
        try {
            // Reset warning for this comment if there is one to avoid giving multiple warnings per comment
            if (modTask.receivedWarning) {
                const userId = modAuthor.authorKey;

                await datastore.callServerAsync('moderationZdf', 'warnUser', {
                    key: userId, commentKey: modTask.key, receivedWarning: false
                });
            }
            await setJudgementAsync({ judgement: "approve", datastore, modTask, reasoning: "" });
            datastore.setSessionData(["preliminaryJudgement", null]);
            setToastMessage({datastore: parentDatastore, label: "The comment has been successfully approved", visible: true })
        } catch (e) {
            setToastMessage({datastore: parentDatastore, label: "Operation could not be executed, please try again", visible: true, type:"error" })
        }
    }

    function getShortenedName(name) {
        if (!name) return ""; 
        const parts = name.split(" ");
        if (parts.length === 1) {
            return parts[0];
        }
    
        const firstName = parts[0];
        const lastName = parts[parts.length - 1];
    
        const lastNameParts = lastName.split("-");
        const abbreviatedLastName = lastNameParts
            .map(part => `${part[0]}.`)
            .join("-");
    
        return `${firstName} ${abbreviatedLastName}`.trim();
    }    

    // Judgement card is visible if the user has selected it or someone else is working on it
    return (taskWatchState || isSelected) && <ToolTip
        label={modTask.deleted === true ? "You cannot work on a deleted comment." : "Only one moderator at a time can work on a comment."}
        disabled={(!taskWatchState || amIViewing || !isSelected) && !modTask.deleted}
    >
        <View style={[JudgementCardStyle.elevatedCard,
        {
            borderColor: isSelected ? colorBlack : colorWhite,
            backgroundColor: !taskWatchState || colorWhite,
        }]}>
            {pageIndex === JudgementFlowState.JUDGE && (
                <>
                    {taskWatchState && <HorizBox center gap={8}>

                    <View>
                        {taskWatchState?.photoUrl ? <FaceImage type={"tiny"} photoUrl={taskWatchState?.photoUrl} /> :
                        <LetterFace type={"tiny"} name={taskWatchState?.name} hue={taskWatchState?.hue}/>}
                    </View>
                        <UtilityText
                            color={colorTextBlue}
                            label={amIViewing ? "You are reviewing" : "{viewerName} is reviewing"}
                            formatParams={{
                                viewerName: !isReduced ? (taskWatchState?.name ?? "") : getShortenedName(taskWatchState?.name ?? "")
                            }}
                            type="tiny"
                            weight="medium"
                        />
                        {!isReduced && (amIViewing ? (<ViewFilled color={colorTextBlue} />) : (<Locked color={colorTextBlue} />))}
                    </HorizBox>}
                    {!modTask.deleted && isSelected && (
                        <>
                            {(modTask.judgement === "reject" || !modTask.judgement) && (
                                <CTAButton
                                    icon={<FaceSatisfied style={{ fill: colorGreen }} />}
                                    label="Approve"
                                    type="secondary"
                                    onPress={() => onApprove()}
                                    disabled={(taskWatchState && !amIViewing) || modTask.deleted}
                                    wide
                                />
                            )}
                            {(modTask.judgement === "approve" || !modTask.judgement) && (
                                <CTAButton
                                    icon={<FaceDissatisfied style={{ fill: colorRed }} />}
                                    label="Reject"
                                    type="secondary"
                                    onPress={() => setPageIndex(JudgementFlowState.REJECTREASON)}
                                    disabled={(taskWatchState && !amIViewing) || modTask.deleted}
                                    wide
                                />
                            )}
                        </>
                    )}
                </>
            )}
            {pageIndex === JudgementFlowState.REJECTREASON && (
                <RejectionPanel
                    onBack={() => setPageIndex(JudgementFlowState.JUDGE)}
                    onNext={() => setPageIndex(JudgementFlowState.WARNANDBLOCK)}
                    modTask={modTask}
                    modAuthor={modAuthor}
                />
            )}
            {pageIndex === JudgementFlowState.WARNANDBLOCK && (
                <WarnAndBlockPanel
                    onBack={() => setPageIndex(JudgementFlowState.REJECTREASON)}
                    onNext={() => setPageIndex(JudgementFlowState.JUDGE)}
                    modTask={modTask}
                    modAuthor={modAuthor}
                />
            )}
        </View>
    </ToolTip>
}

function RejectionPanel({ onBack, onNext, modTask, modAuthor }) {
    const datastore = useDatastore();
    const parentDatastore = useParentDatastore();
    const guidelineNames = [...Object.values(GUIDELINES), 'Other'];
    const [selectedGuidelines, setSelectedGuidelines] = useState({})

    async function onReject() {
        // Check if user is already blocked. If they are, skip the warn & block dialog and finish rejecting the comment immediately.
        // If not, set judgement and reasoning but don't update the queue yet. Otherwise, the comment will disappear from the current filter category before the warn/block dialog can be shown.

        const isBlocked = await datastore.callServerAsync("moderationZdf", "getIsUserBlocked", { key: modAuthor.authorKey });
        const reasoningsUntranslated = Object.entries(selectedGuidelines).filter(value => value[1] === true).map(value => value[0])
        const reasoningTranslated = reasoningsUntranslated.map((reasoning)=>translateLabel({label: reasoning,language:datastore.getLanguage()}))
        const preliminaryJudgement = {
            judgement: "reject",
            datastore,
            modTask,
            reasoning: JSON.stringify(reasoningTranslated),
        };

        try {
           if (isBlocked) {
            await setJudgementAsync(preliminaryJudgement);
            datastore.setSessionData(["preliminaryJudgement", null]);
            onBack();
            setToastMessage({datastore: parentDatastore, label: "The comment has been successfully rejected", visible: true })
            }
            else {
                // Save preliminary judgement in session data. Setting the final judgement here would move the mod task to another filter before moderators reach the warn & block dialog.
                datastore.setSessionData(["preliminaryJudgement"], preliminaryJudgement);
                onNext();
            } 
        } catch (error) {
            setToastMessage({datastore: parentDatastore, label: "Operation could not be executed, please try again", visible: true, type:"error" })
        }

        
    }

    return (
        <View style={JudgementCardStyle.guidelineColumn}>
            <SubtleButton icon={ChevronLeft} label={"Back to selection"} onPress={onBack} />
            <Pad size={12} />
            <UtilityText label={"Which community guideline does this comment violate?"} strong />
            <Pad size={12} />
            {guidelineNames.map((guidelineName, index) => (
                <Checkbox
                    label={guidelineName}
                    key={guidelineName}
                    value={selectedGuidelines[guidelineName]}
                    onChange={(isSelected) => {
                        setSelectedGuidelines(selection => ({...selection, [guidelineName]: isSelected}));
                    }}
                />
            ))}
            <Pad size={12} />
            <CTAButton
                wide
                label={"Reject"}
                disabled={Object.values(selectedGuidelines).filter(s => s === true).length <= 0}
                onPress={onReject}
            />
        </View>
    );
}

function WarnAndBlockPanel({ onBack, onNext, modTask, modAuthor }) {
    const datastore = useDatastore();
    const parentDatastore = useParentDatastore();
    const userId = modAuthor.authorKey;
    const currentModeratorKey = usePersonaKey();
    const currentModeratorPersona = usePersonaObject(currentModeratorKey);
    const params = useInstanceParams();

    const [showBlockConfirmationModal, setShowBlockConfirmationModal] = useState(false);

    let warningCount = useServerCallResult("moderationZdf", "getUserWarningCount", { key: userId });
    if (!warningCount) {
        warningCount = 0;
    }

    async function onWarn() {
        try {
            await datastore.callServerAsync('moderationZdf', 'warnUser', {
                key: userId, commentKey: modTask.key, receivedWarning: true
            });
            rejectCommentAsync();
            onNext();
            setToastMessage({datastore: parentDatastore, label: 'The comment has been successfully rejected and \n{user} was successfully warned', visible: true, formatParams: {user: modAuthor.authorName}})
        } catch (e) {
            setToastMessage({datastore: parentDatastore, label: "Operation could not be executed, please try again", visible: true, type:"error" })
        }
    }

    // TODO: What happens to comments in the mod queue if a user is already blocked? They probably don't require human attention anymore. Should we give an unblock option? Or automatically reject all comments?
    async function onBlock() {
        const isBlocked = await datastore.callServerAsync("moderationZdf", "getIsUserBlocked", { key: userId });
        if (!isBlocked) {
            try {
                await datastore.callServerAsync("moderationZdf", "setIsUserBlocked", {
                    key: userId,
                    isBlocked: true,
                    blockedByKey: currentModeratorKey,
                    blockedByName: currentModeratorPersona.name,
                });
                setToastMessage({datastore: parentDatastore, label: '{user} was successfully blocked', visible: true, formatParams: {user: modAuthor.authorName}})
            } catch (e) {
                setToastMessage({datastore: parentDatastore, label: "Operation could not be executed, please try again", visible: true, type:"error" })
            }
        }
        rejectCommentAsync();
        setShowBlockConfirmationModal(false);
        onNext();
    }

    async function onContinueWithoutWarnOrBlock() {
        try {
            await rejectCommentAsync();
            onNext();
            setToastMessage({datastore: parentDatastore, label: "The comment has been successfully rejected", visible: true })
        } catch (error) {
            setToastMessage({datastore: parentDatastore, label: "Operation could not be executed, please try again", visible: true, type:"error" })

        }
        
    }

    async function rejectCommentAsync() {
        const judgement = datastore.getSessionData(['preliminaryJudgement']);
        if (!judgement) return;

        // Update mod task and move it to the rejected queue
        await setJudgementAsync(judgement);
        datastore.setSessionData(["preliminaryJudgement", null]);
    }

    function onProfile() {
        if (!params) return
        params.userId = userId;
        gotoInstance({structureKey: "moddashboard", instanceKey: parentDatastore.props.instanceKey, params: params})
    }

    return (
        <View>
            <SubtleButton icon={ChevronLeft} label={"Back to selection"} onPress={onBack} />
            <Pad size={12} />
            <UtilityText label={"Want to do anything else?"} strong />
            <Pad size={12} />
            <ToolTip label={"This feature is not implemented yet."}>
                <CTAButton
                    wide
                    disabled
                    type="secondary"
                    label={"Warn user"}
                    onPress={onWarn}
                />
            </ToolTip>
            {warningCount >= 1 && (
                <>
                    <Pad size={12} />
                    <Banner>
                        <UtilityText
                            label='User has already been warned {count} {noun}.' 
                            formatParams={{count: warningCount, singular: 'time', plural: 'times'}}
                            color={colorBlackHover}
                        />
                        <Pad size={16} />
                        <Separator />
                        <Pad size={16} />
                        <HorizBox>
                            <User />
                            <Pad size={4} />
                            <TextButton
                                type="small"
                                color={colorBlackHover}
                                strong
                                label="Show user profile"
                                onPress={onProfile}
                            />
                        </HorizBox>
                    </Banner>
                </>
            )}
            <Pad size={12} />
            {warningCount >= 2 && (
                <>
                    <CTAButton
                        wide
                        type="delete"
                        label={"Block user"}
                        onPress={() => setShowBlockConfirmationModal(true)}
                    />
                    {showBlockConfirmationModal && (
                        <BlockConfirmationModal
                            onBlock={onBlock}
                            onClose={() => setShowBlockConfirmationModal(false)}
                        />
                    )}
                    <Pad size={12} />
                </>
            )}
            <CTAButton
                wide
                type="primary"
                label={"No thanks"}
                onPress={async () => {
                    onContinueWithoutWarnOrBlock();
                }}
            />
        </View>
    );
}

export function BlockConfirmationModal({ onBlock, onClose }) {
    return (
        <Modal onClose={onClose}>
            <PadBox vert={40} horiz={20}>
                <Heading label="Block this user?" level={1} />
                <Pad size={8} />
                <UtilityText
                    label="Do you really want to block this user? You can unblock the user via the profile page."
                    color={colorTextGrey}
                />
                <Pad size={32} />
                <CTAButton
                    wide
                    type="delete"
                    label={"Block user"}
                    onPress={async () => {
                        onBlock();
                    }}
                />
            </PadBox>
        </Modal>
    );
}

export function UnblockConfirmationModal({ onUnblock, onClose }) {
    return (
        <Modal onClose={onClose}>
            <PadBox vert={40} horiz={20}>
                <Heading label="Unblock this user?" level={1} />
                <Pad size={8} />
                <UtilityText label="Do you really want to unblock this user?" color={colorTextGrey} />
                <Pad size={32} />
                <CTAButton
                    wide
                    type="delete"
                    label={"Unblock user"}
                    onPress={async () => {
                        onUnblock();
                    }}
                />
            </PadBox>
        </Modal>
    );
}