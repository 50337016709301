import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { getFragment } from '../../platform-specific/url';

export function toggleOverlayVisibility(visible) {
    window.parent.postMessage({ type: 'psi-change-video-overlay-visibility' , visible}, '*');
}

export function toggleOverlayPointerEvents(value) {
    window.parent.postMessage({ type: 'psi-change-video-overlay-pointer-events' , value}, '*');
}

export function toggleOverlayBackdropVisibility(value) {
    window.parent.postMessage({ type: 'psi-change-video-overlay-backdrop-visibility' , value}, '*');
}

// Retrieve the container dimension outside the iframe
function useOverlayDimension() {
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    useEffect(() => {
        
        // Initially trigger a dimension update
        window.parent.postMessage({type: 'overlay-screen-dimension'}, '*');

        // Listen to dimension updates
        const callback = window.addEventListener('message', (event) => {
            if (event.data.type === 'overlay-screen-dimension') {
                setWidth(event.data.width);
                setHeight(event.data.height)
            }
        });
        // Cleanup
        return () => {
            window.removeEventListener('message', callback);
        }
    }, []);
    return {width, height}
}

// Overlay Screen
// The content of this component controls the position and dimension of the overlay screen iframe
// position is to be given as object containing left, right, top or bottom
// When no position is given along an axis it will center itself
// dimension is to be given as object containig width or height
// When no height is specified it takes the automatic height of the content
// When no width is specifiec it will take up all the availble height
// NOTE: This component should ever only exist once, else they will mess each other up
function OverlayScreenIframe({ children, dimension={}, position={}, showBackdrop = false, visible=true}) {
    // Keep track of the size of the children
    const [dimensionContent, setDimensionContent] = useState({ width: null, height: null })
    const overlayDimension = useOverlayDimension()

    const calculateDimensionStyle = () => {
        const dimData = {}
        dimData.width = dimension.width;
        dimData.height = dimension.height;

        // When no fixed dimension provided, use the dimension of the content
        if (!dimData.height) {
            dimData.height = dimensionContent.height
        }
        if (!dimData.width) {
            dimData.width = dimensionContent.width
        }

        // Correctly add px so the iframe can interpret it
        if (!dimData.width?.toString().includes("px") && !dimData.width?.toString().includes("%")) dimData.width += "px"
        if (!dimData.height?.toString().includes("px") && !dimData.height?.toString().includes("%")) dimData.height += "px"
        return dimData
    }

    const calculatePositionStyle = () => {
        const posData = { ...position}
        return posData
    }

    function onLayout(e) {
        // Keep track of the content's dimensions
        const { height, width } = e.nativeEvent.layout;
        setDimensionContent({ height: Math.round(parseInt(height)) + "px", width: Math.round(parseInt(width)) + "px" })
    }

    useEffect(() => {
        // Notify the Iframe when we change the position
        window.parent.postMessage({ type: 'psi-teaser-position', position: calculatePositionStyle() }, '*');
    }, [position])


    useEffect(() => {
        // Notify the Iframe when we change the dimension or the content changes its dimension
        window.parent.postMessage({ type: 'psi-teaser-dimension', dimension: calculateDimensionStyle() }, '*');
    }, [dimension, dimensionContent])

    // Render a View with the iframes dimensions
    // Render an absolute positioned View with the iframes base container size (can be larger than the iframe itself)
    // Inside this, render the content with custom deimension or up to the size of the base container
    // The rendered size will be reflected back to the iframe, matching their dimensions
    // TODO: This does not work  well with width. You either have to give it a fixed width or it will always take up the maximum width
    return <View style={{ position: "relative", width: "100vw", height: "100vh", overflow: "hidden" }}>
        <View style={{ position: "absolute", width: overlayDimension.width, height: overlayDimension.height}}>
            <View onLayout={onLayout} style={{
                width: dimension.width, 
                height: position.height, 
                maxWidth: overlayDimension.width, 
                maxHeight: overlayDimension.height
            }}>
                {children}
            </View>
        </View>
    </View>;
}


// Content gets positioned as a window, while the iframe's size remains unchanged
function SimplePositionedOverlayScreen({children, dimension = {}, position = {}}) {

    // If no position is given along an axis, center the overlay
    let transform = "";
    if(!position.left && !position.right) {
        position.left = "50%"
        transform += "translateX(-50%)"
    }
    if(!position.top && !position.bottom) {
        position.top = "50%"
        transform += "translateY(-50%)"
    }
    if(position) {
        position.transform = transform
    }

    return <View style={{ position: "absolute" }}>
        <View style={{ width: "100vw", maxWidth: "100%", height: "100vh", maxHeight: "100%", pointerEvents: "none", overflow: "hidden" }}>
            <View style={{ position: "absolute", ...position, ...dimension, pointerEvents: "auto" }}>
                {children}
            </View>
        </View>
    </View>
}

export function OverlayScreen({useIframe=true, repositionIframe=false, children, dimension={}, position={}, showBackdrop = true, visible=true, fullscreen=false, pointerEvents=true}) {
    const url = window.location.toString()
    const communicateWithIframe = url.includes("overlayScreen") || useIframe==true

    useEffect(() => {
        if(communicateWithIframe)
        toggleOverlayVisibility(visible)
    }, [visible])

    useEffect(() => {
        if(communicateWithIframe)
        toggleOverlayPointerEvents(pointerEvents)
    }, [pointerEvents])

    useEffect(() => {
        if(communicateWithIframe)
        toggleOverlayBackdropVisibility(showBackdrop)
    }, [showBackdrop])


    // OverlayScreenIframe: Notifies the Iframe to resize and reposition itself (complicated and requires setup)
    //      Only way to make the overlay screen and the content of the outer container interactive at the same time
    // SimplePositionedOverlayScreen: Content is rendered as a positioned window. Whole iframe will still block or pass through clicks.
    // FullScreenOverlayScreen: Children just render as normal, Whole iframe will still block or pass through clicks
    if(repositionIframe) {
        return <OverlayScreenIframe dimension={dimension} position={position} showBackdrop={showBackdrop} visible={visible}>
            {children}
        </OverlayScreenIframe>
    } else if(fullscreen) {
        return <>{children}</>
    }  else {
        return <SimplePositionedOverlayScreen dimension={dimension} position={position}>
            {children}
        </SimplePositionedOverlayScreen>
    }
}