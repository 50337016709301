import { useCollection, useDatastore, useGlobalProperty } from "util/datastore.js"
import { PollWidget } from "../poll/pollwidget";
import { DataVizText, Heading, Paragraph, TextField, UtilityText } from "component/text.js"
import { ConversationScreen, Pad, PadBox, Separator } from "component/basics.js"
import { spacings } from "component/constants";
import { useIsAdmin } from "component/admin";
import { CTAButton, TextButton } from "component/button";
import { colorTextGrey } from "component/color";
import { PollResult } from "component/poll";
import { FormField } from "component/form";
import { useState } from "react";
import { Banner } from "component/banner";
import { View } from "react-native";

export const TopicPollFeature = {
    key: 'topicpoll',
    name: 'Topic Poll',
    subscreens: {
        pollAdmin: (params) => <PollAdminScreen {...params} />,
    },
    defaultConfig: {
        topicTopWidgets: [],
        topicBottomWidgets: []
    }
}

export const TopicPollTopPositionFeature = {
    key: 'topictoppoll',
    name: 'Top Position',
    config: {
        topicTopWidgets: [TopicPoll],
    }
}

export const TopicPollBottomPositionFeature = {
    key: 'topicbottompoll',
    name: 'Bottom Position',
    config: {
        topicBottomWidgets: [TopicPoll]
    }
}


export const TopicPollInContentPositionFeature = {
    key: 'topicincontentpoll',
    name: 'In content position',
    config: {
        topicContentWidgets: [TopicPoll],
    }
}

function TopicPoll() {
    const poll = useGlobalProperty('poll');
    const isAdmin = useIsAdmin();
    const datastore = useDatastore();
    if (!poll) return <UtilityText label='No poll defined' />;

    const optionTexts = poll.options.map(x => x.label);

    return <PadBox vert={spacings.xl2} horiz={spacings.lg}>
        <PollWidget
            pollKey='topicPoll'
            question={poll.question}
            optionTexts={optionTexts}
        />
        {isAdmin && <TextButton type={"tiny"} label={"Edit poll (admin)"} color={colorTextGrey} onPress={() => datastore.pushSubscreen('pollAdmin', { pollName: 'poll', pollKey: 'topicPoll' })} />}
    </PadBox>
}

export const NextTopicPollFeature = {
    key: 'nexttopicpoll',
    name: 'Next Topic Poll',
    config: {
        topicBottomWidgets: [NextTopicPoll]
    }
}

function NextTopicPoll() {
    const nextTopicPoll = useGlobalProperty('nextTopicPoll');
    const isAdmin = useIsAdmin();
    const datastore = useDatastore();
    if (!nextTopicPoll) return <UtilityText label='No upcoming topic poll defined' />;

    const optionTexts = nextTopicPoll.options.map(x => x.label);

    return <PadBox top={spacings.xl2} horiz={spacings.lg}>
        <PollWidget
            pollKey={'upcomingTopicPoll'}
            question={nextTopicPoll.question}
            optionTexts={optionTexts}
            showOtherOption
            otherOptionText={nextTopicPoll.otherOptionValue}
            otherOptionPlaceholder={nextTopicPoll.otherOptionPlaceholder}
        />
        {isAdmin && <TextButton type={"tiny"} label={"Edit poll (admin)"} color={colorTextGrey} onPress={() => datastore.pushSubscreen('pollAdmin', { pollName: 'nextTopicPoll', pollKey: 'upcomingTopicPoll' })} />}
    </PadBox>
}

function PollAdminScreen({ pollName = '', pollKey = '' }) {
    const isAdmin = useIsAdmin();
    const poll = useGlobalProperty(pollName);
    const votesCollection = useCollection(pollKey);
    if (!isAdmin) {
        return <UtilityText text={"Access denied"} />
    }
    const votes = votesCollection?.map(vote => vote?.value?.label);
    const optionTexts = poll?.options?.map(x => x.label);
    if (poll.type === 'MultipleChoiceOther') {
        optionTexts.push(poll.otherOptionValue);
    }
    const formatParams = { count: votes.length, singular: 'participant', plural: 'participants' };
    return <ConversationScreen>
        <PadBox vert={spacings.xl2} horiz={spacings.lg}>
            <View style={{ flexDirection: 'column', gap: spacings.lg }}>
                <Heading type="large" weight="medium" label={"Edit poll (admin)"} />
                <DataVizText type='heading1' text={poll.question || ''} />
                <Paragraph color={colorTextGrey} label='{count} {noun}' formatParams={formatParams} />
                <PollResult optionTexts={optionTexts} votes={votes} />
                {poll.type === 'MultipleChoiceOther' &&
                    <>
                        <Paragraph color={colorTextGrey} label={`Other option answers:`} />
                        {votesCollection.filter(voteRecord => voteRecord.value.label === poll.otherOptionValue)
                            .map((voteRecord, index) => <Paragraph key={index} color={colorTextGrey} text={`${voteRecord.value.comment}`} />)}
                    </>
                }
                <Separator />
                <PollForm isReadOnly={votes.length > 0} pollName={pollName} poll={poll} hasComment={poll.type === 'MultipleChoiceOther'} />
            </View>
        </PadBox>
    </ConversationScreen>
}

function PollForm({ isReadOnly = false, pollName, poll, hasComment = false }) {
    const datastore = useDatastore();
    const [pollObject, setPollObject] = useState(poll);
    const [values, setValues] = useState(poll.options.map(x => x.label));

    const onAddPollOption = () => {
        const newOptions = [...values, ''];
        setValues(newOptions);
    };
    const onRemovePollOption = () => {
        if (values.length > 0) {
            const newOptions = values.slice(0, values.length - 1);
            setValues(newOptions);
        }
    };

    const updatePollOption = ({ index, value }) => {
        const newValues = [...values];
        newValues[index] = value;
        setValues(newValues);
    }

    const updatePoll = () => {
        datastore.setGlobalProperty(pollName, {
            ...pollObject,
            options: values.map(x => ({ label: x })),
        });
        datastore.goBack();
    }

    return <>
        {isReadOnly && <Banner><UtilityText label='The poll cannot be modified after receiving votes' /></Banner>}
        <FormField label='Poll question'>
            <TextField disabled={isReadOnly} value={pollObject.question} onChange={(value) => setPollObject({ ...pollObject, question: value })} placeholder='Text Field' />
        </FormField>
        {hasComment &&
            <FormField label='Open answer label'>
                <TextField disabled={isReadOnly} value={pollObject.otherOptionValue} onChange={(value) => setPollObject({ ...pollObject, otherOptionValue: value })} placeholder='Text Field' />
            </FormField>}
        {hasComment &&
            <FormField label='Open answer placeholder'>
                <TextField disabled={isReadOnly} value={pollObject.otherOptionPlaceholder} onChange={(value) => setPollObject({ ...pollObject, otherOptionPlaceholder: value })} placeholder='Text Field' />
            </FormField>}
        <View style={{ flexDirection: 'row', gap: spacings.lg }}>
            <CTAButton disabled={isReadOnly} type='secondary' label='Remove option' onPress={onRemovePollOption} />
            <CTAButton disabled={isReadOnly} type='primary' label='Add option' onPress={onAddPollOption} />
        </View>
        <UtilityText type='small' weight="medium" label={'Poll options'} />
        {values.length > 0 &&
            <>
                {values.map((x, index) => (
                    <TextField
                        key={index}
                        disabled={isReadOnly}
                        placeholder='Option...'
                        value={x}
                        onChange={(value) => updatePollOption({ index, value })}
                    />
                ))
                }
            </>
        }
        {isReadOnly ? <CTAButton label="Back" onPress={() => datastore.goBack()} /> : <CTAButton label='Update' onPress={updatePoll} />}
    </>
}