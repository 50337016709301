import { View, StyleSheet } from "react-native"
import { Heading, UtilityText } from "../../component/text"
import { colorAccent, colorAccentLight, colorBlack, colorGreyBorder, colorGreyHover, colorTextGrey, colorWhite } from "../../component/color"
import { CTAButton } from "../../component/button"
import { useEffect, useState } from "react"
import { HoverView, Pad, PadBox } from "../../component/basics"
import { Checkmark } from "@carbon/icons-react"
import { logEventAsync, useLogEvent, useStartTime } from "util/eventlog"
import { useDatastore, useSessionData } from "util/datastore"
import { Modal } from "component/modal"

export const SatisfactionSurveyFeature = {
    name: 'Survey Satisfaction',
    key: 'satisfaction_survey',
    dev: true,
    config: {
        sidebarCloseModal: SatisfactionSurveyModal,
        sidebarShouldShowCloseModal: shouldShowSatisfactionSurvey
    }
}

export const UsabilitySurveyFeature = {
    name: 'Survey Usability',
    key: 'usability_survey',
    dev: true,
    config: {
        commentPostTriggers: [maybeEnableUsabilitySurveyAfterTopLevelComment],
        pageTopRightWidgets: [ShowUsabilitySurveyIfEnabled]
    }
}

const usabilityLabels = [
    'Strongly disagree',
    'Disagree',
    'Neither agree nor disagree',
    'Agree',
    'Strongly agree'
]

export async function maybeEnableUsabilitySurveyAfterTopLevelComment({datastore, comment}) {
    if (comment.replyTo) {
        return;
    }
    const lastShowedUsabilitySurvey = await datastore.getModuleUserGlobalAsync('survey', 'lastShowedUsabilitySurvey') ?? 0;
    const oneWeekAgo = Date.now() - 7 * 24 * 60 * 60 * 1000;
    if (lastShowedUsabilitySurvey < oneWeekAgo) {
        datastore.setModuleUserGlobal('survey', 'lastShowedUsabilitySurvey', Date.now());
        datastore.setSessionData('usabilitySurveyEnabled', true);
    }
}

export function ShowUsabilitySurveyIfEnabled() {
    const enabled = useSessionData('usabilitySurveyEnabled');
    const datastore = useDatastore();

    function onDone() {
        datastore.setSessionData('usabilitySurveyEnabled', false);
    }  

    return enabled ? <Modal onClose={onDone}>
        <PadBox horiz={20} vert={40}>
            <UsabilitySurveyModal onDone={onDone}/>
        </PadBox>
    </Modal> : null;
}

export function UsabilitySurveyModal({onDone}) {
    const [easyToUse, setEasyToUse] = useState(null);
    const [meetsNeeds, setMeetsNeeds] = useState(null);

    const datastore = useDatastore()
    const startTime = useStartTime();

    async function onSubmit() {
        await logEventAsync(datastore, 'survey-usability', {
            easyToUse,
            meetsNeeds,
            timeTaken:Date.now() - startTime
        });
        onDone && onDone();
    }

    return <View>
        <Heading type='large' label="Please indicate how much you agree or disagree with each statement" />
        <Pad size={8} />
        <UtilityText type='large' color={colorTextGrey} text='Share your feedback to help improve our Beta experience.' />
        <Pad size={32} />
        <UtilityText type='small' weight='medium' label='This conversation platform is easy to use' />
        <Pad size={12} />
        <View testID='survey-easy-to-use'>
            <SurveyOptionList optionLabels={usabilityLabels} value={easyToUse} onChange={setEasyToUse} />
        </View>
        <Pad size={32} />
        <UtilityText type='small' weight='medium' label='This conversation platform meets my needs' />
        <Pad size={12} />
        <View testID='survey-meets-needs'>
            <SurveyOptionList optionLabels={usabilityLabels} value={meetsNeeds} onChange={setMeetsNeeds} />
        </View>
        <Pad size={32} />
        <CTAButton wide type='accent' label='Submit' onPress={onSubmit} />
    </View>
}

const satisfactionLabels = [
    'Very satisfied',
    'Satisfied',
    'Neither satisfied nor unsatisfied',
    'Unsatisfied',
    'Very unsatisfied'
]

var global_app_start_time = Date.now();

const thirtySeconds = 1000 * 30;

function shouldShowSatisfactionSurvey() {
    const appOpenMoreThanThirtySecondsAgo = Date.now() - global_app_start_time > thirtySeconds;
    const random25Percent = Math.random() < 0.25;
    return appOpenMoreThanThirtySecondsAgo && random25Percent;
}

export function SatisfactionSurveyModal({onDone}) {
    const [value, setValue] = useState(null);
    useLogEvent('show-survey-satisfaction');
    const datastore = useDatastore()
    const startTime = useStartTime();

    async function onSubmit() {
        await logEventAsync(datastore, 'survey-satisfaction', {
            satisfaction:value, 
            timeTakenForSurvey:Date.now() - startTime,
            timeSinceAppStart:Date.now() - global_app_start_time
        });
        datastore.setSessionData('surveyShown', true);
        onDone && onDone();
    }

    return <View>
        <Heading type='large' label="We'd love your feedback!" />
        <Pad size={8}/>
        <UtilityText type='large' color={colorTextGrey}text='Share your feedback to help improve our Beta experience.' />
        <Pad size={32}/>
        <UtilityText type='small' weight='medium' label='How satisfied are you with this conversation platform?' />
        <Pad size={12}/>
        <SurveyOptionList optionLabels={satisfactionLabels} value={value} onChange={setValue} />
        <Pad size={32}/>
        <CTAButton wide type='accent' label='Submit' onPress={onSubmit} />
    </View>
}

export function SurveyOptionList({optionLabels, value, onChange}) {
    return <View style={{gap:12}}>
        {optionLabels.map((label, index) => (
            <SurveyOption key={index} selected={value === index} label={label} onPress={() => onChange(index)} />
        ))}
    </View>
}

export function SurveyOption({selected, label, onPress}) {
    const s = SurveyOptionStyle;
    return <HoverView style={selected ? s.selected : s.unselected} 
            hoverStyle={!selected && s.hover} 
            ariaLabel={label}
            pressedStyle={!selected && s.pressed} 
            onPress={onPress}>
        <UtilityText type='small' color={selected ? colorAccent : colorBlack} label={label} />
        <View style={selected ? s.selectedCircle : s.circle}>
            {selected && <Checkmark size={14} color={selected ? colorWhite : colorGreyBorder}/>}
        </View>
    </HoverView>
}
const SurveyOptionStyle = StyleSheet.create({
    selectedCircle: {
        width: 24,
        height: 24,
        borderRadius: 16,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: colorAccent,
    },

    circle: {
        width: 24,
        height: 24,
        borderRadius: 16,
        borderWidth: 1,
        borderColor: colorGreyBorder,
        justifyContent: 'center',
        alignItems: 'center',
    },
    unselected: {
        padding: 8,
        paddingLeft: 12,
        borderRadius: 32,
        borderWidth: 1,
        borderColor: colorGreyBorder,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    selected: {
        padding: 8,
        paddingLeft: 12,
        borderRadius: 32,
        borderWidth: 1,
        borderColor: colorAccent,
        backgroundColor: colorAccentLight,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    hover: {
        backgroundColor: colorGreyHover
    },
    pressed: {
        backgroundColor: colorGreyBorder
    }
})
