import { useState } from "react";
import { AccordionField, Checkbox, FormField } from "../../form";
import { View } from "react-native";
import { Heading, TextField, UtilityText } from "../../text";
import { HorizBox, Pad, PadBox, Separator } from "../../basics";
import { DropDownSelector, IconButton, TextButton } from "../../button";
import { Add, CenterSquare, Edit, OpenPanelFilledBottom, OpenPanelFilledLeft, OpenPanelFilledRight, OpenPanelFilledTop, Pen, PenFountain, Play, Terminal_3270, TrashCan } from "@carbon/icons-react";
import { colorGreyPopupBackground, colorPurplishBlue, colorRed, colorTextGrey } from "../../color";
import { endometriosisVideoVotingTemplateDE } from "../../../feature/zdf/videovoting/examples/votingtemplate";
import { TopBarActionProvider } from "../../topbar";
import { useDatastore, useIsLive, useModulePublicData } from "../../../util/datastore";
import { Modal } from "../../modal";
import { useGlobalQuestions } from "../../question";
import { usePlayedVideo } from "../../../system/videotime";
import { generateRandomKey } from "../../../util/util";
import { goBack } from "../../../util/navigate";
import { setToastMessage, ToastRenderer } from "../toast";
import { DocumentLevelComponent } from "../../../platform-specific/popup";
import { useIsLocalhostAdmin } from "../../admin";

export function VideoVotingEditor({ voting = null, questionKey }) {
    const [name, setName] = useState(voting?.name ?? "");
    const [questions, setQuestions] = useState(voting?.questions ?? []);
    const [chapters, setChapters] = useState(voting?.chapters ?? []);
    const [showQuestionSelector, setShowQuestionSelector] = useState(false)
    const [showTemplateSelector, setShowTemplateSelector] = useState(false)
    const [selectedQuestionKey, setSelectedQuestionKey] = useState(questionKey)
    const selectedQuestion = useModulePublicData("question", [selectedQuestionKey])
    const datastore = useDatastore();

    const updateQuestion = (index, updatedQuestion) => {
        setQuestions(prev =>
            prev.map((q, i) => (i === index ? updatedQuestion : q)).filter(c => c != null)
        );
    };

    const updateChapter = (index, updatedChapter) => {
        setChapters(prev =>
            prev.map((c, i) => (i === index ? updatedChapter : c)).filter(c => c != null)
        );
    };

    const prefillTemplate = (voting) => {
        setName(voting.name)
        setQuestions(voting.questions)
        setChapters(voting.chapters)
    }

    const saveTemplate = async () => {
        // Convert strings to proper floats for the timestamps
        questions.forEach(q => {
            q.timestamp = parseFloat(q.timestamp)
        })

        // No voting given -> New creation
        if (!voting) {
            const result = await datastore.callServerAsync('videovoting', 'create', {
                template: { name: name, questions: questions, chapters: chapters },
                questionKey: selectedQuestionKey
            });
            if (!result.error) {
                setToastMessage({ datastore, text: "Creation successful" })
                goBack();
            } else {
                setToastMessage({ datastore, text: "Something went wrong. " + result.error, type: "error" })
            }
        } else {
            const result = await datastore.callServerAsync('videovoting', 'edit', {
                template: { name: name, questions: questions, chapters: chapters },
                questionKey: selectedQuestionKey
            });
            if (!result.error) {
                setToastMessage({ datastore, text: "Edit successful" })
            } else {
                setToastMessage({ datastore, text: "Something went wrong. " + result.error, type: "error" })
            }
        }
    }

    const cancelTemplate = async () => {
        // No voting given -> New creation
        if (!voting) {
            goBack();
        } else {
            setName(voting.name)
            setQuestions(voting.questions ?? [])
            setChapters(voting.chapters ?? [])
        }
    }

    return <View style={{ gap: 16 }}>
        <TextButton text={"Prefill data from exisiting voting"} onPress={() => setShowTemplateSelector(true)} />
        <FormField text="Voting title" required>
            <TextField noTranslationPlaceholder="Enter a title for the voting" value={name} onChange={setName} />
        </FormField>
        <Separator />
        <HorizBox center>
            <Heading text={"Selected thread: "} />
            <Heading text={selectedQuestion?.question ?? "No thread assigned"} color={selectedQuestion && colorPurplishBlue} />
            <IconButton icon={Add} text={"Assign a thread"} onPress={() => { setShowQuestionSelector(!showQuestionSelector) }} />
        </HorizBox>
        <Separator />
        <Heading text={"Chapters:"} />
        <PadBox horiz={16}>
            <View style={{ gap: 16 }}>
                {chapters?.map((chapter, i) => (
                    <View key={i}>
                        <ChapterEditor value={chapter} onChange={(chapter) => updateChapter(i, chapter)} />
                    </View>
                ))}
                <IconButton
                    icon={Add}
                    text="Add Chapter"
                    onPress={() => setChapters([...chapters, { key: generateRandomKey(20), name: '' }])}
                />
            </View>

        </PadBox>
        <Separator />
        <Heading text={"Questions:"} />
        {questions.map((question, i) => (
            <View key={question.key}>
                <QuestionEditor
                    question={question}
                    onUpdate={(updatedQuestion) => updateQuestion(i, updatedQuestion)}
                    chapters={chapters}
                />
            </View>
        ))}
        <IconButton
            icon={Add}
            text="Add Question"
            onPress={() => setQuestions([...questions, { key: generateRandomKey(20), title: '', timestamp: null, type: "poll", options: [{ key: generateRandomKey(20) }, { key: generateRandomKey(20) }] }])}
        />
        <TopBarActionProvider label='Cancel' secondary onPress={cancelTemplate} />
        <TopBarActionProvider label='Save' onPress={saveTemplate} />
        <QuestionSelectorModal visible={showQuestionSelector} onClose={() => { setShowQuestionSelector(false) }} onSelect={(question) => { setSelectedQuestionKey(question.key) }} />
        <TemplateSelectionModal visible={showTemplateSelector} onClose={() => setShowTemplateSelector(false)} onSelect={(voting) => { prefillTemplate(voting) }} />
        <DocumentLevelComponent>
            <View style={{ position: "fixed", bottom: 50, left: 0, right: 0, zIndex: 2, alignItems: "center" }}>
                <ToastRenderer />
            </View>
        </DocumentLevelComponent>
    </View>;
}

const positionIconProps = { size: 32, color: colorTextGrey }
const positionIconPropsSelected = { size: 32, color: colorPurplishBlue }

function QuestionEditor({ question, onUpdate, chapters = [] }) {

    const [showChapterModal, setChapterModal] = useState(false);
    const assignedChapter = chapters.find(c => c.key === question.chapterKey)

    const updateOptions = (index, value) => {
        const updatedOptions = [...question.options];
        updatedOptions[index] = value;
        onUpdate({ ...question, options: updatedOptions.filter(o => o != null) });
    };

    const video = usePlayedVideo()

    const dropdownOptions = [
        { key: "poll", label: "Poll" },
        { key: "slider", label: "Slider" },
    ]
    const changeQuestionType = (type) => {
        if (type === 'poll') {
            onUpdate({ ...question, type: 'poll' })
        } else if (type === 'slider') {
            onUpdate({ ...question, type: 'slider' })
        }
    }

    return <View style={{ backgroundColor: colorGreyPopupBackground, padding: 16 }}>
        <AccordionField titleContent={
            <View style={{ width: "100%", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                <Heading text={question.text ?? "Add a question title"} />
                <Pad />
                <UtilityText text={question.timestamp?.toString()} />
            </View>
        }>
            <View style={{ gap: 16 }}>
                <DropDownSelector text={"Question type"} value={question.type} options={dropdownOptions} onChange={value => { changeQuestionType(value) }} />
                <FormField text="Question Name" required>
                    <TextField
                        noTranslationPlaceholder="Enter a name for the question"
                        value={question.text}
                        onChange={(value) => {
                            onUpdate({ ...question, text: value });
                        }}
                    />
                </FormField>
                <Separator />
                <HorizBox center>
                    <Heading text={"Selected chapter: "} />
                    <Heading text={assignedChapter?.name ?? "No chapter assigned"} color={assignedChapter && colorPurplishBlue} />
                    <IconButton icon={Add} text={"Assign a chapter"} onPress={() => { setChapterModal(!showChapterModal) }} />
                </HorizBox>
                <Separator />
                <HorizBox center>
                    <UtilityText text={"Layout:"} />
                    <IconButton icon={CenterSquare} iconProps={!question.position ? positionIconPropsSelected : positionIconProps} onPress={() => { onUpdate({ ...question, position: null }) }} />
                    <IconButton icon={OpenPanelFilledLeft} iconProps={question.position?.left ? positionIconPropsSelected : positionIconProps} onPress={() => { onUpdate({ ...question, position: { left: "5%" } }) }} />
                    <IconButton icon={OpenPanelFilledRight} iconProps={question.position?.right ? positionIconPropsSelected : positionIconProps} onPress={() => { onUpdate({ ...question, position: { right: "5%" } }) }} />
                    <IconButton icon={OpenPanelFilledTop} iconProps={question.position?.top ? positionIconPropsSelected : positionIconProps} onPress={() => { onUpdate({ ...question, position: { top: "5%" } }) }} />
                    <IconButton icon={OpenPanelFilledBottom} iconProps={question.position?.bottom ? positionIconPropsSelected : positionIconProps} onPress={() => { onUpdate({ ...question, position: { bottom: "5%" } }) }} />
                </HorizBox>
                <Separator />
                <HorizBox>
                    <Checkbox text={"Initial question"} value={question.initial} onChange={() => { onUpdate({ ...question, initial: !question.initial, timestamp: 0 }) }} />
                    <Checkbox text={"Final question"} value={question.final} onChange={() => { onUpdate({ ...question, final: !question.final }) }} />
                </HorizBox>
                <Separator />
                {(!question?.initial && !question?.final) && <FormField text="Timestamp" required>
                    <HorizBox center>
                        <TextField
                            noTranslationPlaceholder="Time in seconds"
                            multiline={false}
                            value={question.timestamp}
                            onChange={(value) => {
                                onUpdate({ ...question, timestamp: value });
                            }}
                        />
                        {(video.time) && <View><IconButton icon={Play} onPress={() => { onUpdate({ ...question, timestamp: video.time }) }} /></View>}
                    </HorizBox>
                </FormField>}
                <HorizBox>
                    <Checkbox text={"Stops video"} value={question.stopsVideo} onChange={() => { onUpdate({ ...question, stopsVideo: !question.stopsVideo }) }} />
                </HorizBox>
                <Separator />
                {question.type === "poll" &&
                    <FormField text={"Options"} required={true}>
                        <View style={{ gap: 8, padding: 8 }}>
                            {question.options !== undefined && question.options.map((option, i) => (
                                <OptionEditor
                                    key={"option" + i}
                                    noTranslationPlaceholder={`Option ${i + 1}`}
                                    value={option}
                                    onChange={(value) => updateOptions(i, value)}
                                />
                            ))}
                        </View>

                        {question.options?.length < 6 && <IconButton
                            icon={Add}
                            text="Add Option"
                            onPress={() => {
                                const newOptions = [...(question.options ?? []), { key: generateRandomKey(20) }];
                                onUpdate({ ...question, options: newOptions });
                            }}
                        />}
                    </FormField>
                }
                {question.type === 'slider' && <FormField text={"Slider options"} required={true}>
                    <View style={{ gap: 8 }}>
                        <HorizBox gap={16}>
                            <FormField text={"Minimum value"} required={true}>
                                <TextField noTranslationPlaceholder={"Enter a lower range"} value={question.minimum} onChange={(value) => onUpdate({ ...question, minimum: value })} />
                            </FormField>
                            <FormField text={"Maximum value"} required={true}>
                                <TextField noTranslationPlaceholder={"Enter an upper range"} value={question.maximum} onChange={(value) => onUpdate({ ...question, maximum: value })} />
                            </FormField>
                        </HorizBox>
                        <HorizBox>
                            <FormField text={"Unit"} >
                                <TextField noTranslationPlaceholder={"Enter an unit"} value={question.unit} onChange={(value) => onUpdate({ ...question, unit: value })} />
                            </FormField>
                        </HorizBox>
                    </View>
                </FormField>}
                <Separator />
                <IconButton icon={TrashCan} onPress={() => onUpdate(null)} iconProps={{ color: colorRed }} />
            </View>
        </AccordionField>
        {showChapterModal && <ChapterSelectionModal visible={showChapterModal} chapters={chapters} onClose={() => setChapterModal(false)} onSelect={(chapter) => onUpdate({ ...question, chapterKey: chapter.key })} />}
    </View>;
}

function OptionEditor({ value, onChange }) {
    return <HorizBox center gap={8}>
        <TextField
            noTranslationPlaceholder={"Enter option text"}
            value={value?.text}
            onChange={(txt) => { onChange({ ...value, text: txt }) }}
        />
        <View><IconButton icon={TrashCan} onPress={() => onChange(null)} /></View>
    </HorizBox>
}

function ChapterEditor({ value, placeholder, onChange }) {

    const [showQuestionSelector, setShowQuestionSelector] = useState(false)
    const allQuestions = useModulePublicData("question");
    const assignedQuestion = allQuestions?.[value?.questionKey];

    return <View>
        <FormField text={"Name"} required={true}>
            <HorizBox center gap={16}>
                <TextField
                    noTranslationPlaceholder={placeholder}
                    value={value?.name}
                    onChange={(txt) => { onChange({ ...value, name: txt }) }}
                />
                <View style={{ flexShrink: 1 }}>
                    <Heading text={assignedQuestion ? assignedQuestion.question : "No thread assigned"} color={assignedQuestion && colorPurplishBlue} />
                    <IconButton icon={Edit} text={"Assign a thread"} onPress={() => setShowQuestionSelector(true)}></IconButton>
                </View>
                <View style={{ flexGrow: 1 }}>
                    <IconButton icon={TrashCan} onPress={() => onChange(null)} />
                </View>
            </HorizBox>
        </FormField>
        {showQuestionSelector && <QuestionSelectorModal visible={showQuestionSelector} onClose={() => setShowQuestionSelector(false)} onSelect={(question) => { onChange({ ...value, questionKey: question.key }) }} />}
    </View>
}

function QuestionSelectorModal({ visible, onClose, onSelect }) {
    const questions = useGlobalQuestions();

    return <>{visible && <Modal onClose={onClose}>
        <PadBox horiz={16} vert={16}>
            <View style={{ gap: 8 }}>
                <Heading text={"Select a question"} />
                {questions.map(question => <TextButton key={question.key} text={question.question} onPress={() => { onSelect(question); onClose() }}> </TextButton>)}
            </View>
        </PadBox>

    </Modal>}</>
}

function ChapterSelectionModal({ visible, chapters, onClose, onSelect }) {
    return <>{visible && <Modal onClose={onClose}>
        <PadBox horiz={16} vert={16}>
            <View style={{ gap: 8 }}>
                <Heading text={"Select a chapter"} />
                {chapters.map(chapter => <TextButton key={chapter.key} text={chapter.name} onPress={() => { onSelect(chapter); onClose() }}> </TextButton>)}
            </View>
        </PadBox>
    </Modal>}</>
}

function TemplateSelectionModal({ visible, onClose, onSelect }) {
    const localBuild = useIsLocalhostAdmin()
    const votings = useModulePublicData('videovoting');
    return <>{visible && <Modal onClose={onClose}>
        <PadBox horiz={16} vert={16}>
            <View style={{ gap: 8 }}>
                <Heading text={"Select an existing Video Voting"} />
                {Object.values(votings ?? {}).map(voting => <TextButton key={voting.key} text={voting.name} onPress={() => { onSelect(voting.template); onClose() }}> </TextButton>)}
                {localBuild && <TextButton key={endometriosisVideoVotingTemplateDE.name} text={endometriosisVideoVotingTemplateDE.name + " (Demo)"} onPress={() => { onSelect(endometriosisVideoVotingTemplateDE); onClose() }}> </TextButton> }
            </View>
        </PadBox>
    </Modal>}</>
}