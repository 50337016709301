import React from "react";
import { ConversationScreen, HeaderBox, HorizBox, LoadingScreen, Pad, PadBox, WindowTitle } from "component/basics.js"
import { CTAButton, SubtleButton, Tag, TextButton } from "component/button.js"
import { ActionEdit, ActionReplyExceptToSelf, BasicComments, CommentsInput, ComposerScreen } from "component/comment.js"
import { Heading, UtilityText, Paragraph, WebLinkTextButton } from "component/text.js"
import { useCollection, useDatastore, useGlobalProperty, usePersonaKey } from "util/datastore.js"
import { Image, View } from "react-native";
import { Catcher } from "system/catcher.js"
import { useConfig } from "util/features.js"
import { colorBlackHover, colorGreyBorder, colorPinkBackground, colorTextGrey, colorWhite } from "component/color.js"
import { keyToUrl, toBool } from "../util/util";
import { StyleSheet } from "react-native";
import { Byline } from "component/people.js"
import { useTranslation } from "component/translation.js"
import { Bookmark, Pin, Share } from '@carbon/icons-react';
import { useLogEvent } from "util/eventlog.js"
import { TabBar, TabContent, TabNavigation } from "../contrib/zdf/tabnavigation";

// TODO: Bring back ActionReport once it is implemented
export const QuestionStructure = {
    key: 'question',
    name: 'Question',
    screen: QuestionScreen,
    teaser: QuestionTeaser,
    hasFocusTrap: true,
    subscreens: {
        composer: ({ commentKey, about, ...screenParams }) => {
            return <ComposerScreen about={about} commentKey={commentKey} intro={<AnswerIntro />} screenParams={screenParams} contentType='Public Response' />;
        },
        moderationDetails: () => {
            // The subscreen for the embedded instance for moderation had to be defined as a separate feature, because it caused a circular dependency
            return <LoadingScreen></LoadingScreen>
        }
    },
    defaultConfig: {
        commentActions: [ActionReplyExceptToSelf],
        commentRightActions: [/* ActionReport, */ ActionEdit],
        commentTopWidgets: [],
        commentAboveWidgets: [],
        commentMiddleWidgets: [],
        commentBelowWidgets: [],
        commentBodyStylers: [],
        commentStylers: [],
        commentAllowEmpty: false,
        commentEditBottomWidgets: [],
        commentEditTopWidgets: [],
        commentPostBlockers: [],
        commentPostTriggers: [],
        commentInputPlaceholder: 'Share your thoughts...',
        commentReplyPlaceholder: 'Reply to {authorName}...',
        commentInputLoginAction: '',
        commentPostCheckers: [],
        commentFilters: [],
        commentRankers: [],
        commentLimit: null,
        commentDeleteTriggers: [],
        composerPreview: AnswerInput,

        headerRoundedTopCorners: false,

        replyAboveWidgets: [],
        replyFilters: [],
        replyBoosters: [],
        

        composerSubtitle: () => 'Public response',
        composerTopBanners: [],
        composerTopWidgets: [],

        headerTopLeftWidgets: [],
        headerAboveInputWidgets: [],
        headerBelowInputWidgets: [],

        myAnswerBottomWidgets: [],

        topBanners: [],
        pageTopWidgets: [],
        pageTopRightWidgets: [],
        pageTopLeftWidgets: [],
        pageBottomWidgets: [],
        pageShowEmptyHelp: true,
        pageBottomPad: 80,

        noCommentsTitle: 'Start the conversation',
        noCommentsMessage: 'Be the first to share your thoughts',
        noMoreCommentsMessage: 'No more responses',
        currentResponseTitle: () => 'Your response',
        tabs: [{
            id: "tab-responses",
            label: "All responses",
            component: QuestionCommentSection
        }],
        topBarHelpBubbles: [],
        readOnly: false,
        openLinksInNewTab: false,

        bylineTags: [],
        profilePhotoLayers: [],

        teaser: null,

        sidebarCloseModal: null,
        sidebarShouldShowCloseModal: null
     },
    instance: []
}

function AnswerIntro() {
    const name = useGlobalProperty('name');

    return <View style={{ backgroundColor: colorPinkBackground }}>
        <PadBox horiz={20} vert={32}>
            <Heading text={name} />
        </PadBox>
    </View>
}

function MyAnswerPreview({comment}) {
    const s = CommentPreviewStyle;
    const datastore = useDatastore();
    const {commentAboveWidgets, commentMiddleWidgets, commentTopWidgets,
        myAnswerBottomWidgets,
        currentResponseTitle, commentBodyStylers} = useConfig();
    const tYourCurrentResponse = useTranslation(currentResponseTitle({comment}));
    const commentBodyStyle = commentBodyStylers.reduce((style, styler) => ({...style, ...styler({comment})}), {});

    return <View style={s.outer}>
        <PadBox vert={16} horiz={16}>
            <Byline type='small' userId={comment.from} name={tYourCurrentResponse} />
            <Pad size={16} />
            {commentAboveWidgets?.map((Widget, i) => <Widget key={i} comment={comment} />)}
            <View style={commentBodyStyle}>
                {commentTopWidgets?.map((Widget,i) => <Widget key={i} comment={comment}/>)}
                {toBool(comment.text) && <Paragraph type='large' color={commentBodyStyle.color} numberOfLines={4} text={comment.text?.trim()} />}
            </View>

            {commentMiddleWidgets?.map((Widget,i) => <Widget key={i} comment={comment}/>)}
            <MyAnswerCounts comment={comment} />

            {toBool(comment.text) && <Pad size={16} />}
            <CTAButton type='secondary' label='Update your response' onPress={() => datastore.pushSubscreen('composer', { commentKey: comment.key })} />
        </PadBox>
        {myAnswerBottomWidgets?.map((Widget,i) => <Widget key={i} comment={comment}/>)}

    </View>
}
const CommentPreviewStyle = StyleSheet.create({
    outer: {
        borderColor: colorGreyBorder,
        borderWidth: 1,
        borderRadius: 8,
        backgroundColor: colorWhite
    }
})

function MyAnswerCounts({comment}) {
    const replies = useCollection('comment', {filter: {replyTo: comment.key}, sortBy: 'time', reverse: true});
    const upvotes = useCollection('upvote', {filter: {comment: comment.key}});
    const reactions = useCollection('reaction', {filter: {reactTo: comment.key}});

    const replyCount = replies.length;
    const upvoteCount = upvotes.length;
    const reactionCount = reactions.length;
    const hasCounts = reactionCount > 0 || upvoteCount > 0 || replyCount > 0;

    return <View>
        {hasCounts && <Pad size={16} />}
        <HorizBox>
            {reactionCount > 0 ? 
                <UtilityText type='tiny' weight='medium' color={colorTextGrey} label='{count} {noun}' formatParams={{count: reactionCount, singular: 'reaction', plural: 'reactions'}} />
            : upvoteCount > 0 ?
                <UtilityText type='tiny' weight='medium' color={colorTextGrey} label='{count} {noun}' formatParams={{count: upvoteCount, singular: 'upvote', plural: 'upvotes'}} />
            : null}
            {(reactionCount > 0 || upvoteCount > 0) && replyCount > 0 && <UtilityText type='tiny' weight='medium' color={colorTextGrey} label=' • ' />}
            {replyCount > 0 && <UtilityText type='tiny' weight='medium' color={colorTextGrey} label='{count} {noun}' formatParams={{count: replyCount, singular: 'reply', plural: 'replies'}} />}
        </HorizBox>
    </View>
}

function AnswerInput() {
    const personaKey = usePersonaKey();
    const myAnswer = useCollection('comment', {filter: {from: personaKey, replyTo: null, deleted: null}})[0];

    if (myAnswer) {
        return <MyAnswerPreview comment={myAnswer} />
    } else {
        return <CommentsInput />
    }
}

function HeaderTopWidgets() {
    const { headerTopLeftWidgets } = useConfig();
    if (headerTopLeftWidgets?.length > 0) {
        return <>
            <Pad size={12} />
            {headerTopLeftWidgets.map((Widget, i) => <Widget key={i} />)}
            <Pad size={32} />
        </>
    } else {
        return null;
    }
}

export function QuestionScreen() {
    const {topBanners, tabs, headerAboveInputWidgets,
        headerBelowInputWidgets, composerPreview, headerTopLeftWidgets} = useConfig();
    useLogEvent('question-view');
    const name = useGlobalProperty('name');

    let urlParamTab;

    return <ConversationScreen>
        <WindowTitle text={name} />
        {topBanners?.map((Banner, i) => <Banner key={i} />)}
        <View style={{zIndex: 1}}>
            <Catcher>
                <HeaderBox>
                    <HeaderTopWidgets />
                    <Heading type="large" weight="medium" text={name} />
                    {headerAboveInputWidgets?.map((Widget,i) => <Widget key={i}/>)}
                    <Pad size={32} />
                    {React.createElement(composerPreview)}
                    {headerBelowInputWidgets?.map((Widget,i) => <Widget key={i}/>)}
                    <Pad size={12} />
                </HeaderBox>
            </Catcher>
        </View>
        <TabNavigation tabs={tabs} initialTabId={urlParamTab}>
            <PadBox horiz={20} top={tabs.length > 1 ? 40 : 0}>
                <TabBar />
            </PadBox>
            <TabContent/>
        </TabNavigation>
    </ConversationScreen>
}

export function QuestionCommentSection() {
    const {pageBottomPad} = useConfig();
    return <>
        <Catcher>
            <BasicComments canPost={false} />
        </Catcher>
        <Pad size={pageBottomPad} />
    </>
}

export function ArticleCard({ article, tags, thumbsize = 64 }) {
    const url = keyToUrl(article.key);
    return <HorizBox center={true} key={article.key}>
        <Image source={{ uri: article.image }} style={{ width: thumbsize, height: thumbsize }} />
        <Pad size={16} />
        <View style={{flex: 1}}>
            {tags ? <HorizBox>{tags.map(tag => <PadBox key={tag} right={4} bottom={4}><Tag compact key={tag} text={tag} /></PadBox>)}</HorizBox>  : <></>}
            <WebLinkTextButton text={article.title} url={url}/>
        </View>
    </HorizBox>
}

export function QuestionTeaser() {
    const {teaser} = useConfig();
    const config = useConfig();
    if (teaser) {
        return React.createElement(teaser);
    } else {
        console.log('config', config);
        return <UtilityText label='No teaser defined' />
    }
}
