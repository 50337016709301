import { Banner } from "component/banner.js"
import { HorizBox, Pad, PadBox } from "component/basics.js"
import { TextButton } from "component/button.js"
import { colorPurpleBackground, colorTeaserBackground, colorLightBlueBackground, colorTextGrey } from "component/color.js"
import { spacings } from "component/constants"
import { Modal } from "component/modal.js"
import { Heading, UtilityText } from "component/text.js"
import { View } from "react-native";
import { GuidelinesModal } from "../question/CommunityGuidelinesFeature";
import { useState } from "react";

export const HumanReviewsEverythingFeature = {
    key: 'human_premoderation',
    name: 'Human Reviews Everything',
    config: {
        headerBelowInputWidgets: [ModerationNote],
        commentEditBottomWidgets: [ModerationNote],
        commentPostTriggers: [sendPostToModeration],
        commentPostCheckers: [showModalAfterPosting]
    }
}

export function ModerationNote() {
    const [showCommunityGuidelines, setShowCommunityGuidelines] = useState(false)
    return <View>
        <Pad size={20} />
        <View style={{flexDirection: 'row', flexWrap: 'wrap'}}>
            <UtilityText label='Posts are manually moderated to meet the ' />
            <TextButton type="small" label='Community Guidelines' underline onPress={() => setShowCommunityGuidelines(true)} />
            {showCommunityGuidelines && <GuidelinesModal onClose={() => setShowCommunityGuidelines(false)}/>}
            <UtilityText text='.' />
        </View>
    </View>
}

export async function sendPostToModeration({datastore, comment, commentKey}) {
    const key = await datastore.callServerAsync('moderation', 'sendToReview', {
        type: 'comment', setInReview: true, source: 'human_premoderation',
        text: comment.text, key: commentKey, from: comment.from
    });

    // Run derived view triggers as comment gets updated serverside
    if (key) {
        await datastore.callServerAsync('derivedviews', 'runTriggers', {
            type: 'comment', key
        });
    }
}

export function showModalAfterPosting() {
    return {allow: true, modal: HumanModerationModal}
}

export function HumanModerationModal({onClose}) {
    return <Modal onClose={onClose}>
        <PadBox horiz={spacings.lg} vert={spacings.lg}>
            <Heading type="large" weight="medium" label='Thank you!'/>
            <Pad />
            <UtilityText type='large' color={colorTextGrey} label='Your response has been sent to a moderator for review.' />
            <Pad size={32} />
                <Banner color={colorLightBlueBackground}>
                    <Heading label='📫 What happens next'/>
                    <Pad size={16} />
                    <UtilityText label='You will receive an email notification when your post is approved or rejected by moderators.'/>
                </Banner>
                <Pad />
        </PadBox>
    </Modal>    
}

