import { Pad, PadBox, Separator } from "component/basics.js"
import { colorSurfaceDiscovery } from "component/color.js"
import { formatDate } from "component/date.js"
import { Heading, LinkText, UtilityText, WebLinkTextButton } from "component/text.js"
import { useCollection } from "util/datastore.js"
import { View } from "react-native";
import { keyToUrl } from "../../util/util";
import { Image } from "react-native";

export const ReadingListFeature = {
    key: 'readinglist',
    name: 'Reading List',
    config: {
        topicBottomWidgets: [ReadingListWidget],
    }
}

export function ReadingListWidget() {
    const articles = useCollection('backlink_article');

    return <PadBox horiz={20} top={32}>
        <DiscoveryBox>
            <PadBox horiz={20} vert={20}>
                <UtilityText label='Reading List' />
                <Pad size={12} />
                <Heading type="large" weight="medium" label='Learn more about this topic' />
                <Pad size={12} />
                {articles.map((article,i) => <ArticlePreview first={i==0} key={article.key} article={article} />)}
            </PadBox>
        </DiscoveryBox>
    </PadBox>
}

function DiscoveryBox({children}) {
    const s = DiscoveryBoxStyle;
    return <View style={s.box}>
        {children}
    </View>
}
const DiscoveryBoxStyle = {
    box: {
        backgroundColor: colorSurfaceDiscovery,
        borderRadius: 12,
    }
}

function getDateString(date) {
    try {
        const d = new Date(date);
        return formatDate(d);
    } catch (e) {
        return ''
    }
}

function ArticlePreview({first, article}) {
    const s = ArticlePreviewStyle;

    const url = keyToUrl(article.key);
    const dateString = getDateString(article.date);

    return <View>            
        {!first && <PadBox top={20}>
            <Separator />
        </PadBox>}
        <View style={s.outer}>
            <Image style={s.image} source={{uri:article.image}} />
            <View style={s.right}>
                <WebLinkTextButton heading weight='strong' type='small' text={article.title} url={url} />
                <Pad size={8} />
                <UtilityText text={dateString} />
            </View>
        </View>
    </View>
}
const ArticlePreviewStyle = {
    outer: {
        flexDirection: 'row',
        marginTop: 20
    },
    image: {
        width: 100,
        height: 70,
        borderRadius: 8,
    },
    right: {
        marginLeft: 20,
        flexShrink: 1
    }
}

