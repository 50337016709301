import { ModDashboardSidebar } from "../../../structure/zdf/moddashboard";
import { modDashboardDemoData } from "./moddashboard.data";
import { StructureDemo } from "../../../util/instance";
import { DemoSection } from "../../../system/demo";
import { Datastore } from "../../../util/datastore";
import { AddComment, Chat } from "@carbon/icons-react";
import { UtilityText } from "../../../component/text";

export function ModDashboardSidebarDemo() {
    return <Datastore config={{
        moderationPages: [
            {
                id: "page-threads",
                label: "!First",
                icon: AddComment,
                component: <UtilityText text={'First component'}/>,
            },
            {
                id: "page-comments",
                label: "!Second",
                icon: Chat,
                component: <UtilityText text={'Second component (default)'}/>
            }
        ],
        defaultModerationPage: {
            id: "page-comments",
            label: "!Second",
            icon: Chat,
            component: <UtilityText text={'Second component (default)'}/>
        }
    }}>
        <DemoSection text="Mod Dashboard Sidebar">
            <ModDashboardSidebar />
        </DemoSection>
    </Datastore>
}

export function ModDashboardFullDemo() {

    const globals = { initialized: true };

    const modulePublic = {
        question: modDashboardDemoData.modulePublicQuestion,
        moderation: {
            queue: {
                ...modDashboardDemoData.modTaskQueues.thread1,
                ...modDashboardDemoData.modTaskQueues.thread2,
            },
            queue_filtered: {
                awaiting_decision: {
                    ...modDashboardDemoData.modTaskSubQueues.thread1.awaiting_decision,
                    ...modDashboardDemoData.modTaskSubQueues.thread2.awaiting_decision,
                },
                human_approved: {
                    ...modDashboardDemoData.modTaskSubQueues.thread1.human_approved,
                    ...modDashboardDemoData.modTaskSubQueues.thread2.human_approved,
                },
                auto_approved: {
                    ...modDashboardDemoData.modTaskSubQueues.thread1.auto_approved,
                    ...modDashboardDemoData.modTaskSubQueues.thread2.auto_approved,
                },
                rejected: {
                    ...modDashboardDemoData.modTaskSubQueues.thread1.rejected,
                    ...modDashboardDemoData.modTaskSubQueues.thread2.rejected,
                },
            }
        }
    }

    const serverCalls = {
        moderationZdf: {
            ...modDashboardDemoData.serverCalls.moderationZdf
        },
        moderation: {
            ...modDashboardDemoData.serverCalls.moderation
        }
    }

    return (
        <StructureDemo
            globals={globals}
        
            structureKey="moddashboard"
            instanceKey="a"
            features={null}
            testState={{
                roles: ["Moderator"],
                modulePublic: modulePublic,
                serverCall: serverCalls
            }}
        />
    );
}