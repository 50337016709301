import { LengthLimitFeature } from "feature/general/LengthLimitFeature.js"
import { UpvoteFeature } from "feature/general/UpvoteFeature.js"
import { VideoSyncFeature } from "./deprecated/VideoSyncFeature"
import { AgreeDisagreeFeature, CommentSliderFeature, CommentSliderHorizVisualizationFeature, CommentSliderPollPickerFeature, YesNoFeature, CommentSliderVertVisualizationFeature, CommentSliderSliderFeature, CommentSliderEnableVisualizationFeature, CommentSliderNoDefaultFeature } from "./question/CommentSliderFeature"
import { ModerateRepliesStrictlyFeature, PreModeratorFeature, ModerateWithGPTFeature, ModerateWithJigsawFeature, ModerationHelpFeature } from "./moderation/AIPreModeratorFeature"
import { ProfileArticleRecommendationsFeature } from "./zdf/ProfileArticleRecommendationsFeature"
import { ProfileConversationRecommendationsFeature } from "./zdf/ProfileConversationRecommendationsFeature"
import { ProfileInterestsFeature } from "./zdf/ProfileInterestsFeature"
import { VideoQuoteFeature } from "./zdf/VideoQuoteFeature"
import { ArticleTopicTaggingFeature } from "./zdf/ArticleTopicTagFeature"
import { ConvoMatchFeature } from "./zdf/ConvoMatchFeature"
import { ProfileAnswersFeature } from "./profile/ProfileAswersFeature"
import { EditorialQuestionFeature, TanQuestionTeaserFeature, GreenQuestionTeaserFeature, CMSEmbeddedQuestionTeaserFeature } from "./teaser/EditorialQuestionFeature"
import { NarwwhalReactionsFeature, NewPublicReactionsFeature, ReactionTypesFeature } from "./question/ReactionTypesFeature"
import { ReplyNotificationsFeature } from "feature/question/ReplyNotifsFeature.js"
import { VideoVotingFeature, VideoVotingVideoOverlayFeature } from "./zdf/videovoting/VideoVotingArticleFeature"
import { VideoVotingOverviewFeature } from "./zdf/videovoting/VideoVotingOverviewFeature"
import { ProfileSettingsFeature } from "./zdf/ProfileSettingsFeature"
import { ProfileVideoVotingSettingFeature } from "./zdf/videovoting/ProfileVideoVotingSettingFeature.js"
import { CBCRCDemoFeature } from "../demo/cbcrc/componentdemo.js"
import { ZDFDemoFeature } from "../demo/zdf/componentdemozdf.js"
import { DemoConversationFeatures } from "../demo/question/conversationfeatures.js"
import { RepresentingPerspectivesCardFeature, RepresentingPerspectivesFeature, RepresentingPerspectivesSpeechBubbleFeature, RepresentingPerspectivesSubtleBubbleFeature } from "./question/RepresentingPerspectives"
import { ContextEntryPointsFeature } from "./question/ContextEntryPointsFeature"
import { ProfileTitledWriterFeature } from "./cbcrc/profile/ProfileTitledWriterFeature"
import { TitledWriterFeature } from "./cbcrc/general/TitledWriterFeature"
import { TranslationFeature } from "../contrib/srg/translation/TranslationFeature"
import { ConversationHelperAddonFeatures, ConversationHelperFeature } from "./zdf/ConversationHelperFeature"
import { ConversationHelperSettingsFeatures } from "./zdf/ConversationHelperAdminFeature"
import { TeaserDemoFeature } from "../demo/teaser/teaserdemo.js"
import { ProfileAnswersDemo } from "../demo/profile/profileanswers-demo"
import { TopicTeaserFeature, AskMeAnythingTeaserFeature } from "./cbcrc/connect/TopicTeaserFeature"
import { PinCommentFeature } from "./question/PinCommentFeature"
import { ReportCommentFeature } from "./moderation/ReportCommentFeature"
import { AdminModLinkFeature, NewModLinkFeature } from "./admin/AdminModLinkFeature"
import { DemoModerationFeature } from "../demo/moderation/moderation-demo"
import { HideRejectedFeature } from "./moderation/HideRejectedFeature"
import { HumanReviewsEverythingFeature } from "./moderation/HumanReviewsEverythingFeature"
import { DemoFallbacksFeature } from "../demo/moderation/fallbacks-demo"
import { ModerationDashDemoFeature } from "../demo/admin/moderationdash-demo"
import { CloseConversationFeature } from "feature/question/CloseConversationFeature.js"
import { OpenLinksInNewTabFeature } from "feature/general/OpenLinksInNewTabFeature.js"
import { BlacklistFeature } from "./zdf/premoderation/BlacklistFeature"
import { CommunityGuidelinesFeature, CommunityGuidelinesLeftFeature } from "./question/CommunityGuidelinesFeature"
import { SingleResponseHelpFeature } from "./question/SingleResponseHelpFeature"
import { NextTopicPollFeature, TopicPollFeature, TopicPollTopPositionFeature, TopicPollBottomPositionFeature, TopicPollInContentPositionFeature } from "./cbcrc/topic/TopicPollFeature"
import { TopicUserQuestionsFeature, TopicUserQuestionCallToActionFeature, TopicPopularQuestionsFeature, TopicAIModerationTagFeature, TopicOfTheMomentCallToActionFeature } from "./cbcrc/topic/UserQuestionsFeature"
import { TopicRelatedConversationsFeature } from "./cbcrc/topic/RelatedConversationsFeature"
import { TopicRelatedArticlesCarouselFeature } from "./cbcrc/topic/RelatedArticlesCarouselFeature"
import { TopicLiveStatusRightFeature } from "./cbcrc/topic/LiveStatusFeature"
import { TopicParticipantsFeature } from "./cbcrc/topic/TopicParticipants"
import { TopicPhotoTeaserForTopicFeature } from "./teaser/TopicPhotoTeaser"
import { QuestionCardsFeature } from "./topic/QuestionCardsFeature"
import { EmbeddedFeature } from "./question/EmbeddedFeature"
import { ReadingListFeature } from "./topic/ReadingListFeature"
import { AskMeAnythingHeaderFeature } from "./cbcrc/topic/AskMeAnythingHeaderFeature"
import { TopicConfigDemoFeature, TopicConfigFeature } from "../demo/topic/topic-config"
import { DemoFeature, DemoSecondaryFeature } from "demo/core-design-system/comment-config.js"
import { DemoQuestionBasicsFeature } from "../demo/question/questionbasics-demo"
import { PuppetSupportFeature } from "./admin/PuppetTestSupportFeature"
import { DemoFilterFeature } from "../demo/core-design-system/DemoFilter"
import { BasicTeaserFeature } from "./teaser/BasicTeaserFeature"
import { DemoProfileFeature } from "../demo/profile/profile-config"
import { ProfileCommentsFeature } from "./profile/ProfileCommentsFeature"
import { DesignSystemDemoFeature } from "../demo/core-design-system/designsystem-demo"
import { CommentDemoFeature } from "../demo/core-design-system/comment-demo"
import { ProfileDemoFeature } from "../demo/profile/profile-demo"
import { EventlogDemoFeature } from "../demo/admin/eventlog-demo"
import { ProfileEditDemo } from "../demo/profile/profileedit-demo"
import { ProfilePhotoAndNameFeature } from "./profile/ProfilePhotoAndName"
import { AdminUsersFeature } from "./admin/AdminUsersFeature"
import { AdminDemo } from "../demo/admin/admin-demo"
import { TopBarDemoFeature } from "../demo/core-design-system/topbar-demo"
import { BasicFeaturesDemoFeature } from "../demo/core-design-system/basicfeatures-demo"
import { EmbeddedInstanceDemoFeature } from "../demo/core-design-system/datamodel-demo"
import { LoginDemo } from "../demo/core-design-system/login-demo"
import { HelpDemoFeature } from "../demo/core-design-system/help-demo"
import { ProfileDeleteDemo } from "demo/account/profiledelete-demo"
import { AccountDeleteFeature } from "./account/AccountDeleteFeature"
import { AskMeAnythingConfigFeature, BaseTopicConfigFeature, TopicOfTheMomentConfigFeature } from "./cbcrc/topic/TopicOfTheMomentConfigFeature"
import { PinAnswerFeature } from "./cbcrc/topic/PinAnswerFeature"
import { JumpToCommentFeature } from "./zdf/JumpToCommentFeature"
import { AutoApproveFeature } from "./zdf/premoderation/AutoApproveFeature"
import { EnableModDashboardThreadDetailsFeature } from "./zdf/question/EnableThreadModDashboardViewFeature"
import { RecordEntryUrlFeature } from "./question/RecordEntryUrl"
import { AccountRequestDataFeature } from "./account/RequestDataFeature"
import { QuestionListFeature } from "./admin/QuestionList"
import { TopicListFeature } from "./cbcrc/admin/TopicListFeature"
import { AITagFeature } from "./question/AITagFeature"
import { CommentsSortFeature } from "./cbcrc/comments/CommentsSortFeature"
import { DeleteCommentsInModDashboardFeature } from "./zdf/question/DeleteCommentsinModDashboardFeature"
import { PollDemoFeature } from "demo/core-design-system/poll-demo"
import { SurveyDemoFeature } from "demo/general/survey-demo"
import { SatisfactionSurveyFeature, UsabilitySurveyFeature } from "./general/SurveyFeature"
import { VideoVotingListFeature } from "./zdf/admin/VideoVotingListFeature"
import { TopicDemoFeature } from "demo/topic/topic-demo"
import { PuppetDemoFeature } from "demo/admin/puppet-demo"

export function SECTION(label, features) { return { section: true, label, features } }
export function SUBSECTION(label, features) { return { section: true, level: 2, label, features } }
export function COMPOSITE(parent, features) { return { composite: true, parent, features } }
export function CHOOSEONE(label, features) { return { chooseOne: true, label, features } }


export var features = {
    account: [
        SECTION('Account Management', [
            AccountRequestDataFeature,
            AccountDeleteFeature,
        ])
    ],
    simplecomments: [
        SECTION('Developer', [
            COMPOSITE(DemoFeature, [
                DemoSecondaryFeature,
            ]),
            DemoModerationFeature,
            DemoFilterFeature,
        ]),
        SECTION('General', [
            UpvoteFeature,
            LengthLimitFeature,
            BasicTeaserFeature,
            ReplyNotificationsFeature,
            CloseConversationFeature,
            OpenLinksInNewTabFeature
        ])
    ],
    profile: [
        SECTION('Developer', [
            DemoProfileFeature,
            ProfileCommentsFeature
        ]),
        SECTION('General', [
            ProfilePhotoAndNameFeature,
        ])
    ],
    componentdemo: [
        SECTION('Core Component Sets', [
            DesignSystemDemoFeature,
            CommentDemoFeature,
            ProfileDemoFeature,
            EventlogDemoFeature,
            ProfileEditDemo,
            AdminDemo,
            TopBarDemoFeature,
            BasicFeaturesDemoFeature,
            EmbeddedInstanceDemoFeature,
            LoginDemo,
            HelpDemoFeature,
            ProfileDeleteDemo,
            PollDemoFeature,
            SurveyDemoFeature,
            PuppetDemoFeature
        ]),
        SECTION('Configuration', [
            OpenLinksInNewTabFeature
        ])
    ],
    admin: [
        SECTION('Admin Modules', [
            AdminUsersFeature,
            QuestionListFeature,
            TopicListFeature,
            VideoVotingListFeature
        ])
    ]
}

export var defaultFeatureConfig = {
    account: {
        profilerequestdata: true,
        profiledelete: true
    },
    componentdemo: {
        demo_designsystem: true,
        demo_comment: true,
        demo_profile: true,
        demo_eventlog: true,
        demo_profileedit: true,
        demo_admin: true,
        demo_topbar: true,
        demo_basicfeatures: true,
        demo_embedded: true,
        demo_login: true,
        demo_help: true,
        demo_profile_delete: true,
        demo_poll: true,
        demo_survey: true,
        demo_puppet: true
    },
    simplecomments: {
        openlinksinnewtab: true
    },
    profile: {
        profileeditname: true,
    },
    admin: {
        adminusers: true,
        questionlist: true,
        topiclist: true
    }
}

export var roles = {
    Owner: {
        allCapabilities: true,
        can: [
            'adminusers/modify-admins'
        ]
    },
    Developer: {
        inherits: ['Editorial', 'Super-Moderator', 'Analyst']
    },
    'Super-Moderator': {
        inherits: ['Moderator']
    },
    Moderator: {
        can: [
            'remove-comment',
            'moderate'
        ]
    },
    Editorial: {
    },
    Analyst: {
        can: [
            'eventlog/view'
        ]
    }
}

export function addFeatures(newFeaturesForStructs) {
    Object.keys(newFeaturesForStructs).forEach(structureKey => {
        const oldFeatures = features[structureKey] || [];
        const newFeatures = newFeaturesForStructs[structureKey];
        features[structureKey] = [...oldFeatures, ...newFeatures]
    })
}

export function addDefaultFeatures(newDefaultFeatures) {
    Object.keys(newDefaultFeatures).forEach(structureKey => {
        const newDefaults = newDefaultFeatures[structureKey];
        const oldDefaults = defaultFeatureConfig[structureKey] || {};
        const mergedDefaults = { ...oldDefaults, ...newDefaults };
        defaultFeatureConfig[structureKey] = mergedDefaults;
    })
}

export const newFeatures = {
    admin: [
        SECTION('Quick links', [
            AdminModLinkFeature,
            NewModLinkFeature
        ]),
        SECTION('Screens', [
            PuppetSupportFeature
        ])
    ],
    article: [
        COMPOSITE(EditorialQuestionFeature, [
            CHOOSEONE('Teaser Type', [
                GreenQuestionTeaserFeature,
                TanQuestionTeaserFeature,
                AskMeAnythingTeaserFeature,
                TopicTeaserFeature
            ])
        ]),
        // EditorialQuestionFeature,
        COMPOSITE(VideoVotingFeature, [
            VideoVotingVideoOverlayFeature
        ]),
    ],
    topic: [
        SECTION('Topic template', [
            COMPOSITE(BaseTopicConfigFeature, [
                TopicUserQuestionCallToActionFeature,
            ]),
            COMPOSITE(TopicOfTheMomentConfigFeature, [
                TopicLiveStatusRightFeature,
                TopicParticipantsFeature,
                TopicOfTheMomentCallToActionFeature,
                CommunityGuidelinesLeftFeature,
            ]),
            COMPOSITE(AskMeAnythingConfigFeature, [
                AskMeAnythingHeaderFeature,
                CommunityGuidelinesLeftFeature,
            ]),
        ]),
        SECTION('Content', [
            TopicUserQuestionsFeature, // DEV feature enabled by default
            TopicPopularQuestionsFeature, // DEV feature enabled by default
            COMPOSITE(TopicPollFeature, [
                CHOOSEONE('Position', [
                    TopicPollTopPositionFeature,
                    TopicPollInContentPositionFeature, 
                    TopicPollBottomPositionFeature
            ])]),
            TopicRelatedConversationsFeature,
            TopicRelatedArticlesCarouselFeature,
            NextTopicPollFeature,
            QuestionCardsFeature, // DEV feature
            UpvoteFeature, // DEV feature enabled by default
        ]),
        SECTION('Moderation', [
            HideRejectedFeature, // DEV feature enabled by default
            COMPOSITE(PreModeratorFeature, [
                ModerateWithGPTFeature,
                ModerateWithJigsawFeature,
                ModerateRepliesStrictlyFeature,
                BlacklistFeature,
                AutoApproveFeature,
            ]),
            ModerationHelpFeature, // DEV feature enabled by default
            ReportCommentFeature,
            EnableModDashboardThreadDetailsFeature,
            DeleteCommentsInModDashboardFeature
        ]),
        SECTION('General', [
            TopicAIModerationTagFeature, // DEV feature enabled by default
            CommunityGuidelinesFeature, // DEV feature enabled by default
            ContextEntryPointsFeature,
            ReadingListFeature,
            PinCommentFeature,
            ReplyNotificationsFeature, // DEV feature enabled by default
            RecordEntryUrlFeature, // DEV feature enabled by default
            TopicPhotoTeaserForTopicFeature, // DEV feature
            TopicConfigFeature, // DEV feature disabled by default
            TitledWriterFeature, // DEV feature enabled by default
            PinAnswerFeature, // DEV feature enabled by default
            CommentsSortFeature, // DEV feature enabled by default
            SatisfactionSurveyFeature, // DEV feature enabled by default
            UsabilitySurveyFeature, // DEV feature enabled by default
            EnableModDashboardThreadDetailsFeature, // Dev feature, enables detail view within mod dashboard
        ]),
        SECTION('Fallbacks', [
            HumanReviewsEverythingFeature,
            CloseConversationFeature, // this needs to be supported in Topic, right now it doesnt do anything
        ])
    ],
    simplecomments: [
        SECTION('PSI Extensions', [
            CommentSliderFeature,
            TranslationFeature,
            VideoSyncFeature,
            VideoQuoteFeature,
            ArticleTopicTaggingFeature,
            ConvoMatchFeature,
            CommunityGuidelinesFeature,
        ]),
        SECTION('Moderation', [
            HideRejectedFeature,
            COMPOSITE(PreModeratorFeature, [
                ModerateWithGPTFeature,
                ModerateWithJigsawFeature,
                ModerateRepliesStrictlyFeature,
                BlacklistFeature,
                AutoApproveFeature
            ]),
            ReportCommentFeature,
            HumanReviewsEverythingFeature,
            CloseConversationFeature
        ]),
    ],
    question: [
        SECTION('Conversation Type', [
            COMPOSITE(CommentSliderFeature, [
                CHOOSEONE('Spectrum', [
                    YesNoFeature,
                    AgreeDisagreeFeature
                ]),
                CHOOSEONE('Composer', [
                    CommentSliderPollPickerFeature,
                    CommentSliderSliderFeature,
                    CommentSliderNoDefaultFeature
                ]),
                COMPOSITE(CommentSliderEnableVisualizationFeature, [
                    CHOOSEONE(null, [
                        CommentSliderHorizVisualizationFeature,
                        CommentSliderVertVisualizationFeature
                    ]),
                ]),
            ]),
            ContextEntryPointsFeature,
            COMPOSITE(ReactionTypesFeature, [
                CHOOSEONE('Reaction Set', [
                    NewPublicReactionsFeature,
                    NarwwhalReactionsFeature
                ])
            ]),
            COMPOSITE(RepresentingPerspectivesFeature, [
                CHOOSEONE('UI Variant', [
                    RepresentingPerspectivesCardFeature,
                    RepresentingPerspectivesSpeechBubbleFeature,
                    RepresentingPerspectivesSubtleBubbleFeature
                ]),
            ]),
        ]),
        SECTION('Moderation', [
            HideRejectedFeature,
            COMPOSITE(PreModeratorFeature, [
                ModerateWithGPTFeature,
                ModerateWithJigsawFeature,
                ModerateRepliesStrictlyFeature,
                BlacklistFeature,
                AutoApproveFeature,
            ]),
            ModerationHelpFeature,
            ReportCommentFeature,
            EnableModDashboardThreadDetailsFeature,
            DeleteCommentsInModDashboardFeature
        ]),
        SECTION('General', [
            AITagFeature,
            JumpToCommentFeature,
            LengthLimitFeature,
            CommunityGuidelinesFeature,
            COMPOSITE(ConversationHelperFeature, [
                ...ConversationHelperAddonFeatures,
                ...ConversationHelperSettingsFeatures,
            ]),
            EmbeddedFeature,
            OpenLinksInNewTabFeature,
            PinCommentFeature,
            RecordEntryUrlFeature,
            VideoVotingOverviewFeature,
            ReplyNotificationsFeature,
            DemoFeature,
            SingleResponseHelpFeature,
            CMSEmbeddedQuestionTeaserFeature,
            SatisfactionSurveyFeature,
            UsabilitySurveyFeature,
            TitledWriterFeature,
            TranslationFeature,
            UpvoteFeature,
        ]),
        SECTION('Fallbacks', [
            HumanReviewsEverythingFeature,
            CloseConversationFeature,
        ])
    ],
    profile: [
        SECTION('General', [
            ProfileAnswersFeature,
        ]),
        SECTION('ZDF', [
            ProfileInterestsFeature,
            ProfileConversationRecommendationsFeature,
            ProfileArticleRecommendationsFeature,
            COMPOSITE(ProfileSettingsFeature, [
                ProfileVideoVotingSettingFeature
            ])
        ]),
        SECTION('CBCRC', [
            ProfileTitledWriterFeature,
        ]),        
    ],
    componentdemo: [
        SECTION('PSI Components', [
            CBCRCDemoFeature,
            ZDFDemoFeature,
            DemoConversationFeatures,
            TeaserDemoFeature,
            ProfileAnswersDemo,
            DemoModerationFeature,
            DemoFallbacksFeature,
            ModerationDashDemoFeature,
            TopicConfigDemoFeature,
            DemoQuestionBasicsFeature,
            TopicDemoFeature
        ])
    ],
}

export const newDefaultFeatures = {
    admin: {
        admin_link_mod: true,
        admin_new_link_mod: true,
        editorialtopic: true,
        puppettest: true,
        videovotinglist: true
    },
    componentdemo: {
        demo_cbcrc: true,
        demo_zdf: true,
        demo_conversation: true,
        demo_teaser: true,
        demo_fallbacks: true,
        demo_moderationdash: true,
        demo_moderation: true,
        demo_questionbasics: true,
        demo_topic: true,
        demo_topic_config: true
    },
    article: {
        editorialquestion: true,
        videovotingArticle: false,
        videovotingArticleTextIntegration: false,
    },
    topic: {
        editorialtopic: true,
        topictype: true,
        poll: true,
        upvote: true,
        commentssort: true,
        premoderator: false,
        record_entry_url: true,
        context_entry_points: true,
        community_guidelines: true,
        pin_comment: true,

        // All CBC modules on by default for now
        amaheader: true,
        basetopicconfig: true,
        topicuserctaquestions: true,
        topicpopularquestions: true,
        topicuserquestions: true,
        topicrelatedcontent: true,
        topicpoll: true,
        nexttopicpoll: true,
        topicfollow: true,
        topiclivestatusright: true,
        topicparticipants: true,
        titledWriter: true,
        pin_answer: true,
        tomuserctaquestions: true,

        topicphototeaser_topic: true,
        replynotifications: true,

        // Moderation 
        topicaimoderationtag: true,
        hide_rejected: true,
        aimoderation: true,
        jigsawmoderation: true,
        premoderation: true,
        report_comment: true,
        moderationhelp: true,
        autoapprovecomments: true,
        deletecommentsinmoddashboard: true,
    },
    articlequestions: {
        upvotequestion: true,
        questionteaser: true
    },
    simplecomments: {
        premoderator: true,
        articletopictagging: false,
        convomatch: false,
        translationfeature: false,


        hide_rejected: true,
        premoderation: true,
        aimoderation: true,
        jigsawmoderation: true,
        strictreplymoderation: true,
        report_comment: true
    },
    question: {
        lengthlimit: false,
        upvote: false,
        reactiontypes: true,
        replynotifications: true,
        commentslider: false,
        translationfeature: false,
        community_guidelines: true,
        votingoverview: false,
        record_entry_url: true,
        aitag: true,
        pin_comment: true,
        single_response_help: true,

        jumptocomment: true,
        context_entry_points: true,

        // Radio button first features: TODO - auto-select these
        commentslider_yes: true,
        commentslider_slider: true,
        commentslider_horiz_visualization: true,
        commentslider_enable_visualization: true,
        newpublicreactions: true,
        // representing_perspectives_card: true,
        representing_perspectives_subtle_bubble: true,

        // Moderation 
        hide_rejected: true,
        aimoderation: true,
        jigsawmoderation: true,
        premoderation: true,
        report_comment: true,
        moderationhelp: true,
        autoapprovecomments: true,
        deletecommentsinmoddashboard: true,

        //Conversation helper Settings
        conversationhelper_timing_comments: true,
        conversationhelper_timing_comments_1: true,
        conversationhelper_personality_b: true,
        conversationhelper_scope_all_comments: true,
        conversationhelper_scope_threshold_1: true,
        conversationhelper_top_x_percent_3: true,
        conversationhelper_guidance_update_comment_interval_0: true,
        conversationhelper_guidance_feedback_set_1: true,
        cmsembeddedquestionteaser: true,
    },
    profile: {
        profileanswers: true,
        profiledelete: true
    }
}

export const newRoles = {
    Owner: {
    },
    Developer: {
        inherits: ['Editorial', 'Super-Moderator', 'Analyst'],
        can: ['features/toggle-experimental']
    },
    'Super-Moderator': {
        inherits: ['Moderator'],
        can: ['moderation/edit-blacklist']
    },
    Moderator: {
        can: ['moderation/judge', 'moderation/view', 'moderation/view-blacklist']
    },
    Editorial: {
        can: ['features/toggle-stable']
    },
    Analyst: {
        can: [
            'eventlog/view'
        ]
    }
}
