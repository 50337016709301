import { useModulePublicData } from "../../../util/datastore";
import { colorBlack } from "../../color";
import { UtilityText } from "../../text";

export function ModDashboardDateTime({
    timestamp,
    locale = null,
    color = colorBlack,
    language,
    type = "small"
}) {
    const date = new Date(timestamp);

    if (!language) {
        const siloLanguage = useModulePublicData("language");
        language = siloLanguage || "english";
    }

    let clientLocale = "";
    if (locale) {
        clientLocale = locale;
    }
    else {
        switch (language) {
            case "english":
                clientLocale = "en-US";
                break;
            case "german":
                clientLocale = "de-DE";
                break;
            case "french":
                clientLocale = "fr-CA";
                break;
            default:
                clientLocale = "en-US";
                break;
        }
    }
    
    const dateOptions = { day: "2-digit", month: "short", year: "numeric" };
    const timeOptions = { hour: "numeric", minute: "2-digit" };

    const formattedDate = date.toLocaleDateString(clientLocale, dateOptions);
    let formattedTime = date.toLocaleTimeString(clientLocale, timeOptions).toLowerCase();

    if (language === "german") {
        formattedTime += " Uhr";
    } else if (language === "french") {
        formattedTime = formattedTime.replace(":", "\u00A0h\u00A0");
    } else {
        formattedTime = formattedTime.replace(" ", "");
    }

    const formattedDateTime = `${formattedDate} | ${formattedTime}`;
    return <UtilityText text={formattedDateTime} color={color} type={type} />;
}