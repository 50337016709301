import { DataVizText, Heading, Paragraph, UtilityText } from "component/text.js"
import { useCollection, useDatastore, useInstanceKey, useStructureKey, useGlobalProperty } from "util/datastore.js"
import { useEffect, useRef, useState } from "react";
import { logEventAsync } from "util/eventlog.js"
import { StyleSheet, View } from "react-native";
import { HorizBox, HoverView, Narrow, Pad, PadBox, Separator, WindowTitle } from "component/basics.js"
import { colorLightBlueBackground, colorGreen, colorPinkBackground, colorRed, colorTextGrey, colorWhite, colorGreyBorder, colorDisabledText } from "component/color.js"
import { Tag, CTAButton, IconButton, TextButton } from "component/button.js"
import { formatDate } from "component/date.js"
import { useLanguage } from "component/translation.js"
import { useIsAdmin } from "component/admin.js"
import { gotoInstance } from "util/navigate.js"
import { TabBar, TabContent, TabNavigation } from "../../contrib/zdf/tabnavigation";
import { FaceImage } from "component/people.js"
import { AutoModeratorReactionTypesWidget, countReactionsPerType } from "../../component/zdf/conversationhelper/autoModeratorReactions";
import { ScrollView } from "react-native-web";
import { Banner } from "component/banner.js"
import { REPLACE, useConfig } from "util/features.js"
import { SpacedArray } from "system/demo.js"
import { imagesZdf } from "../../assets/zdf";
import { RichText } from "component/richtext.js"
import { ArrowUpRight, Time, Information, Edit } from "@carbon/icons-react";
import { SECTION, COMPOSITE, CHOOSEONE } from "feature/index.js"
import { PrivateCommentFeedbackFeature } from "./PrivateCommentFeedbackFeature";
import { AccordionField } from "component/form.js"
import { REPLACE_ZDF_AccordionField } from "../../component/zdf/form";
import { useTypewriter } from "../../component/zdf/typewriter";
import { Modal } from "component/modal";

/*
Figma: https://www.figma.com/design/VDCebWSu7lU0GynG1qmtF5/Conversation-Helper---Testing?node-id=0-1&t=VlCGmpdSrRKAKHst-0

The Conversation Helper is an auto-moderation feature that aims to steer and guide the conversation by providing public, thought-provoking prompts. They are posted in a widget above the conversation and are visible to everyone. The Conversation Helper's guidance is twofold: it highlights interesting and new aspects for users to discuss and shares a reminder about how to improve the conversation.

The first thought-provoking prompt is generated automatically when turning this feature on. It is always based on the topic of the conversation and disregards existing comments if there are any.

After generating the initial thought-provoking prompt, the following process may be triggered every time a new comment is posted or an existing comment is updated:
1. Look at all comments and detect problems in the conversation.
2. If problems are found, generate a new thought-provoking prompt that relates to the problems detected. If no problems are found, keep the current thought-provoking prompt.

The analysis frequency can be adjusted in the feature menu. For example, you can run the analysis after every new comment posted, or only when 5 new comments have been posted.

Additional sub-features:
- Feedback Guidance: users can rate the current thought-provoking prompt and reminder with various reaction types
- Conversation summary: summarizes the comments whenver a new comment is posted
- Help history: show a history of all previous reminders (currently doesn't include the thought-provoking questions)
*/

export const ConversationHelperFeature = {
    name: 'Conversation Helper',
    key: 'conversationhelper',
    subscreens: {
        prompthistory: () => <ThoughtProvokingPromptHistory />,
        conversationhelperadmin: () => <ConversationHelperAdminScreen />
    },  
    config: {
        pageTopWidgets: [ThoughtProvokingPromptSelector],
        commentPostTriggers: [checkConversationAsync, summarizeDiscussion],
        commentInputPlaceholder: 'Share your thoughts...',
        commentAllowEmpty: false,
    },
    defaultConfig: {
        reactionsEnabled: false,
        conversationHelperReactionTypes: [
        ],
        historyEnabled: false,
        summaryEnabled: false,   
        aiGeneratedEnabled: false,
        timingSetting: {
            type: "comment",
            every: 1
        },
        conversationHelperAppearance: {
            name: "Auto-Moderator",
            photoUrl: imagesZdf.conversationhelperProfileBulb
        },
        moderatorType: "a",
        analysisScope: "allComments",
        minCommentThreshold: 1,
        topXPercentFloat: 0.1,
        guidanceUpdateIntervalEnabled: true,
        guidanceUpdateCommentInterval: null,
    },
}

export const ConversationHelperGuidanceFeedbackFeature = {
    name: 'Guidance Feedback',
    parentFeature: 'conversationhelper',
    key: 'conversationhelper_reactions',
    config: {
        reactionsEnabled: true,
        conversationHelperReactionTypes: [
            { label: 'Thank you', emoji: '🙏' },
            { label: 'Helpful', emoji: '➕' },
            { label: 'Disagree', emoji: '🤔' },
        ],
    }
};


const ConversationHelperGuidanceFeedbackSet2Feature = {
    name: 'Set 2',
    key: 'conversationhelper_guidance_feedback_set_2',
    parentFeature: 'conversationhelper_reactions',
    config: {
        conversationHelperReactionTypes: REPLACE([
            { label: 'Thank you', emoji: '🙏' },
            { label: 'New to me', emoji: '💡' },
            { label: 'Respect', emoji: '🤝🏽' },
            { label: 'Disagree', emoji: '🤔' },
        ])

    }
}

const ConversationHelperGuidanceFeedbackSet1Feature = {
    name: 'Set 1',
    key: 'conversationhelper_guidance_feedback_set_1',
    parentFeature: 'conversationhelper_reactions',
    config: {}
}


export const ConversationHelperHistoryFeature = {
    name: 'Help History',
    parentFeature: 'conversationhelper',
    key: 'conversationhelper_history',
    config: {
        historyEnabled: true
    }
};

export const ConversationHelperSummaryFeature = {
    name: 'Conversation Summary',
    parentFeature: 'conversationhelper',
    key: 'conversationhelper_summary',
    config: {
        summaryEnabled: true
    }
}

export const ConversationHelperAIGenerated = {
    name: 'AI Generated Info',
    parentFeature: 'conversationhelper',
    key: 'conversationhelper_ai_generated',
    config: {
        aiGeneratedEnabled: true
    }
}

export const ConversationHelperAddonFeatures = [
    SECTION("Add-ons", [
        COMPOSITE(ConversationHelperGuidanceFeedbackFeature, [
                        CHOOSEONE('Feedback Set', [
                            ConversationHelperGuidanceFeedbackSet1Feature,
                            ConversationHelperGuidanceFeedbackSet2Feature
                        ])
                    ]),
                    
        ConversationHelperHistoryFeature,
        ConversationHelperSummaryFeature,
        PrivateCommentFeedbackFeature,
        ConversationHelperAIGenerated,
    ]),
];

/** Selects the most recent thought-provoking prompt */
export function ThoughtProvokingPromptSelector() {
    const datastore = useDatastore();

    // Get the latest prompt first
    const thoughtProvokingPrompts = useCollection("thought_provoking_prompt", { sortBy: 'time', reverse: true });
    const thoughtProvokingPromptExists = (thoughtProvokingPrompts && thoughtProvokingPrompts.length > 0);

    useEffect(() => {
        // If there is no thought-provoking prompt yet, generate one based on the conversation topic
        if (!thoughtProvokingPromptExists) {
            generateThoughtProvokingPromptFromTopicAsync({ datastore });
        }
    }, []);

    return (
        <View>
            {thoughtProvokingPromptExists && (
                <ThoughtProvokingPrompt
                    currentThoughtProvokingPrompt={thoughtProvokingPrompts[0]}
                    allThoughtProvokingPrompts={thoughtProvokingPrompts}
                    showAuthor={true}
                    textIsAnimated={true}
                />
            )}
        </View>
    );
}

/** Displays a thought-provoking prompt in a widget */
export function ThoughtProvokingPrompt({ currentThoughtProvokingPrompt, textIsAnimated = false }) {
    const s = ThoughtProvokingPromptStyle;
    const firstThoughProvokingPromptKey = useRef(null);

    const datastore = useDatastore();
    const language = useLanguage();
    let config = useConfig();

    const [showHistory, setShowHistory] = useState(false);
    const [hasClicked, setHasClicked] = useState(false);

    const summary = useCollection("summary", { sortBy: "time", reverse: true });
    const hasSummary = summary && summary.length > 0;
    const newestSummary = summary[0];


    if (config.conversationHelperAppearance === undefined) {
        config = {
            ...config,
            conversationHelperAppearance: {
                name: "Auto-Moderator",
                photoUrl: imagesZdf.conversationhelperProfileBulb,
            },
        };
    }

    useEffect(() => {
        if (hasClicked)
            logEventAsync(
                datastore,
                showHistory ? "conversationhelper-view-history" : "conversationhelper-close-history"
            );
    }, [hasClicked, showHistory]);

    useEffect(()=>{
        if(!firstThoughProvokingPromptKey.current) firstThoughProvokingPromptKey.current = currentThoughtProvokingPrompt.key
    }, [currentThoughtProvokingPrompt])
    const firstRenderedPrompt = !firstThoughProvokingPromptKey.current || currentThoughtProvokingPrompt.key === firstThoughProvokingPromptKey.current

    const typeWriterReminder = useTypewriter({
        text: currentThoughtProvokingPrompt.reminder,
        play: false,
        timePerCharacterInMs: (textIsAnimated && !firstRenderedPrompt) ? 10 : 0,
    })
 
    const typeWriterSuggestedPerspective = useTypewriter({
        play: true,
        text: currentThoughtProvokingPrompt.suggestedPerspective,
        timePerCharacterInMs:  (textIsAnimated && !firstRenderedPrompt) ? 10 : 0,
        nextTypewriter: typeWriterReminder
    })

    const [showModal, setShowModal] = useState(false);
    const onPress = () => {
        setShowModal(!showModal);
    };

    return (
        <View style={[s.coloredBackground]}>
            <PadBox horiz={20} vert={20}>
                <ConversationHelperAdminButton/>
                <HorizBox center spread>
                    <HorizBox center>
                        <FaceImage photoUrl={config.conversationHelperAppearance.photoUrl} />
                        <Pad size={10} />
                        <Heading center type="large" weight="medium" label={"Hello everyone!"} />
                        <Pad size={10} />
                        <View>
                            <UtilityText
                                color={colorTextGrey}
                                label={"{time}"}
                                type="tiny"
                                formatParams={{
                                    time: formatDate(currentThoughtProvokingPrompt.time, language),
                                }}
                            />
                        </View>
                    </HorizBox>
                    {config.historyEnabled && (
                        <HorizBox right center>
                            <Time color={colorTextGrey}/>
                            <Pad size={5} />
                            <TextButton
                                label={"Help history"}
                                type="small"
                                strong
                                color={colorTextGrey}
                                underline={false}
                                onPress={() => {
                                    setHasClicked(true);
                                    setShowHistory(!showHistory);
                                    datastore.pushSubscreen("prompthistory");
                                }}
                            />
                        </HorizBox>
                    )}
                </HorizBox>
                <Pad />
                <View>
                    <Heading
                        text={typeWriterSuggestedPerspective.displayText}
                        type="small"
                    />
                    <Pad size={8} />
                    <Paragraph
                        type="large"
                        text={typeWriterReminder.displayText}
                    />
                </View>
                {config.reactionsEnabled && (
                    <AutoModeratorReactionTypesWidget thoughtProvokingPrompt={currentThoughtProvokingPrompt} />
                )}
                {config.summaryEnabled && hasSummary && (
                    <View style={{ marginBottom: -16, marginHorizontal: -16 }}>
                        <Pad size={10} />
                        <REPLACE_ZDF_AccordionField
                            nameInLog="Conversation Summary"
                            titleContent={
                                <PadBox left={16}>
                                    <UtilityText weight='medium' label={"Conversation Summary"} />
                                </PadBox>
                            }
                        >
                            <View>
                                <PadBox horiz={16}>
                                    <Separator />
                                </PadBox>
                                <PadBox top={16} left={16} right={16 * 3} bottom={16}>
                                    <Paragraph text={newestSummary.summary} />
                                </PadBox>
                            </View>
                        </REPLACE_ZDF_AccordionField>
                    </View>
                )}
                {config.aiGeneratedEnabled && (
                <>
                <Pad size={24} />
                <View style={{flexDirection: 'row', alignItems: 'center' }}>
                    <Paragraph
                        type="small"
                        color={colorDisabledText}
                        label="This guidance is generated by AI."
                    />
                    <Pad size={8} />
                    <View style={s.iconWrapper}>
                        <HoverView onPress={onPress} testID="ai-generated-info" style={s.iconTapTarget}>
                            <Information color={colorDisabledText}/>
                        </HoverView>
                        {showModal && <AIInfoModal onClose={() => setShowModal(false)} />}
                    </View>
                </View>
                </>
                )}
            </PadBox>
            <Separator />
        </View>
    );
}

export function AIInfoModal({onClose}) {
    return (
        <Modal onClose={onClose}>
            <PadBox horiz={20} vert={40}>
                <Heading
                    label="About Conversation Helper"
                    type="large"
                    weight="medium"
                />
                <Pad size={8} />
                <Paragraph 
                    type="large" 
                    color={colorTextGrey}
                    label="To maintain a welcoming and respectful atmosphere, we also use AI to support moderation. In this conversation, an AI gives hints for a more constructive conversation atmosphere and summarizes the conversation."
                />
            </PadBox>  
        </Modal>
    )
}

const ThoughtProvokingPromptStyle = StyleSheet.create({
    coloredBackground: {
        backgroundColor: colorLightBlueBackground
    },
    historyTitleBackgroundColor: {
        backgroundColor: colorPinkBackground
    },
    iconTapTarget: {
        width: 44,
        height: 44,
        justifyContent: 'center',
        alignItems: 'center',
    },
    iconWrapper: {
        position: 'relative',
        width: 16,
        height: 16,
        justifyContent: 'center',
        alignItems: 'center',
    }
})

/** Displays the history of all thought-provoking prompts */
export function ThoughtProvokingPromptHistory() {
    const s = ThoughtProvokingPromptStyle;
    const allThoughtProvokingPrompts = useCollection("thought_provoking_prompt", { reverse: true })
    const historyAvailable = allThoughtProvokingPrompts && allThoughtProvokingPrompts.length > 0;

    return (
        <ScrollView>
            <View>
                <Banner color={s.historyTitleBackgroundColor.backgroundColor}>
                    <PadBox vert={20} horiz={10}>
                        <Heading label={"Help history"} />
                    </PadBox>
                </Banner>
                <Pad size={10} />
                <PadBox vert={0}>
                    {!historyAvailable ? (
                        <UtilityText label={"No history available."} />
                    ) : (
                        allThoughtProvokingPrompts.map((thoughtProvokingPrompt) => (
                            <View key={"prompt-" + thoughtProvokingPrompt.key}>
                                <ThoughtProvokingPromptHistoryItem thoughtProvokingPrompt={thoughtProvokingPrompt} />
                            </View>
                        ))
                    )}
                </PadBox>
            </View>
        </ScrollView>
    );
}

/** A thought-provoking prompt as displayed in the history */
export function ThoughtProvokingPromptHistoryItem({ thoughtProvokingPrompt }) {
    const language = useLanguage();
    const config = useConfig();

    const reactions = useCollection('thought_provoking_prompt_reactions', { filter: { reactTo: thoughtProvokingPrompt.key } });
    const hasReactions = reactions && reactions.length > 0;
    const countedReactionsPerType = countReactionsPerType(reactions);
    const reactionMap = config.conversationHelperReactionTypes;

    // Filter reactions by type so we only list each type once
    const uniqueReactions = reactions.filter((reaction, index, self) => {
        return self.findIndex(r => r.reactionType === reaction.reactionType) === index;
    })

    return (
        <View>
            <PadBox vert={10} horiz={20}>
                <DataVizText
                    type={"heading2"}
                    label={"{time}"}
                    formatParams={{ time: formatDate(thoughtProvokingPrompt.time, language) }}
                />
                <PadBox vert={10} />
                <UtilityText weight='medium' text={thoughtProvokingPrompt.suggestedPerspective}/>
                <PadBox vert={6}/>
                <UtilityText text={thoughtProvokingPrompt.reminder} />
                <PadBox />

                {config.reactionsEnabled && hasReactions ? (
                    <HorizBox>
                        {uniqueReactions
                            .filter((reaction) =>
                                reactionMap.some((entry) => entry.label === reaction.reactionType)
                         )
                            .map((reaction, id) => (
                            <PadBox key={"padding-" + id} vert={15} right={5}>
                                <Tag
                                    key={reaction.reactionType}
                                    text={countedReactionsPerType[reaction.reactionType].toString()}
                                    emoji={
                                        reactionMap.find((entry) => entry.label === reaction.reactionType)?.emoji
                                    }
                                />
                            </PadBox>
                        ))}
                    </HorizBox>
                ) : (
                    <Pad size={20} />
                )}
                <Separator />
            </PadBox>
        </View>
    );
}

/** This is called every time a comment is posted. Based on the configured analysis frequency, it checks if there are problems in the conversation and generates a new thought-provoking prompt. */
async function checkConversationAsync({ datastore }) {

    const timingSetting = datastore.getConfig().timingSetting;
    const comments = datastore.getCollection("comment", { sortBy: "time", reverse: true });

    if (timingSetting.type === "manual") {
        return;
    } else if (timingSetting.type === "comment") {
        let lastAnalysisTime = datastore.getGlobalProperty("lastConversationAnalysisTime");
        if (!lastAnalysisTime) lastAnalysisTime = 0;

        const newCommentsSinceLastAnalysis = getNewCommentsSinceTime({ comments, timestamp: lastAnalysisTime });
        if (newCommentsSinceLastAnalysis.length < timingSetting.every) {
            return;
        }
    }

    const commentsToAnalyze = getCommentsToAnalyzeAsJsonString({ datastore });

    const problemsResult = await checkForProblemsInConversationAsync({
        datastore,
        comments: commentsToAnalyze,
        triggerType: "automatic",
    });

    if (problemsResult.problemsFound) {
        generateThoughtProvokingPromptFromConversationAsync({
            datastore,
            comments: commentsToAnalyze,
            problemsExplanation: problemsResult.explanation,
            triggeredBy: "problems"
        });
    } else if (problemsResult.problemsFound === false) {
        updateThoughtProvokingPromptWithoutProblems({datastore});
    }
}

async function updateThoughtProvokingPromptWithoutProblems({datastore}) {
    const config = datastore.getConfig();

    if (!config.guidanceUpdateIntervalEnabled || !config.guidanceUpdateCommentInterval) {
        return;
    }

    const comments = datastore.getCollection("comment", { sortBy: "time", reverse: true });
    let lastGuidanceUpdateTime = datastore.getGlobalProperty("lastGuidanceUpdateTime"); 

    if (lastGuidanceUpdateTime === null || lastGuidanceUpdateTime === undefined) {
        lastGuidanceUpdateTime = 0;
    }
    // console.log("Last guidance update", lastGuidanceUpdateTime, new Date(lastGuidanceUpdateTime));

    const commentsSinceLastGuidanceUpdate = getNewCommentsSinceTime({
        comments: comments,
        timestamp: lastGuidanceUpdateTime,
    });
    // console.log("Comments since last guidance update: ", commentsSinceLastGuidanceUpdate);

    if (config.guidanceUpdateCommentInterval) {
        // console.log("Guidance comment interval", config.guidanceUpdateCommentInterval);

        if (commentsSinceLastGuidanceUpdate.length >= config.guidanceUpdateCommentInterval) {
            generateThoughtProvokingPromptFromConversationAsync({
                datastore,
                comments: commentsSinceLastGuidanceUpdate,
                problemsExplanation: "No problems found",
                triggeredBy: "comment threshold for guidance update"
            });
        }
    }
    
}

/** Returns the comments that are relevant to the conversation analysis based on the configured scope */
function getCommentsToAnalyzeAsJsonString({ datastore }) {
    const comments = datastore.getCollection("comment",{});
    const config = datastore.getConfig();

    let cleanedComments = [];

    if (config.analysisScope === "allComments") {
        cleanedComments = getCommentsWithoutUnnecessaryInfo({ comments: comments });
    }
    else if (config.analysisScope === "selectTopXPercent") {
        const sortedComments = sortCommentsByEditedAndTime({ comments });
        const recentComments = getRecentCommentsByPercentage({
            sortedComments: sortedComments,
            minCommentThreshold: config.minCommentThreshold ?? 1,
            topXPercentFloat: config.topXPercentFloat??0.1
        });
        cleanedComments = getCommentsWithoutUnnecessaryInfo({ comments: recentComments });
    }
    else if (config.analysisScope === "newCommentsSinceLastAnalysis") {
        const lastAnalysisTime = datastore.getGlobalProperty("lastConversationAnalysisTime");
        const commentsSinceLastAnalysis = getNewCommentsSinceTime({
            comments: comments,
            timestamp: lastAnalysisTime,
        });
        cleanedComments = getCommentsWithoutUnnecessaryInfo({ comments: commentsSinceLastAnalysis });
    }

    const commentsToAnalyze = cleanedComments.length === 0 ? "No comments to analyze." : JSON.stringify(cleanedComments);
    return commentsToAnalyze;
}

/** Sorts comments according to when they were last updated, prioritizing the value of `edited` if it exists, otherwise `time` */
function sortCommentsByEditedAndTime({ comments }) {
    // Compare times of two comments and sort in ascending order
    const sortedComments = comments.sort((a, b) => {
        if (a.edited && b.edited) {
            return a.edited - b.edited;
        } else if (a.edited && b.time) {
            return a.edited - b.time;
        } else if (a.time && b.edited) {
            return a.time - b.edited;
        } else {
            return a.time - b.time;
        }
    });
    // Descending order with newest comments at the top
    sortedComments.reverse();

    return sortedComments;
}

function getNewCommentsSinceTime({ comments, timestamp }) {
    const commentsSinceLastAnalysis = comments.filter((comment) => {
        return (comment.time > timestamp || comment.edited > timestamp);
    })
    return commentsSinceLastAnalysis;
}

/**
 * Returns recent comments based on a given percentage 
 * @param {Array} sortedComments - An array of comments sorted by their time
 * @param {number} minCommentThreshold - The amount of comments needed until percentage is applied and older comments are ignored
 * @param {float} topXPercentFloat - How many percent of the comments should be used
 * @returns {Array}
 */
function getRecentCommentsByPercentage({ sortedComments, minCommentThreshold = 1, topXPercentFloat = 0.1 }) {
    const numberOfComments = sortedComments.length;

    if (!sortedComments || numberOfComments === 0 || numberOfComments < minCommentThreshold) {
        return sortedComments;
    }

    if (topXPercentFloat > 1.0) topXPercentFloat = 1.0;

    // Rounding up to the next integer so there's always at least one comment to analyze
    let endIndex = Math.ceil(numberOfComments * topXPercentFloat);

    if (endIndex > sortedComments.length) {
        endIndex = sortedComments.length;
    }

    const newestComments = sortedComments.slice(0, endIndex);

    return newestComments;
}

/** Deletes all unnecessary info from a comment array except for `key`, `text` and `replyTo` */
function getCommentsWithoutUnnecessaryInfo({ comments }) {
    let minimalComments = [];
    comments.map((comment) => {
        let minimalComment = { key: comment.key, text: comment.text };
        if (comment.replyTo) {
            minimalComment.replyTo = comment.replyTo;
        }
        minimalComments.push(minimalComment);
    });
    return minimalComments;
}

// TODO: Consider removing this if we are sure we always want to send the comments as a JSON string instead of merging only the text fields
function mergeTextOfAllComments({ datastore, comment }) {
    const comments = datastore.getCollection("comment", { sortBy: "time", reverse: true });

    // Merge all comments into a string. If the current comment is an edit, filter out the previous version of it.
    let mergedComments = comments
        .filter((c) => c.key !== comment?.key)
        .map((c) => c.text)
        .join("\n\n");

    // Add the current version of this comment. Only necessary if it is an edit.
    if (comment && comment.key !== undefined) {
        mergedComments = mergedComments === "" ? comment.text : comment.text + "\n\n" + mergedComments;
    }
    return mergedComments;
}

async function generateThoughtProvokingPromptFromTopicAsync({ datastore }) {
    const topic = datastore.getGlobalProperty("name");

    const thoughtProvokingPromptResult = await datastore.callServerAsync(
        'conversationhelper', 'generateThoughtProvokingPromptFromTopic', {topic});

    await datastore.callServerAsync('conversationhelper', 'setLastGuidanceUpdateTime', {});
    
    logEventAsync(datastore, "conversationhelper-update-guidance", {
        generatedFrom: "topic",
        suggestedPerspective: thoughtProvokingPromptResult.suggestedPerspective,
        reminder: thoughtProvokingPromptResult.reminder,
        triggeredBy: "initilization",
        // There are no different personalities for generating guidance based on the topic alone.
        personalityUsed: "default"
    });

}

async function checkForProblemsInConversationAsync({ datastore, comments, triggerType = "automatic" }) {
    const topic = datastore.getGlobalProperty("name");
    const config = datastore.getConfig();

    const problemsResult = await datastore.callServerAsync('conversationhelper', 'checkForProblemsInConversation', {
        comments: comments,
        topic,
        moderatorType: config.moderatorType
    });

    logEventAsync(datastore, "conversationhelper-analyze-conversation", {
        problemsFound: problemsResult.problemsFound.toString() ?? "false",
        explanation: problemsResult.explanation,
        triggeredBy: triggerType === "automatic" ? "automation as configured" : "admin",
        timingOfAnalysis: "every " + config.timingSetting.every + " " + config.timingSetting.type + "(s)",
        scopeOfAnalysis:
            config.analysisScope === "selectTopXPercent"
                ? "most recent x% of comments"
                : config.analysisScope,
        scopeOfAnalysisPercentage:
            config.analysisScope === "selectTopXPercent"
                ? (config.topXPercentFloat * 100) + "%"
                : "not applicable",
        scopeOfAnalysisThreshold:
            config.analysisScope === "selectTopXPercent"
                ? config.minCommentThreshold + " comment(s)"
                : "not applicable",
    });

    return problemsResult;
}

async function generateThoughtProvokingPromptFromConversationAsync({ datastore, comments, problemsExplanation, triggeredBy = "" }) {
    const topic = datastore.getGlobalProperty("name");
    const moderatorType = datastore.getConfig().moderatorType

    const thoughtProvokingPromptResult = await datastore.callServerAsync(
        'conversationhelper', 'generateThoughtProvokingPromptFromConversation', {
            comments: comments,
            analysis: problemsExplanation,
            topic,
            moderatorType
        }
    );

    await datastore.callServerAsync('conversationhelper', 'setLastGuidanceUpdateTime');

    logEventAsync(datastore, "conversationhelper-update-guidance", {
        generatedFrom: "conversation",
        suggestedPerspective: thoughtProvokingPromptResult.suggestedPerspective,
        reminder: thoughtProvokingPromptResult.reminder,
        triggeredBy: triggeredBy,
        personalityUsed: datastore.getConfig().moderatorType
    });
}

// This function is only a temp. solution until a scheduling system is in place
function summarizeDiscussion({ datastore }) {
    const config = datastore.getConfig()
    if (config.summaryEnabled) {
        const comments = datastore.getCollection("comment", { sortBy: "time", reverse: true });
        const hasComments = comments && comments.length > 0;

        if (!hasComments) { return; }

        const minimalComments = getCommentsWithoutUnnecessaryInfo({ comments: comments });
        // console.log("Generating conversation summary from:", minimalComments);
        generateSummaryFromConversationAsync({ datastore, comments: minimalComments, triggeredBy: "new comment" });
    }
}

async function generateSummaryFromConversationAsync({ datastore, comments, triggeredBy = "" }) {
    const topic = datastore.getGlobalProperty("name");
    const commentsJsonString = JSON.stringify(comments);

    const summaryResult = await datastore.callServerAsync(
        'conversationhelper', 'generateSummaryFromConversation', {
            topic: topic,
            comments: commentsJsonString
        },
    );

    logEventAsync(datastore, "conversationhelper-generate-summary", {
        summary: summaryResult.summary,
        // There is only one version of the summary prompt
        personalityUsed: "default",
        triggeredBy
    });
}

function ConversationHelperAdminButton() {
    const isAdmin = useIsAdmin()
    const datastore = useDatastore()
    const openDashboard = () => {
        datastore.pushSubscreen('conversationhelperadmin');
    }

    if (!isAdmin) return null;

    return (
        <View style={{ paddingBottom: 24, flexDirection: "row"}}>
            <Edit color={colorTextGrey} />
            <PadBox right={4}/>
            <TextButton type={"small"} strong label={"Open Admin Dashboard"} color={colorTextGrey} onPress={openDashboard} />
        </View>
    );
}

export function ConversationHelperAdminScreen() {
    const questionName = useGlobalProperty("name");
    const structureKey = useStructureKey();
    const instanceKey = useInstanceKey();
    const isAdmin = useIsAdmin();

    if (!isAdmin) {
        return <UtilityText text={"Access denied"} />
    }

    return (
        <PadBox horiz={24}>
            <Narrow>
                <WindowTitle label="Conversation Helper Dashboard" />
                <Heading type="large" weight="medium" label="Conversation Helper Dashboard" />

                <Pad />

                <Heading label={"Related Thread"} />
                <HorizBox center>
                    <TextButton
                        text={questionName}
                        editorial
                        type="small"
                        onPress={() => gotoInstance({ structureKey: structureKey, instanceKey: instanceKey })}
                    />
                    <IconButton
                        icon={ArrowUpRight}
                        onPress={() => gotoInstance({ structureKey: structureKey, instanceKey: instanceKey })}
                    />
                </HorizBox>

                <Pad />
                
                <ConversationHelperAdminOverview/>
            </Narrow>
        </PadBox>
    );
}

function ConversationHelperAdminOverview() {
    const datastore = useDatastore();
    const config = useConfig();
    const language = useLanguage();

    const thoughtProvokingPrompts = useCollection("thought_provoking_prompt", { sortBy: "time", reverse: true });
    const comments = useCollection("comment", { sortBy: "time", reverse: true });
    const allAnalysisResults = useCollection("conversation_analysis_result", { sortBy: "time", reverse: true });

    const lastAnalysisTime = useGlobalProperty("lastConversationAnalysisTime");
    const lastAnalysisTimeFormatted = formatDate(lastAnalysisTime, language);
    const latestThoughtProvokingPrompt = thoughtProvokingPrompts[0];
    const filteredComments = comments.filter((c) => c.time > lastAnalysisTime || c.edited > lastAnalysisTime);

    const [manualAnalysisResult, setManualAnalysisResult] = useState(null);

    const performManualCheckForProblems = async () => {
        let commentsToAnalyze = getCommentsToAnalyzeAsJsonString({ datastore });

        const problemsResult = await checkForProblemsInConversationAsync({
            datastore,
            comments: commentsToAnalyze,
            triggerType: "manual"
        });

        setManualAnalysisResult(problemsResult);

        return problemsResult;
    }

    const performManualPromptGeneration = async () => {
        const problemsResult = await performManualCheckForProblems();

        let commentsToAnalyze = getCommentsToAnalyzeAsJsonString({ datastore });

        generateThoughtProvokingPromptFromConversationAsync({
            datastore,
            mergedComments: commentsToAnalyze,
            problemsExplanation: problemsResult.explanation,
            triggeredBy: "admin"
        });
    }

    return <SpacedArray>
            <Banner color={colorLightBlueBackground}>
                {/* "Guidance" refers to Thought-Provoking Prompt but that's a rather clunky name to have in a UI. */}
                <Heading label={"Current Guidance"} />
                <Pad />
                <UtilityText label="This guidance message is visible to everyone." />
                <Pad />
                <View style={{ borderColor: colorGreyBorder, borderWidth: 1 }}>
                    <ThoughtProvokingPromptSelector />
                </View>
                {latestThoughtProvokingPrompt?.explanation && (
                    <View>
                        <Pad />
                        <Banner color={colorWhite}>
                            <UtilityText weight='medium' label="This message was generated because..." />
                            <Pad />
                            <Paragraph text={latestThoughtProvokingPrompt.explanation} />
                        </Banner>
                    </View>
                )}
                <Pad />
                <CTAButton type={"secondary"} label="Generate new guidance" onPress={performManualPromptGeneration} />
            </Banner>

            <Banner color={colorLightBlueBackground}>
                <Heading label={"Conversation Analysis"} />
                <Pad />
                {manualAnalysisResult && (
                    <SpacedArray>
                        <HorizBox>
                            <UtilityText label="Result: " weight='medium' />
                            {manualAnalysisResult.problemsFound ? (
                                <UtilityText label="Problems detected!" color={colorRed} weight='medium' />
                            ) : (
                                <UtilityText label="No problems detected." color={colorGreen} weight='medium' />
                            )}
                        </HorizBox>
                        <UtilityText weight='medium' label="Explanation" />
                        <UtilityText text={manualAnalysisResult.explanation} />
                    </SpacedArray>
                )}
                <CTAButton type={"secondary"} label="Analyze conversation" onPress={performManualCheckForProblems} />
            </Banner>

            <Banner color={colorPinkBackground}>
                <Heading label="Conversation Stats" />
                <Pad />
                <SpacedArray pad={10}>
                    {lastAnalysisTime ? (
                        <RichText
                            label="- Last conversation analysis: **{time}**"
                            formatParams={{ time: lastAnalysisTimeFormatted }}
                        />
                    ) : (
                        <RichText label={"- Last conversation analysis: **None**"} />
                    )}
                    <RichText
                        label={"- **{count} check(s)** for problems performed"}
                        formatParams={{ count: allAnalysisResults.length }}
                    />
                    <RichText
                        label={"- **{count} guidance message(s)** generated"}
                        formatParams={{ count: thoughtProvokingPrompts.length }}
                    />
                    <RichText
                        label={"- **{count} new comment(s)** since last conversation analysis"}
                        formatParams={{ count: filteredComments.length }}
                    />
                </SpacedArray>
            </Banner>
        </SpacedArray> 
}