import { useState } from "react";
import { useDatastore } from "../../../util/datastore";
import { colorBlack, colorDisabledText, colorGreyBorder, colorRed, colorWhite } from "../../color";
import { callServerApiAsync } from "../../../system/servercall";
import { StyleSheet, View } from "react-native";
import { CTAButton } from "../../button";
import { Close } from "@carbon/icons-react";
import { AutoSizeTextInput, UtilityText } from "../../text";

const BlacklistTextInputWithChipsStyle = StyleSheet.create({
    inputContainer: {
        flexDirection: "column",
        borderWidth: 1,
        borderRadius: 4,
        backgroundColor: colorWhite,
        borderColor: colorGreyBorder,
    },
    inputContainerFocus: {
        outlineStyle: "solid",
        outlineWidth: 1,
        borderColor: colorBlack,
        outlineColor: colorBlack,
    },
    inputContainerError: {
        borderColor: colorRed,
        outlineColor: colorRed,
    },
    termsContainer: {
        maxHeight: "100px",
        marginTop: 4,
        overflowY: "auto",
        overflowX: "hidden",
    },
    termsContainerInner: {
        flexDirection: "row",
        flexFlow: "wrap",
    },
    textField: {
        backgroundColor: colorWhite,
        paddingHorizontal: 16,
        paddingVertical: 15,
        fontSize: 16,
        lineHeight: 24,
        fontFamily: "IBMPlexSans_400Regular, Arial, Helvetica, sans-serif",
        borderWidth: 0,
        outlineWidth: 0,
    },
    termContainer: {
        paddingTop: "4px", 
        paddingLeft: "4px" 
    }
});

export function BlacklistTextInputWithChips({ terms, setTerms, testID }) {
    const s = BlacklistTextInputWithChipsStyle;
    
    const datastore = useDatastore();

    const [plainText, setPlainText] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [textInputHasFocus, setTextInputHasFocus] = useState(false);

    async function onChange(text) {
        // Transform to lowercase
        let lowercaseText = text.toLowerCase();

        // Replace ";", or tab with newline
        lowercaseText = lowercaseText.replace(/(\t|;)/gm, "\n");

        // Split text into array at newline
        const splitTextArray = lowercaseText.split(/(\n)/gm);

        // Remove unnecessary spaces at the start and at the end of the term
        let cleanedTextArray = [];
        splitTextArray.forEach((splitText) => {
            cleanedTextArray.push(splitText.replace(/^ +| +$/g, ""));
        });

        // Remove empty string entries from array
        cleanedTextArray = cleanedTextArray.filter((v) => v !== "");

        // Remove duplicate entries from array
        let newTermsArray = [...new Set(cleanedTextArray)];

        // newTermsArray uses "\n" as a separating element between terms, so having 2 or more elements in newTermsArray means there is at least 1 new term to add
        if (newTermsArray.length >= 2) {
            newTermsArray = newTermsArray.filter((v) => v !== "\n");

            for (let newTerm of newTermsArray) {
                const isTermInBlacklist = await callServerApiAsync({
                    datastore,
                    component: "blacklist",
                    funcname: "findTermInBlacklist",
                    params: {
                        term: newTerm,
                    },
                });

                // Check if the new term has already been entered in the input field or saved to the blacklist
                if (newTerm.length > 0 && !terms.includes(newTerm) && !isTermInBlacklist) {
                    setTerms((terms) => [...terms, newTerm]);
                    setErrorMessage("");
                    setPlainText("");
                } else {
                    setErrorMessage("This term is already in the list");
                }
            }
        } else {
            setPlainText(text);
            setErrorMessage("");
        }
    }

    function onDelete(text) {
        const remainingTerms = [...terms.filter((term) => term !== text)];
        if (remainingTerms.length === 0) {
            setTerms([]);
        } else {
            setTerms(remainingTerms);
        }
    }

    return (
        <View>
            <View style={[s.inputContainer, textInputHasFocus && s.inputContainerFocus, errorMessage && s.inputContainerError]}>
                    {terms?.length > 0 && <View style={s.termsContainer}>
                        <View style={s.termsContainerInner}>
                            {terms.map((term, index) => (
                                <View key={index} style={s.termContainer}>
                                    <CTAButton text={term} icon={<Close />} type="secondary" onPress={() => onDelete(term)} />
                                </View>
                            ))}
                        </View>
                    </View>}

                    <View style={s.textFieldContainer}>
                        <AutoSizeTextInput
                            value={plainText}
                            onChange={(text) => onChange(text)}
                            emptyHeight={54}
                            autoFocus={false}
                            testID={testID}
                            style={s.textField}
                            placeholder={"Add terms - seperate multiple terms with ;"}
                            placeholderTextColor={colorDisabledText}
                            onFocus={() => setTextInputHasFocus(true)}
                            onBlur={() => setTextInputHasFocus(false)}
                            multiline={true}
                            maxHeight={54}
                        />
                    </View>
                </View>
            <View style={{ display: "flex", flexDirection: "column" }}>
                {errorMessage !== "" ? <UtilityText label={errorMessage} color={colorRed} /> : null}
            </View>
        </View>
    );
}