import { UserMultiple } from "@carbon/icons-react";
import { HorizBox, Pad, PadBox } from "component/basics.js"
import { formatToDateRangeString } from "component/cbcrc/date";
import { TopicDate } from "component/cbcrc/header";
import { spacings } from "component/constants";
import { UtilityText } from "component/text.js"
import { useCollection, useGlobalProperty } from "util/datastore.js"
import { useConfig } from "util/features";

export const TopicParticipantsFeature = {
    key: 'topicparticipants',
    name: 'Topic Participants',
    dev: true,
    config: {
        topicHeaderBottomWidgets: [TopicOfTheMomentBottomWidget]
    },
}

function TopicOfTheMomentBottomWidget() {
    const startAt = useGlobalProperty('startAt');
    const endAt = useGlobalProperty('endAt');
    const live = endAt > new Date().toISOString();

    return live ?
        <TopicParticipantsWidget />
        :
        <PadBox vert={spacings.xl} bottom={spacings.sm}>
            <TopicDate date={formatToDateRangeString({ start: new Date(startAt), end: new Date(endAt), locale: 'fr-CA' })} />
        </PadBox>
}

function TopicParticipantsWidget() {
    const personas = useCollection('persona');
    const { headerFontColor } = useConfig();
    const count = personas?.length;

    return <PadBox top={24}>
        <HorizBox>
            <UserMultiple color={headerFontColor} />
            <Pad size={spacings.xs} />
            <UtilityText color={headerFontColor} label='{count} {noun}'
                formatParams={{ count, singular: 'participant', plural: 'participants' }}
            />
        </HorizBox>
    </PadBox>
}