import { ConversationScreen, HorizBox, Pad, Separator } from "component/basics.js"
import { CTAButton, TextButton } from "component/button.js"
import { Heading, UtilityText } from "component/text.js"
import { useCollection, useDatastore, useGlobalProperty, useInstanceKey, useModulePublicData, useStructureKey } from "util/datastore.js"
import { gotoInstanceScreen, makeUrl } from "util/navigate.js"
import { useEffect, useRef, useState } from "react";
import { Toggle } from "../../../component/form"
import { View } from "react-native-web"

// TODO: Make this a generic function for everyone
// There was no possibility to change the url of the teaser Iframe
export function gotoUrlTeaser({ structureKey, instanceKey, screenKey, targetTeaser = null }) {
    const url = makeUrl([structureKey, instanceKey, screenKey]);
    window.parent.postMessage({ type: 'psi-teaser-gotoUrl', url, targetTeaser }, '*');
}

export const VideoVotingFeature = {
    name: 'Video Voting',
    key: 'videovotingArticle',
    subscreens: {
        manageVideoVoting: VideoVotingAssignmentScreen,
    },
    config: {
        additionalAdminTeaserWidgets: [ManageVideoVotingButton],
        teaserTopWidgets: [RedirectToVideoVotingWidget]
    },
    defaultConfig: {
        showVotingWithinTextTeaser: false,

    }
}

function ManageVideoVotingButton() {
    const instanceKey = useInstanceKey();
    const structureKey = useStructureKey();
    const datastore = useDatastore();

    return <CTAButton type="secondary" label={"Manage Voting (Admin)"}
        onPress={() => datastore.gotoInstanceScreen({ structureKey, instanceKey, screenKey: 'manageVideoVoting', params: {} })}
    />
}

function VideoVotingAssignmentScreen() {
    const votings = useModulePublicData("videovoting");
    return <ConversationScreen pad>
        <Heading label={"Select a voting for this article"} type="large" />
        <Pad />
        <CTAButton label={"Create a new voting"} onPress={() => { gotoInstanceScreen({ structureKey: "admin", instanceKey: "one", screenKey: "createVoting" }) }} />
        <Pad />
        {Object.values(votings ?? {}).map((voting) => {
            return <VideoVotingItem key={voting.key} voting={voting} />
        })}
    </ConversationScreen>
}

function VideoVotingItem({ voting }) {
    const datastore = useDatastore();
    const assignedVotings = useCollection("videovoting");
    const moduleVoting = useModulePublicData("videovoting");
    const assigned = assignedVotings.filter(v => !v.deleted).map(v => v.key).includes(voting.key);
    const assignmentEnabled = assignedVotings.filter(v => !v.deleted).length <= 0 || assigned

    const toggle = async () => {
        if (assigned) {
            const result = await datastore.callServerAsync('videovoting', 'unassign', { key: voting.key });
        } else {
            const result = await datastore.callServerAsync('videovoting', 'assign', { key: voting.key });
        }
    }

    return <HorizBox spread center>
        <TextButton key={voting.key} text={moduleVoting?.[voting.key]?.template?.name} onPress={() => { gotoInstanceScreen({ structureKey: "videovoting", instanceKey: voting.key, screenKey: "edit", params: { key: voting.key } }) }} />
        <View style={{ visibility: assignmentEnabled ? "visible" : "hidden" }}><Toggle value={assigned} spread onChange={toggle} /></View>
    </HorizBox>
}

function RedirectToVideoVotingWidget() {
    const assignedVotings = useCollection("videovoting");
    const filtered = assignedVotings.filter(v => !v.deleted)

    useEffect(() => {
        if (filtered && filtered.length > 0) {
            gotoUrlTeaser({ structureKey: "videovoting", instanceKey: filtered[0].key, screenKey: "overlayScreen", targetTeaser: "overlay-screen" })
        }
    }, [])

    return null
}


// Determines if the video voting should be shown on top of the video or below it in the teaser Iframe
export const VideoVotingVideoOverlayFeature = {
    name: 'Show voting UI below video',
    key: 'videovotingArticleTextIntegration',
    parentFeature: 'videovotingArticle',
    config: {},
    defaultConfig: {
        showVotingWithinTextTeaser: true
    }
}