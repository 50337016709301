import { colorWhite } from "component/color.js"
import { getFontSize } from "platform-specific/font";
import { StyleSheet } from "react-native";
import { Image, View } from "react-native-web";

export function Badge({imageUrl=null}) {
    const s = BadgeStyle;
    if (imageUrl) return <View style={[
                s.button, 
            ]} >
        <Image style={{ width: '100%', height: '100%', borderRadius: '50%' }} source={{uri: imageUrl}} />
    </View>
    return null
}
const BadgeStyle =  StyleSheet.create({
    button: {
        borderWidth: 1,
        borderRadius: '50%',
        fontSize: getFontSize(10),
        position: 'absolute',
        bottom: -1,
        right: -1,
        width: '35%',
        height: '35%',
        borderColor: colorWhite
    },
})