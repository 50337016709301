import { ConversationScreen, HeaderBox, HorizBox, Pad, PadBox, WindowTitle } from "component/basics.js"
import { colorTealBackground, colorTextGrey, colorWhite } from "component/color.js"
import { Heading, TextField, UtilityText } from "component/text.js"
import { useDatastore, useGlobalProperty } from "util/datastore.js"
import { useConfig } from "util/features.js"
import React, { useState } from "react";
import { spacings } from "component/constants"
import { useIsAdmin } from "component/admin"
import { FormField } from "component/form"
import { CTAButton, TextButton } from "component/button"


export const TopicStructure = {
    key: 'topic',
    name: 'Topic',
    screen: TopicScreen,
    teaser: TopicTeaser,
    hasFocusTrap: true,
    subscreens: {
        topicNameAdmin: () => <TopicNameAdminScreen />,
    },
    defaultConfig: {
        topicDescriptionWidget: TopicDescription,
        topicContentWidgets: [],
        topicTopWidgets: [],
        topicBottomWidgets: [],
        topicHeaderLeftWidgets: [],
        topicHeaderRightWidgets: [],
        topicHeaderBottomWidgets: [],
        pageTopLeftWidgets: [],
        pageTopRightWidgets: [],
        teaser: null,
        topBarHelpBubbles: [],
        bylineTags: [],
        profilePhotoLayers: [],
        composerTopWidgets: [],
        commentMiddleWidgets: [],
        commentAboveWidgets: [],
        commentBodyStylers: [],
        commentPostTriggers: [],
        replyAboveWidgets: [],
        replyBoosters: [],
        commentBoosters: [],
        headerBackgroundColor: colorTealBackground,
        headerFontColor: colorWhite,
        commentsTopLeftWidgets: [],
        commentsTopRightWidgets: [],
        composerPreview: null,
        readOnly: false,
        commentDeleteTriggers: [],
        sidebarCloseModal: null,
        sidebarShouldShowCloseModal: null
    }   
}

function TopicDescription() {
    return <UtilityText color={colorWhite} label='Discussion topic' />
}

export function TopicTeaser() {
    const { teaser } = useConfig();
    if (teaser) {
        return React.createElement(teaser);
    } else {
        return <UtilityText label='No teaser defined' />
    }
}

function TopicHeader() {
    const datastore = useDatastore();
    const name = useGlobalProperty('name');
    const isAdmin = useIsAdmin();
    const { topicHeaderLeftWidgets, topicHeaderRightWidgets,
        topicHeaderBottomWidgets, topicDescriptionWidget, headerBackgroundColor, headerFontColor } = useConfig();
    return <HeaderBox backgroundColor={headerBackgroundColor}>
        <WindowTitle text={name} />
        {(topicHeaderLeftWidgets?.length || topicHeaderRightWidgets?.length) ? <Pad size={12} /> : null}
        <HorizBox spread center>
            <HorizBox center>
                {topicHeaderLeftWidgets.map((Widget, index) => <Widget key={index} />)}
            </HorizBox>
            <HorizBox center>
                {topicHeaderRightWidgets.map((Widget, index) => <Widget key={index} />)}
            </HorizBox>
        </HorizBox>
        {(topicHeaderLeftWidgets?.length || topicHeaderRightWidgets?.length) ? <Pad size={spacings.xl} /> : <Pad size={12} />}
        {React.createElement(topicDescriptionWidget)}
        <Pad size={12} />
        <Heading color={headerFontColor} type="large" weight="medium" text={name} />
        {isAdmin && <TextButton type={"tiny"} label={"Edit topic name (admin)"} color={colorTextGrey} onPress={() => datastore.pushSubscreen('topicNameAdmin')} />}
        {topicHeaderBottomWidgets.map((Widget, index) => <Widget key={index} />)}
        <Pad size={12} />
    </HeaderBox>
}


function TopicScreen() {
    const { topicTopWidgets, topicContentWidgets, topicBottomWidgets } = useConfig();
    return <ConversationScreen>
        <TopicHeader />
        {topicTopWidgets.map((Widget, index) => <Widget key={index} />)}
        {topicContentWidgets.map((Widget, index) => <Widget key={index} />)}
        {topicBottomWidgets.map((Widget, index) => <Widget key={index} />)}
        <Pad size={80} />
    </ConversationScreen>
}

function TopicNameAdminScreen() {
    const datastore = useDatastore();
    const initialName = useGlobalProperty("name");
    const initialPreview = useGlobalProperty("preview");
    const [name, setName] = useState(initialName);
    const isAdmin = useIsAdmin();

    const updateTopicName = () => {
        datastore.setGlobalProperty('name', name);
        datastore.setGlobalProperty('preview', {...initialPreview, name});
        datastore.goBack();
    }

    if (!isAdmin) {
        return <UtilityText text={"Access denied"} />
    }

    return <ConversationScreen>
        <PadBox horiz={spacings.lg} vert={spacings.xl2}>
            <Heading type="large" weight="medium" label={"Edit topic name (admin)"} />
            <PadBox vert={spacings.lg}>
                <FormField label='Topic name'>
                    <TextField
                        label='Topic name'
                        placeholder='Topic name'
                        value={name}
                        onChange={setName}
                    />
                </FormField>
            </PadBox>
            <CTAButton label='Update' onPress={updateTopicName} />
        </PadBox>
    </ConversationScreen>
}
