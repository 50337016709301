import { Pad } from "component/basics"
import { PollPicker, PollSurvey, PollResult, PollSurveyThenResults } from "component/poll"
import { UtilityText } from "component/text"
import { useState } from "react"
import { View } from "react-native"
import { CLICK, INPUT } from "system/demo"

export const PollDemoFeature = {
    key: 'demo_poll',
    name: 'Poll Demo',
    config: {
        componentSections: [
            {label: 'Core Design System', key: 'core', pages: [
                {label: 'Poll', key:'polls', storySets: pollStorySets, 
                    designUrl: 'https://www.figma.com/design/MX0AcO8d0ZlCBs4e9vkl5f/PSI-Design-System?node-id=4831-5007&t=t6FrFrD5ap18phaP-0'}
            ]}
        ]
    }
}

function PollPickerDemo() {
    const [value, setValue] = useState(null)
    return <PollPicker value={value} 
        optionLabels={['Strongly no', 'No with reservations', "It's complicated", "Yes with reservations", "Strongly yes"]} 
        optionColors={['red', 'orange', 'yellow', 'green', 'blue']} 
        placeholder='!Are cats cute?' onChangeValue={setValue} 
    />
}

function PollSurveyDemo({
    optionTexts, showButton = true, 
    showOtherOption = false, otherOptionLabel = 'Other', otherOptionPlaceholder = 'Write in a topic...',
}) {
    const [myVote, setMyVote] = useState(null);
    const [comment, setComment] = useState(null);


    function onVote(option, comment) {
        setMyVote(option)
        setComment(comment)
    }

    return <View>
        <PollSurvey 
            showButton={showButton} myVote={myVote} 
            optionTexts={optionTexts} onVote={onVote} 
            showOtherOption={showOtherOption} otherOptionLabel={otherOptionLabel} otherOptionPlaceholder={otherOptionPlaceholder} 
        />
        <Pad />
        <UtilityText text={myVote ? 'You voted for ' + myVote : 'You have not voted yet'} />
        {comment && <UtilityText text={comment} />}
     </View>
}

function PollSurveyThenResultsDemo({question, optionLabels, optionTexts, votes, showOtherOption, showButton, otherOptionText}) {
    const [myVote, setMyVote] = useState(null);
    const [myComment, setMyComment] = useState(null);

    function onVote(option, comment) {
        setMyVote(option)
        setMyComment(comment)
    }

    return <View>
        <PollSurveyThenResults question={question} optionLabels={optionLabels} 
            optionTexts={optionTexts} votes={votes} myVote={myVote} myComment={myComment} 
            onVote={onVote} showOtherOption={showOtherOption} otherOptionText={otherOptionText} 
            otherOptionPlaceholder='!Enter your choice'
            showButton={showButton}
        />
        <Pad />
        <UtilityText text={myVote ? 'You voted for ' + myVote : 'You have not voted yet'} />
        {myComment && <UtilityText text={myComment} />}
    </View>
}   

const commentSliderLabels = ['Strongly no', 'No with reservations', "It's complicated", "Yes with reservations", "Strongly yes"]  

function pollStorySets() {return [
    {
        label: 'PollResult with Labels',
        content: <PollResult optionLabels={commentSliderLabels} votes={['Strongly no', 'No with reservations', 'No with reservations', "It's complicated", "Yes with reservations", "Strongly yes"]} myVote='Strongly no' />
    },
    {
        label: 'PollResult with Option Texts',
        content: <PollResult optionTexts={['Cat', 'Dog', 'Komodo Dragon']} votes={['Cat', 'Cat', 'Cat']} myVote='Cat' />
    },
    { 
        label: 'PollSurveyThenResults',
        content: <PollSurveyThenResultsDemo 
            question='What is your favorite animal?' 
            optionTexts={['Cat', 'Dog', 'Komodo Dragon']} 
            showButton={false}
            votes={['Cat', 'Cat', 'Dog', 'Dog', 'Dog', 'Komodo Dragon']} />,
        stories: [
            {
                label: 'Cat', actions: [
                    CLICK('Cat'),
                ]
            },
            {
                label: 'Cat then Dog', actions: [
                    CLICK('Cat'),
                    CLICK('Change my vote'),
                    CLICK('Dog'),
                ]
            }
        ]
    },
    { 
        label: 'PollSurveyThenResults with Other Option and Button',
        content: <PollSurveyThenResultsDemo 
            question='What is your favorite animal?' 
            optionTexts={['Cat', 'Dog', 'Komodo Dragon']} 
            showOtherOption showButton otherOptionText='Other'
            votes={['Cat', 'Cat', 'Dog', 'Dog', 'Dog', 'Komodo Dragon']} 
        />,
        stories: [
            {
                label: 'Cat', actions: [
                    CLICK('Cat'),
                    CLICK('Submit my vote')
                ]
            },
            {
                label: 'Cat then Dog', actions: [
                    CLICK('Cat'),
                    CLICK('Submit my vote'),
                    CLICK('Change my vote'),
                    CLICK('Dog'),
                    CLICK('Submit my vote')
                ]
            },
            {
                label: 'Other', actions: [
                    CLICK('Other'),
                    INPUT('other-option', 'Badger'),
                    CLICK('Submit my vote')
                ]
            }
        ]
    },
    {
        label: 'PollPicker',
        content: <PollPickerDemo />,
        stories: [
            {
                label: 'Strongly no', actions: [
                    CLICK('Strongly no')
                ]
            },
            {
                label: 'No then yes', actions: [
                    CLICK('No with reservations'),
                    CLICK('Change'),
                    CLICK('Strongly yes')
                ]
            }
        ]
    },
    {
        label: 'PollSurvey with Button',
        content: <PollSurveyDemo optionTexts={['Cat', 'Dog', 'Komodo Dragon']} />,
        stories: [
            {
                label: 'Cat', actions: [
                    CLICK('Cat'),
                    CLICK('Submit my vote')
                ]
            },
            {
                label: 'Cat then Dog', actions: [
                    CLICK('Cat'),
                    CLICK('Dog'),
                    CLICK('Submit my vote')
                ]
            }
        ]
    },
    {
        label: 'PollSurvey without Button',
        content: <PollSurveyDemo optionTexts={['Cat', 'Dog', 'Komodo Dragon']} showButton={false} />,
        stories: [
            {
                label: 'Cat', actions: [
                    CLICK('Cat')
                ]
            },
            {
                label: 'Cat then Dog', actions: [
                    CLICK('Cat'),
                    CLICK('Dog')
                ]
            }
        ]
    },
    {
        label: 'PollSurvey with Other Option',
        content: <PollSurveyDemo showOtherOption optionTexts={['Cat', 'Dog', 'Komodo Dragon']} />
    },
]}