import { logEventAsync } from "util/eventlog.js";

let timersInitialized = false;
let timers = [];

export function initializeGlobalSidebarTimers(datastore) {
    if (timersInitialized) {
        return;
    }
    timersInitialized = true;

    timers = [
        setTimeout(() => logEventAsync(datastore, "sidebar-open-1-min"), 60000),  
        setTimeout(() => logEventAsync(datastore, "sidebar-open-5-min"), 300000),
        setTimeout(() => logEventAsync(datastore, "sidebar-open-10-min"), 600000),        
    ];
}

export function clearGlobalSidebarTimers(datastore) {
    logEventAsync(datastore, "sidebar-close");
    timers.forEach(clearTimeout);
    timers = [];
    timersInitialized = false;
}


