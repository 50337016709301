import { Banner } from "component/banner.js"
import { Pad, PadBox } from "component/basics.js"
import { CTAButton, SubtleButton, TextButton } from "component/button.js"
import { colorLightBlueBackground, colorPink, colorBlack, colorBlackHover, colorGreyBorder, colorTextGrey } from "component/color.js"
import { RichText } from "component/richtext.js"
import { BulletText, Heading, TextField, UtilityText } from "component/text.js"
import { useDatastore, useSessionData, useModulePublicData } from "util/datastore.js"
import { useState } from "react";
import { Modal } from "component/modal.js"
import { goBack } from "util/navigate.js"
import { logEventAsync, useLogEvent } from "util/eventlog.js"
import { GUIDELINES, PARENT_GUIDELINES, getParentRuleByChildText, getRuleById, GuidelinesModal } from "../question/CommunityGuidelinesFeature";
import { View, StyleSheet, Text } from "react-native";
import { Pin, Send } from "@carbon/icons-react";
import { formatDate } from "component/date.js"
import React from "react";
import { getWillHelpBeSeenAsync, HelpBubble } from "component/help.js"
import { sleepMillisAsync } from "util/util.js"

export const PreModeratorFeature = {
    name: 'Pre Moderation',
    key: 'premoderation',
    config: {
        commentPostBlockers: [isPostButtonDisabledAsync],
        commentEditBottomWidgets: [ModerationReport],
        commentPostCheckers: [checkPostAsync],
        topBarHelpBubbles: [ModerationInProgessHelpBubble]
    },
    defaultConfig: {
        commentModerators: [],
        useOnlyGptModeration: false
    }
}

export const ModerationHelpFeature = {
    name: 'Moderation Toast',
    key: 'moderationhelp',
    dev: true,
    config: {
        topBarHelpBubbles: [ModerationInProgessHelpBubble],
        commentPostCheckers: [waitForHelpBubbleAsync],
        commentPostTriggers: [clearHelpBubbleAsync]
    }
}

export const ModerateWithGPTFeature = {
    name: 'GPT',
    key: 'aimoderation',
    config: {
        commentModerators: [moderateWithGPT]
    }
}

export const ModerateWithJigsawFeature = {
    name: 'Jigsaw',
    key: 'jigsawmoderation',
    config: {
        commentModerators: [moderateWithJigsaw]
    }
}

async function moderateWithGPT({datastore, comment, questionText}) {
    const moderationResult = await datastore.callServerAsync(
        'moderation', 'checkCommentWithGpt', {commentText: comment.text, questionText});
    if (moderationResult.violates) {
        const violations = moderationResult.rulesBroken?.map(getRuleById) ?? [];
        
        return {
            violates: moderationResult.violates, violations, source: 'ai',
            metadata: {rulesBroken: JSON.stringify(moderationResult.rulesBroken ?? [])}
        };
    } else {
        return {violates: false}
    }
}

async function moderateWithJigsaw({datastore, comment}) {
    const moderationResult = await datastore.callServerAsync(
        'moderation', 'checkCommentWithJigsaw', {text: comment.text});
    if (moderationResult.violates) {
        return {
            violates: true, source: 'jigsaw', 
            violations: [GUIDELINES.NO_UNCIVIL],
            metadata: {toxicity: moderationResult.toxicity}
        };
    } else {
        return {violates: false}
    }
}

export const ModerateRepliesStrictlyFeature = {
    name: 'Strict Reply Moderation',
    key: 'strictreplymoderation',
    config: {
        commentModerators: [moderateRepliesStrictly]
    }
}


async function moderateRepliesStrictly({datastore, comment}) {
    if (!comment.replyTo) {
        return {violates: false}
    }
    const parentComment = await datastore.getObject('comment', comment.replyTo);
    const moderationResult = await datastore.callServerAsync('moderation', 'moderateReplyStrictly', {
        parentText: parentComment.text, text: comment.text, 
        commentKey: comment.key || null, inReview: comment.inReview
    });
    if (!moderationResult.allow) {
        return {violates: true, source: 'ai-reply-moderaton',
            violations: [GUIDELINES.BE_CONSTRUCTIVE]
        }
    } else {
        return {violates: false}
    }
}


async function checkPostAsync({datastore, comment, questionText}) {
    const {commentModerators} = datastore.getConfig();

    if (!comment.text?.trim()) {
        return {allow: true, commentProps: {violates: false, inReview: false}};
    }

    var violation = null;
    var violations = [];

    for (const moderator of (commentModerators || [])) {
        const moderationResult = await moderator({datastore, comment, questionText});
        if (moderationResult.violates) {
            violations = [...violations, ...moderationResult.violations];
            violation = moderationResult;
            logEventAsync(datastore, 'moderation-block', {text: comment.text});
            break;
        }
    }
    violations = [...new Set(violations)];
    const violates = violations.length > 0;
    const violatingTextChanged = comment.violatingText && (comment.text !== comment.violatingText);

    if (comment.inReview && (!violates || violatingTextChanged)) {
        await datastore.callServerAsync('moderation', 'removeCommentFromReviewQueue', {commentKey: comment.key});
        await datastore.updateObject("comment", comment.key, { ...comment, inReview: false });
    }

    return {
        allow: !violates, commentProps: {
            violates, inReview: false, violations,
            violatingText: violates ? comment.text : null,
        }
    };
}

export async function checkPerspectiveAsync({datastore, perspectiveText, questionText, language='English'}) {
    const moderationResult = await datastore.callServerAsync(
        'moderation', 'checkPerspectiveWithGpt', {perspectiveText, questionText, language});
    if (moderationResult.violates) {
        const violations = moderationResult.rulesBroken?.map(getRuleById) ?? [];
        return {violates: true, violations: [...new Set(violations)]}
    } else {
        return {violates: false}
    }
}

function ModerationInProgessHelpBubble() {
    const moderationInProgress = useSessionData('moderationInProgress');
    if (!moderationInProgress) { return null; }
    return <HelpBubble right label='Your response is being reviewed by AI to ensure it follows our Community Guidelines' 
        condition={moderationInProgress} helpKey='moderationInProgress'
    />
}

async function waitForHelpBubbleAsync({datastore, comment}) {
    const helpSeen = await getWillHelpBeSeenAsync({datastore, helpKey: 'moderationInProgress'});
    if (comment.replyTo && !helpSeen) return {allow: true};
    datastore.setSessionData('moderationInProgress', true);
    if (datastore.getSiloKey() != 'puppet-test') {
        await sleepMillisAsync(4000);
    }
    return {allow: true}; 
}

async function clearHelpBubbleAsync({datastore, comment}) {
    datastore.setSessionData('moderationInProgress', false);
}


function isPostButtonDisabledAsync({datastore, comment}) {
    return comment.violates && comment.violatingText == comment.text;
}

export function ModerationReport({comment, onCancel, isPerspectiveModeration=false}) {
    const s = moderationReportStyles;
    const showExtraModerationMessage = useModulePublicData('showExtraModerationMessage');
    const [showModerationModal, setShowModerationModal] = useState(false);
    const [showCommunityGuidelines, setShowCommunityGuidelines] = useState(false);
    const target = isPerspectiveModeration ? comment?.perspectives?.writeIn : comment;
    const parentViolations = Array.from(new Set(target?.violations?.map(getParentRuleByChildText) ?? []));
    const moderatedThing = isPerspectiveModeration ? 'perspective label' : 'response';

    if (!target.violates) {
        return null;
    } else {
        return <View style={s.container}>
            <PadBox horiz={20} vert={16}>
                <Heading label={`⚠️ Check your ${moderatedThing}`} />
                <Pad size={16} />
                <Text style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                    <RichText label={`AI review found that your ${moderatedThing} may be incompatible with the following [Community Guidelines]($onPress): `}
                        callbacks={{onPress: () => setShowCommunityGuidelines(true)}} 
                    />
                    {parentViolations.map((parentViolation, i) => {
                        return <View key={i} style={{ flexDirection: 'row' }}>
                            <UtilityText weight='medium' label={parentViolation} />
                            {i < parentViolations.length - 1 && <UtilityText text=", " />}
                        </View>
                    })}
                </Text>
                
                <Pad size={16} />
                <UtilityText label={`Consider rewording your ${moderatedThing}:`} />
                <Pad size={8} />
                {target.violations?.map((ruleText, i) => 
                    <BulletText key={i} label={ruleText} />
                )}
                <Pad size={20} />
                {!isPerspectiveModeration && (comment.inReview
                    ? <View style={{flexDirection: 'row'}}>
                        {React.createElement(Send, { style: { color: colorBlackHover } })}
                        <PadBox right={4}/>
                        <UtilityText color={colorBlackHover} type='tiny' label='Sent to moderator: {time}' formatParams={{time: formatDate(comment.time)}}/>
                    </View>
                    : <View>
                        <View style={s.line} />
                        <Pad size={20} />
                        <UtilityText weight='medium' label='Did AI get it wrong?' />
                        {showExtraModerationMessage && <View>
                            <Pad size={8} />
                            <UtilityText label="⚠️ Our moderation filter is currently set to be quite strict in order to ensure the highest level of safety! If you feel that your contribution is being unfairly rejected, please contact our moderation team!" />
                        </View> }
                        <Pad size={12} />                         
                        <SubtleButton type='tiny' icon={Send} color={colorBlackHover} label='Send to a moderator for review' onPress={() => setShowModerationModal(true)}/>
                    </View>
                )}
            </PadBox>
            {showModerationModal && <ModerationModal comment={comment} onCancel={onCancel} onClose={() => setShowModerationModal(false)} />}
            {showCommunityGuidelines && <GuidelinesModal onClose={() => setShowCommunityGuidelines(false)}/>}
        </View>
    }
}
const moderationReportStyles = StyleSheet.create({
    container: {
        backgroundColor: colorPink,
    },
    line: {
        height: 1, 
        backgroundColor: colorGreyBorder,
        width: '100%',
    },
});

// TODO: Bring back link to transparency dashboard, once we have cross-partner alignment
// and it works better

export function ModerationModal({comment, onClose, onCancel}) {
    const [inProgress, setInProgress] = useState(false);
    const [sent, setSent] = useState(false);
    const [appealText, setAppealText] = useState('');
    const datastore = useDatastore();
    const siloKey = datastore.getSiloKey();
    useLogEvent('moderation-modal', {text: comment.text});
    async function onReport() {
        setInProgress(true);
        const key = await datastore.callServerAsync('moderation', 'sendToReview', {
            type: 'comment', setInReview: true, source: 'premoderation',
            text: comment.text, key: comment.key, from: comment.from,
            object: comment,
            violations: JSON.stringify(comment.violations), appealText
        });
        
        // Run derived view triggers as comment gets updated serverside
        if (key) {
            await datastore.callServerAsync('derivedviews', 'runTriggers', {
                type: 'comment', key
            });
        }

        setInProgress(false);
        setSent(true);
        logEventAsync(datastore, 'moderation-appeal', {text: appealText});
    }

    function onDone() {
        onClose();
        if (onCancel) {
            onCancel();
        } else {
            goBack();
        }
    }

    const dashboardUrl = 'https://np-psi-dev.web.app/' + siloKey + '/moderationdash/dash';

    if (sent) {
        return <Modal onClose={onDone}>
            <PadBox horiz={20} vert={20}>
                <Heading type="large" weight="medium" label='Thank you!'/>
                <Pad size={8} />
                <UtilityText type='large' color={colorTextGrey} label='Your response has been sent to a moderator for review.' />
                <Pad size={32} />
                    <Banner color={colorLightBlueBackground}>
                        <Heading label='📫 What happens next'/>
                        <Pad size={16} />
                        <UtilityText label='You will receive an email notification when your post is approved or rejected by moderators.'/>
                    </Banner>
                <Pad />
            </PadBox>  
        </Modal>
    } else {
        return <Modal onClose={onClose} buttonRow={
            <CTAButton disabled={inProgress || !appealText} wide type='primary' label={inProgress ? 'Sending...' : 'Send to moderator'} onPress={onReport}/>
        }>
            <PadBox horiz={20} vert={20}>
                <Heading type="large" weight="medium" label='Send to moderator'/>
                <Pad />
                <Banner color={colorLightBlueBackground}>
                    <Heading label='✨ We use AI for moderation'/>
                    <Pad size={32} />
                    <RichText label='AI helps us moderate comments, but sometimes it can make a mistake. If AI is preventing you from sharing a respectful opinion, send your comment to a moderator for review.'/>
                </Banner>
                <Pad size={32} />
                <RichText label="**Share details** (required)" type="small" color={colorBlack}/>
                <Pad size={12} />
                <TextField testID='appeal' big value={appealText} onChange={setAppealText} placeholder='Message to Moderator...'/>
                <Pad size={12} />
                <UtilityText type='small' label='Provide any details to help us determine if your post follows our Community Guidelines.' color={colorTextGrey} />
            </PadBox>  
        </Modal>
    }
}
