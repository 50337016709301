import { HorizBox, Pad } from "component/basics.js"
import { View } from "react-native";
import { useConfig } from "../util/features";

export function TeaserScreen({children, color='transparent'}) {
    function onLayout(e) {
        const {height} = e.nativeEvent.layout;
        window.parent.postMessage({type: 'psi-teaser-height', height}, '*');
    }
    const hasEmulateWarning = window.location.hostname == 'localhost';
    const { teaserTopWidgets } = useConfig();
    
    return <View onLayout={onLayout} style={{backgroundColor: color, borderRadius: 8, maxWidth: 797, width: '100%'}}>
        {teaserTopWidgets && <HorizBox>
            {teaserTopWidgets?.map((Widget,i) => <Widget key={i}/>)}
        </HorizBox>}
        {children}
        {hasEmulateWarning && <Pad size={20} />}
    </View>
}

