import { requestAppNavigateToExternalUrl } from "util/webview";
import { getIsInPsiNativeAppWebview } from "./url";

export function WebLinkBase({url, newTab, style, setHover=()=>{}, children}) {

    const onClick = () => {
        if(getIsInPsiNativeAppWebview()) {
            requestAppNavigateToExternalUrl(url);
        }
    }

    const target = newTab ? '_blank' : '_parent';
    return <a href={url} target={target} style={style} rel='noreferrer'
        onClick={onClick}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
    >
        {children}
    </a>;
}
