import React, { useEffect, useState } from "react";
import { useDatastore, useModulePublicData, useServerCallResult } from "../../../util/datastore";
import { ScrollView, View } from "react-native-web";
import { AutoSizeTextInput, Heading, TextField, UtilityText } from "../../text";
import { BreadCrumb, CTAButton, DropDownSelector, Popup, Tag } from "../../button";
import { Close, Information, OverflowMenuHorizontal, Search } from "@carbon/icons-react";
import { colorBlack, colorDisabledText, colorGreyBorder, colorPurpleBackground, colorRed, colorWhite } from "../../color";
import { Checkbox } from "../../form";
import { StyleSheet } from "react-native";
import { HorizBox, Pad, Separator } from "../../basics";
import { BlacklistAddTermModal, BlacklistDeleteModal, BlacklistEditModal, BlacklistHelpModal, BlacklistMultiSelectionDialog, BlacklistItemEditDeleteMenu } from "./blacklistdialogs";
import { generateAlternativeSpellings } from "./blacklistutil";
import { Banner } from "../../banner";
import { callServerApiAsync } from "../../../system/servercall";
import { BlacklistTextInputWithChips } from "./blacklistTextInput";
import { useHasCapability } from "../../admin";

const BlacklistStyle = StyleSheet.create({
    blacklist: {
        width: "100%",
        paddingTop: 80,
        paddingBottom: 80,
        display: "flex",
        overflow: "visible",
        flexShrink: 0,
        backgroundColor: "#f7f7f7",
    },
    footerDialogContainer: {
        position: "absolute",
        width: 490,
        marginLeft: "auto",
        marginRight: "auto",
        left: 0,
        right: 0,
        bottom: "15%",
    }
});

function useBlacklist() {
    const refreshTimer = useModulePublicData("blacklist", ["lastUpdate"]);
    const blacklist = useServerCallResult("blacklist", "getBlacklist", { time: refreshTimer });

    if (blacklist && Object.keys(blacklist).length > 0) {
        return Object.values(blacklist).sort((a, b) => a.term.localeCompare(b.term));
    } else {
        return [];
    }
}

export function Blacklist() {
    const s = BlacklistStyle;

    const blacklistEntries = useBlacklist();
    const [selectedTerms, setSelectedTerms] = useState([]);
    const [shouldShowSelectionDialog, setShouldShowSelectionDialog] = useState(false);

    function deselectTerms({ termsToDeselect }) {
        let updatedSelectedTerms = selectedTerms;
        termsToDeselect.forEach((termToDeselect) => {
            updatedSelectedTerms = updatedSelectedTerms.filter((term) => term !== termToDeselect);
        });
        setSelectedTerms(updatedSelectedTerms);
    }

    useEffect(() => {
        setShouldShowSelectionDialog(selectedTerms.length > 0);
    }, [selectedTerms]);

    // TODO: How can the RestrictedScreen be used here (It is not full screen here)
    const viewAccess = useHasCapability("moderation/view-blacklist")
    const editAccess = useHasCapability("moderation/edit-blacklist");
    
    if (!viewAccess) {
        return <Banner><UtilityText label='You do not have access to this feature'/></Banner>
    }

    return (
        <View style={s.blacklist}>
            <BlacklistHeader />
            {editAccess && <>
                <Pad size={24} />
                <BlacklistAddTermField />
            </>}
            <Pad size={60} />
            <BlacklistSearchField />
            <Pad size={24} />
            <BlacklistItemList
                blacklistEntries={blacklistEntries}
                selectedTerms={selectedTerms}
                setSelectedTerms={setSelectedTerms}
            />
            {shouldShowSelectionDialog === true ? (
                <View style={s.footerDialogContainer}>
                    <BlacklistMultiSelectionDialog
                        selectedTerms={shouldShowSelectionDialog ? selectedTerms : []}
                        deselectTerms={({ termsToDeselect }) => deselectTerms({ termsToDeselect })}
                    />
                </View>
            ) : null}
        </View>
    );
}

function BlacklistHeader() {
    const [shouldShowInfoModal, setShouldShowInfoModal] = useState(false);

    return <View style={{ gap: 16 }}>
        <Heading label={"Blacklist"} type="large" />
        <HorizBox center>
            <UtilityText
                label={"Manage terms that are contested and should be reported automatically."}
                type="large"
            />
            <Pad size={5} />
            <BreadCrumb icon={Information} onPress={() => setShouldShowInfoModal(true)} />
            {shouldShowInfoModal === true && <BlacklistHelpModal onClose={() => setShouldShowInfoModal(false)} />}
        </HorizBox>
    </View>
}

const BlacklistAddTermFieldStyle = StyleSheet.create({
    baseContainer: {
        width: "100%",
    },
    inputFieldAndButtonContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "100%",
    },
    inputField: {
        width: "calc(100% - 24px - 170px)",
    },
    addTermButton: {
        width: "170px",
    },
});

function BlacklistAddTermField() {
    const [shouldShowAddTermDialog, setShouldShowAddTermDialog] = useState(false);
    const [terms, setTerms] = useState([]);

    const s = BlacklistAddTermFieldStyle;

    return (
        <View style={s.baseContainer}>
            <View style={s.inputFieldAndButtonContainer}>
                <View style={s.inputField}>
                    <BlacklistTextInputWithChips terms={terms} setTerms={setTerms} />
                </View>
                <Pad size={24} />
                <View style={s.addTermButton}>
                    <CTAButton
                        label={"Add Term"}
                        wide
                        onPress={() => terms.length > 0 && setShouldShowAddTermDialog(true)}
                    />
                </View>
            </View>
            {shouldShowAddTermDialog && terms.length > 0 && (
                <BlacklistAddTermModal
                    terms={terms}
                    onClose={() => setShouldShowAddTermDialog(false)}
                    onSave={() => setTerms([])}
                />
            )}
        </View>
    );
}


function BlacklistSearchField() {
    return (
        <View style={{ width: "33%" }}>
            <TextField placeholder={"Search..."} icon={Search} multiline={false} />
        </View>
    );
}

const BlacklistItemListStyle = StyleSheet.create({
    blacklistScrollView: {
        width: "100%",
        height: "calc(100vh - 630px)",
        flexDirection: "column",
        gap: 8,
        overflowX: "hidden",
        flexShrink: 0,
    }
});

function BlacklistItemList({ blacklistEntries, selectedTerms, setSelectedTerms }) {
    const sortingOrderOptions = [
        { key: "ascending", label: "A-Z" },
        { key: "descending", label: "Z-A" },
    ];

    const [sortingOrder, setSortingOrder] = useState("ascending");
    const [sortedBlacklistEntries, setSortedBlacklistEntries] = useState([]);
    const characterReplacements = useServerCallResult("blacklist", "getCharacterReplacements") || {};

    const s = BlacklistItemListStyle;

    useEffect(() => {
        if (sortingOrder === "descending") {
            blacklistEntries.sort((a, b) => b.term.localeCompare(a.term));
        } else if (sortingOrder === "ascending") {
            blacklistEntries.sort((a, b) => a.term.localeCompare(b.term));
        }

        setSortedBlacklistEntries(
            blacklistEntries.map((blacklistEntry) => {
                return { ...blacklistEntry };
            })
        );
    }, [sortingOrder, blacklistEntries]);

    function updateSelection({ blacklistEntry, isSelected, pressedShiftKey }) {
        let updatedSelectedTerms = selectedTerms;

        if (!pressedShiftKey) {
            // Remove term from selection
            if (selectedTerms.includes(blacklistEntry.term) && isSelected === false) {
                updatedSelectedTerms = selectedTerms.filter((term) => term !== blacklistEntry.term);
            }
            // Add term to selection
            else if (!selectedTerms.includes(blacklistEntry.term) && isSelected === true) {
                updatedSelectedTerms = [...selectedTerms, blacklistEntry.term];
            }
        }

        // Select a range of terms by selecting an element, pressing Shift and selecting another element
        else {
            const startIndex = sortedBlacklistEntries.indexOf(blacklistEntry);
            let endIndex;

            // If there are no other selected elements
            if (selectedTerms.length === 0) {
                setSelectedTerms(blacklistEntry.term);
            }
            // If there are other selected elements, determine the range of elements
            else {
                endIndex = sortedBlacklistEntries.findIndex(
                    (blacklistEntry) => blacklistEntry.term === selectedTerms[selectedTerms.length - 1]
                );

                const blacklistEntriesSelectionRange =
                    startIndex < endIndex
                        ? sortedBlacklistEntries.slice(startIndex, endIndex + 1)
                        : sortedBlacklistEntries.slice(endIndex, startIndex + 1);

                // Remove range from selection
                if (selectedTerms.includes(blacklistEntry.term) && isSelected === false) {
                    blacklistEntriesSelectionRange.forEach((blacklistEntry) => {
                        updatedSelectedTerms = updatedSelectedTerms.filter((term) => term !== blacklistEntry.term);
                    });
                }
                // Add range to selection
                else if (!selectedTerms.includes(blacklistEntry.term) && isSelected === true) {
                    blacklistEntriesSelectionRange.forEach((blacklistEntry) => {
                        // Check for each term if it is already in the blacklist
                        if (!updatedSelectedTerms.includes(blacklistEntry.term)) {
                            updatedSelectedTerms = [...updatedSelectedTerms, blacklistEntry.term];
                        }
                    });
                }
            }
        }

        setSelectedTerms(updatedSelectedTerms);
    }

    return (
        <View>
            <DropDownSelector
                strong={true}
                type="small"
                value={sortingOrder}
                options={sortingOrderOptions}
                onChange={setSortingOrder}
            />
            <Pad />
            <ScrollView style={s.blacklistScrollView}>
                {sortedBlacklistEntries.length > 0 ? (
                    sortedBlacklistEntries.map((blacklistEntry, index) => (
                        <View key={blacklistEntry.term}>
                            {index > 0 && <Separator />}
                            <BlacklistItem
                                isSelected={selectedTerms.includes(blacklistEntry.term)}
                                onChangeSelection={({ isSelected, pressedShiftKey }) =>
                                    updateSelection({ blacklistEntry, isSelected, pressedShiftKey })
                                }
                                isSingularTerm={blacklistEntry.isSingularTerm}
                                hasAlternativeSpellings={blacklistEntry.hasAlternativeSpellings}
                                term={blacklistEntry.term}
                                blacklistEntries={blacklistEntries}
                                characterReplacements={characterReplacements}
                            />
                        </View>
                    ))
                ) : (
                    <Banner color={colorWhite}>
                        <UtilityText label="The blacklist is empty." />
                    </Banner>
                )}
            </ScrollView>
        </View>
    );
}

const BlacklistItemStyle = StyleSheet.create({
    item: {
        backgroundColor: colorWhite,
        gap: 8,
        paddingLeft: 8,
        paddingRight: 8,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        height: 48
    },
    itemStart: {
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: 8,
    },
    termVariants: {
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "flex-end",
        gap: 8,
        overflow: "hidden",
    },
    termVariant: {
        overflow: "hidden"
    },
    itemEnd: {
        flexDirection: "row-reverse",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: 8,
        paddingRight: 8,
        overflow: "visible",
        width: 40,
    },
});

function BlacklistItem({
    isSingularTerm = true,
    hasAlternativeSpellings = true,
    term = "Lorem Ipsum",
    isSelected = false,
    onChangeSelection,
    blacklistEntries,
    characterReplacements,
}) {
    const [spellingVariants, setSpellingVariants] = useState([term]);
    const preprocessedSpellingVariants = spellingVariants.join("\n|\n").split("\n");
    const [shouldShowEditModal, setShouldShowEditModal] = useState(false);
    const [shouldShowDeleteModal, setShouldShowDeleteModal] = useState(false);

    const editAccess = useHasCapability("moderation/edit-blacklist")

    useEffect(() => {
        if (hasAlternativeSpellings) {
            setSpellingVariants(generateAlternativeSpellings({ term, characterReplacements }));
        }
    }, [characterReplacements]);

    function onSelectBlacklistItem({ event }) {
        onChangeSelection({ isSelected: !isSelected, pressedShiftKey: event && event.shiftKey ? event.shiftKey : false });
    }
    function handleDeletedTerm() {
        onChangeSelection({ isSelected: false, pressedShiftKey: false });
    }

    const s = BlacklistItemStyle;

    return (
        <View style={s.item}>
            <View style={s.itemStart}>
                {editAccess && <View
                    onMouseDown={(event) => {
                        onSelectBlacklistItem({ event });
                    }}
                >
                    <Checkbox value={isSelected} onChange={() => {}} />
                </View>}
                <View style={s.termVariants}>
                    {preprocessedSpellingVariants.map((spellingVariant, index) => {
                        return (
                            <View style={s.termVariant} key={index}>
                                <UtilityText
                                    weight={index == 0 ? "strong" : "normal"}
                                    type="large"
                                    text={spellingVariant}
                                    key={index}
                                />
                            </View>
                        );
                    })}
                    {preprocessedSpellingVariants.length > 1 && (
                        <>
                            <UtilityText type="large" weight="normal" text="|" />
                            <UtilityText type="large" weight="normal" text="..." />
                        </>
                    )}
                </View>
            </View>

            {editAccess && <View style={s.itemEnd}>
                <Popup
                    popupContent={() => (
                        <BlacklistItemEditDeleteMenu
                            onEditPressed={() => setShouldShowEditModal(true)}
                            onDeletePressed={() => setShouldShowDeleteModal(true)}
                        />
                    )}
                >
                    <OverflowMenuHorizontal />
                </Popup>
                {isSingularTerm === true && (
                    <Tag emoji={"✍️"} color={colorPurpleBackground} label={"Singular Term"} />
                )}
            </View>}
            {shouldShowEditModal && (
                <BlacklistEditModal
                    term={term}
                    hasAlternativeSpellings={hasAlternativeSpellings}
                    isSingularTerm={isSingularTerm}
                    onClose={() => setShouldShowEditModal(false)}
                    blacklistEntries={blacklistEntries}
                />
            )}
            {shouldShowDeleteModal && (
                <BlacklistDeleteModal
                    termsToDelete={[term]}
                    onClose={() => setShouldShowDeleteModal(false)}
                    onTermsDeleted={() => handleDeletedTerm()}
                />
            )}
        </View>
    );
}