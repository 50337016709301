import { DemoSection } from "system/demo.js"
import { View } from "react-native-web"
import { Datastore } from "util/datastore.js"
import { TopicTeaserFeature, AdaptiveLayoutTopicTeaserWithComment, WideTopicTeaserWithComment, NarrowTopicTeaserWithComment, TopicInfo } from "../../feature/cbcrc/connect/TopicTeaserFeature"

export function TopicTeaserScreen() {
    const topic = {
        key: 1,
        from: 'a',
        text: 'Is Godzilla good?',

    }

    const commentPreview = {
        key: 1,
        from: 'a',
        text: 'I love Godzilla. He is so cute.',
        time: Date.now(),
        upvoteCount: 5
    }

    const collections = {
        derived_topicPreview: [
            { key: 1, commentCount: 1234, commentPreview, name: 'Is Godzilla good?' }
        ]
    }


    const emptyStateCollection = {
        derived_topicPreview: [
            { key: 1 }
        ]
    }

    const configWithGraphic = { ...TopicTeaserFeature.defaultConfig};

    // return null;
    return <View>
        <Datastore testState={{collections}}>
            <DemoSection text='Adaptive Layout Question Teaser With Comment'>
                <AdaptiveLayoutTopicTeaserWithComment topic={topic} commentPreview={commentPreview} commentCount={12} />
            </DemoSection>
            <DemoSection text='Wide Question Teaser With Comment'>
            <WideTopicTeaserWithComment header={'Topic of the moment'} icon={'star'} emptyState={'Be the first to share your thoughts'} countFormat={{ count: 5, singular: 'participant', plural: 'participants' }} title={topic.text} instanceKey={"topic"} commentPreview={commentPreview} commentCount={5} callToAction="Share your thoughts" />
            </DemoSection>
            <DemoSection text='Narrow Question Teaser With Comment'>
            <NarrowTopicTeaserWithComment header={'Topic of the moment'} icon={'star'} emptyState={'Be the first to share your thoughts'} countFormat={{ count: 5, singular: 'participant', plural: 'participants' }} title={topic.text} instanceKey={"topic"} commentPreview={commentPreview} commentCount={5} callToAction="Share your thoughts"/>
            </DemoSection>
        </Datastore>
        <Datastore testState={{collections: emptyStateCollection}} config={configWithGraphic}>
            <DemoSection text='Adaptive Empty State With Graphic'>
                <AdaptiveLayoutTopicTeaserWithComment topic={topic} />
            </DemoSection>
        </Datastore>

        <DemoSection text='Topic Info'>
            <TopicInfo header={'Topic of the moment'} icon={'star'} title={topic.text} emptyState={'Be the first to share your thoughts'} countFormat={{ count: 5, singular: 'participant', plural: 'participants' }}  structureKey={"topic"} commentCount={42} />
        </DemoSection>

    </View>
}
