import React from 'react';
import { StyleSheet, View } from 'react-native';
import { TopBar } from '../component/topbar';
import { IBMPlexSans_400Regular, IBMPlexSans_500Medium, IBMPlexSans_600SemiBold } from '@expo-google-fonts/ibm-plex-sans';
import { IBMPlexMono_400Regular, IBMPlexMono_500Medium, IBMPlexMono_600SemiBold } from '@expo-google-fonts/ibm-plex-mono';
import { ConfigContext, Datastore, WaitForData, useDatastore, useGlobalProperty, useIsLive, useLoaded, usePersonaKey, usePersonaPreview, useSiloKey } from '../util/datastore';
import { useFonts } from 'expo-font';
import { Catcher } from '../system/catcher';
import { specialScreens, structures } from '../structure/index.js';
import { assembleConfig, assembleScreenSet } from './features';
import { useFirebaseData, useFirebaseUser } from './firebase';
import { requireParams } from './util';
import { WithScreenStack } from './params';
import { OnboardingScreen } from 'structure/login';
import { personaA } from './testpersonas';
import { initializeGlobalSidebarTimers } from 'util/timers.js';
import { LoadingScreen } from '../component/basics';

export function useStandardFonts() {
    let [fontsLoaded] = useFonts({
        IBMPlexSans_400Regular,
        IBMPlexSans_500Medium,
        IBMPlexSans_600SemiBold,
        IBMPlexMono_400Regular,
        IBMPlexMono_500Medium,
        IBMPlexMono_600SemiBold
    });
    return fontsLoaded
}

function usePersonaPreviewForSilo({siloKey, once=true}) {
    const fbUser = useFirebaseUser();
    const personaPreview = useFirebaseData(
        ['silo', siloKey, 'structure', 'profile', 'instance', fbUser?.uid, 'global', 'preview']
    );
    if (!fbUser) return null;
    return personaPreview;
}

export function ScreenStack({url, screenStack, siloKey, structureKey, instanceKey, props}) {
    const s = ScreenStackStyle;
    
    const structure = getStructureForKey(structureKey);
    if (!structure) {
        throw new Error('Structure not found: ' + structureKey);
    }
    const readOnly = useFirebaseData(['silo', siloKey, 'structure', structureKey, 'instance', instanceKey, 'global', 'features', 'readonly'], {once: true, defaultValue: false}) ?? true;
    const activeFeatures = useFirebaseData(['silo', siloKey, 'structure', structureKey, 'instance', instanceKey, 'global', 'features'], {once: readOnly}) || [];
    const config = assembleConfig({structure, activeFeatures});
    const language = useFirebaseData(['silo', siloKey, 'module-public', 'language'], {once: readOnly})
    const screenSet = assembleScreenSet({structure, activeFeatures});
    const personaPreview = usePersonaPreviewForSilo({siloKey, once: readOnly});

    if (!structureKey || !instanceKey || !siloKey) {
        console.error('ScreenStack missing keys', {structureKey, instanceKey, siloKey});
    }

    return <View style={[s.stackHolder]}>
        <Datastore siloKey={siloKey} instanceKey={instanceKey} structureKey={structureKey} 
                language={language} isLive={true} config={config} readOnly={readOnly}
                personaPreview={personaPreview} {...props} >
            {screenStack.map((screenInstance, index) => 
                <WithScreenStack url={url} screenStack={screenStack} index={index} key={index}>
                    <StackedScreen screenSet={screenSet} screenInstance={screenInstance} index={index} />
                </WithScreenStack>
            )}
        </Datastore>
    </View>
}
  
const ScreenStackStyle = StyleSheet.create({
    stackHolder: {
        position: 'absolute',
        top: 0, bottom: 0, left: 0, right: 0,
        // backgroundColor: 'white',
    }
})

export function EmbeddedInstance({structureKey, instanceKey, features, screenKey}) {
    const isLive = useIsLive();
    const siloKey = useSiloKey();
    const datastore = useDatastore();

    const structure = getStructureForKey(structureKey);
    if (!structure) {
        console.error('Structure not found', {structureKey});
    }

    const readOnly = useFirebaseData(['silo', siloKey, 'structure', structureKey, 'instance', instanceKey, 'global', 'features', 'readonly'], {once: true, defaultValue: false}) ?? true;
    const selectedFeatures = useFirebaseData(['silo', siloKey, 'structure', structureKey, 'instance', instanceKey, 'global', 'features'], {once: readOnly}) || [];
    const activeFeatures = {...selectedFeatures, ...features};
    const config = assembleConfig({structure, activeFeatures});
    const screenSet = assembleScreenSet({structure, activeFeatures});
    const screen = getScreen({screenSet, structure, screenKey, instanceKey});

    if (!isLive) {
        const allInstanceData = datastore.getEmbeddedInstanceData();
        const thisInstanceData = allInstanceData?.[structureKey]?.[instanceKey];
        if (!thisInstanceData) {
            console.error('Embedded instance data not found for test', {allInstanceData, structureKey, instanceKey});
        }
        const testState = {...datastore.props.testState, ...thisInstanceData};
        const extendedInstanceData = {...datastore.props, testState, config, structureKey, instanceKey, isLive};
        return <Datastore {...extendedInstanceData}>
            {React.createElement(screen)}
        </Datastore>
    } else {        
        return <Datastore siloKey={siloKey} structureKey={structureKey} instanceKey={instanceKey} 
                config={config} isLive={isLive} isEmbedded={true}
                personaPreview={datastore.props.personaPreview}
                language={datastore.props.language} readOnly={datastore.props.readOnly} >
            {React.createElement(screen)}
        </Datastore>
    }
}

export function StructureDemo({
        siloKey='demo', structureKey, instanceKey='demo', screenKey, params={},
        features,  
        testState, 
        personaPreview=personaA,
        language='english',
        roles=['Owner']
    }) {
    requireParams('StructureDemo', {structureKey});
    const datastore = useDatastore();
    const [screenStack, setScreenStack] = React.useState([{siloKey: 'demo', structureKey, instanceKey, screenKey, params}]);

    function pushSubscreen(screenKey, params) {
        setScreenStack([...screenStack, {siloKey: 'demo', structureKey, instanceKey, screenKey, params}]);
    }
    function gotoInstance({structureKey, instanceKey, screenKey, params}) {
        setScreenStack([{siloKey: 'demo', structureKey, instanceKey, screenKey, params}]);
    }

    function goBack() {
        if (screenStack.length == 1) {
            datastore.goBack();
        } else {
            setScreenStack(screenStack.slice(0, screenStack.length-1));
        }
    }

    const extendedTestState = {...testState, goBack, roles, isInSidebar: false, pushSubscreen, gotoInstance, globals: {...testState?.globals, features}};

    return <Datastore siloKey={siloKey} structureKey={structureKey} instanceKey={instanceKey}
            testState={extendedTestState} isLive={false} language={language} personaPreview={personaPreview}>
        <StructureDemoConfiguredScreenStack structureKey={structureKey} screenStack={screenStack}/>
    </Datastore>
}

// This has to be inside the Datastore, to allow the user to change the 
// features (in the datastore) while playing with the demo.
export function StructureDemoConfiguredScreenStack({structureKey, screenStack}) {
    const s = ScreenStackStyle;

    const activeFeatures = useGlobalProperty('features');
    const structure = getStructureForKey(structureKey);
    const screenSet = assembleScreenSet({structure, activeFeatures});
    const config = assembleConfig({structure, activeFeatures});

    return <View style={s.stackHolder}>
        <ConfigContext.Provider value={config}>
            {screenStack.map((screenInstance, index) =>
                <WithScreenStack screenStack={screenStack} index={index} key={index}>
                    <StackedScreen screenSet={screenSet} screenInstance={screenInstance} index={index} key={index} />
                </WithScreenStack>
            )}  
        </ConfigContext.Provider>
    </View>
}


export function StackedScreen({screenSet, screenInstance, index}) {
    const {structureKey, instanceKey, screenKey, params} = screenInstance;
    const loaded = useLoaded();
    const datastore = useDatastore();
    const isSpecialScreen = specialScreens.includes(screenKey);

    if (datastore && loaded && !isSpecialScreen) {
        initializeGlobalSidebarTimers(datastore);
    }

    const personaKey = usePersonaKey();
    const personaPreview = usePersonaPreview();
    const needsSetup = personaKey && !personaPreview?.name && !isSpecialScreen;

    const structure = getStructureForKey(structureKey);
    var screen = getScreen({screenSet, structure, screenKey, instanceKey});
  
    if (!screen) {
        if (loaded) { // Don't show this error waiting for screen set to resolve
            console.error('Screen not found', {loaded, screenSet, structure: structure, screenKey, instanceKey, screen});
        }        
        return <FullScreen zIndex={index} transparent={isSpecialScreen}>
            <LoadingScreen label='Screen not found'/>
        </FullScreen>;
    }

    return <FullScreen zIndex={index} transparent={isSpecialScreen}>
            {!isSpecialScreen && <TopBar preventBack={needsSetup}/>}
                <WaitForData>
                    <Catcher>
                        {needsSetup && <OnboardingScreen />}
                        {!needsSetup && React.createElement(screen, params)}
                    </Catcher>
                </WaitForData>
    </FullScreen>
}  

function getScreen({screenSet, structure, screenKey}) {
    if (!screenKey) {
        return structure.screen;
    } else if (screenKey == 'teaser') {
        return structure.teaser;
    } else if (screenKey == 'overlayScreen') {
        return structure.overlayScreen;
    } else {
        return screenSet[screenKey];
    }
}
  
function FullScreen({children, zIndex=0, transparent=false}) {
    const s = FullScreenStyle;
    return <View style={[s.fullScreen, {zIndex, backgroundColor: transparent ? 'transparent' : 'white'}]}>{children}</View>
}

const FullScreenStyle = StyleSheet.create({
    fullScreen: {
        position: 'absolute',
        top: 0, bottom: 0, left: 0, right: 0,
    }
})
  
  
export function getStructureForKey(key) {
    if (!key) return null;
    return structures.find(structure => structure.key === key);
}
  
  