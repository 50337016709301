import { Close } from "@carbon/icons-react";
import React, { useContext, useEffect, useState } from "react";
import { StyleSheet } from "react-native";
import { View } from "react-native-web";
import { colorBlack, colorNearBlack, colorPinkBackground, colorWhite } from "../color";
import { UtilityText } from "../text";
import { BreadCrumb } from "../button";
import { useSessionData } from "../../util/datastore";

export function ToastRenderer() {
    const toast = useSessionData("toast");

    const [toastVisible, setToastVisible] = useState(false)

    useEffect(() => {
        if (toast?.visible) {
            setToastVisible(true)
            const timeout = setTimeout(() => {
                setToastVisible(false)
            }, toast.duration ?? 10000) // 10 Seconds default
            return () => {
                clearTimeout(timeout)
            }
        }
    }, [toast])

    if(!toast) {
        return null;
    }
    
    return <Toast 
        text={toast.text}
        label={toast.label}
        formatParams={toast.formatParams}
        type={toast.type}
        visible={toastVisible}
        onClose={()=>{setToastVisible(false)}}
     />
}


const ToastStyle = StyleSheet.create({
    toast: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "fit-content",
        justifyContent: "space-between",
        padding: 20,
        gap: 12,
        height: "min-height",
        zIndex: 10000,
        borderRadius: 8,
        boxShadow: '5px 2px 10px rgba(0, 0, 0, 0.1)',
        minWidth: 335
    },
    toastError: {
        backgroundColor: colorPinkBackground,
        color: colorBlack
    },
    toastInfo: {
        backgroundColor: colorBlack,
        color: colorWhite
    }

})

export function Toast({label="", text = "", formatParams={}, type="info", visible = false, onClose}) {
    const s = ToastStyle;
    const textColor = type === "error" ? colorNearBlack : colorWhite
    return visible === true ? (<View style={[s.toast, type === "error" ? s.toastError : s.toastInfo]} >
        <UtilityText type="small" label={label} text={text} formatParams={formatParams} color={textColor} />
        <BreadCrumb testID={"toast-close"} icon={Close} iconProps={{ color: textColor , size: 28 }} onPress={onClose}/>
    </View>)
        : null
}

export function setToastMessage({datastore, label, text, formatParams, type="info", duration=10000, visible=true}) {
    datastore.setSessionData("toast", {text, label, type, duration, formatParams, visible})
}