// TODO: Discuss and define good data models
// Template: Just the general structure of the voting. No results or state
export const endometriosisVideoVotingTemplateDE = {
    name: "Endometriosis",
    key: "endometriosis-template",
    chapters: [
        {
            key: "chapter-0",
            name: "Chapter 1"
        },
        {
            key: "chapter-1",
            name: "Chapter 2"
        }
    ],
    questions: [{
        text: "Hast du schon mal von Endometriose gehört?",
        key: "endometriosis-template-question-0",
        type: 'poll',
        timestamp: 0,
        initial: true,
        options: [
            {
                key: "endometriosis-template-question-0-option-0",
                text: "Ja"
            },
            {
                key: "endometriosis-template-question-0-option-1",
                text: "Nein"
            }
        ]
    },
    {
        text: "Wie wichtig ist es für Betroffene, dass die Erkrankung und der damit einhergehende Leidensdruck anerkannt wird?",
        timestamp: 638,
        key: "endometriosis-template-question-1",
        type: 'poll',
        options: [
            {
                key: "endometriosis-template-question-1-option-0",
                text: "Sehr wichtig, da Endometriose den Alltag der Betroffenen sehr einzuschränken scheint."
            },
            {
                key: "endometriosis-template-question-1-option-1",
                text: "Wichtig, wie bei allen anderen Erkrankungen auch."
            }
        ]
    },
    {
        text: 'Ist es richtig, dass die Regierung Geld in die Erforschung von "Endometriose" investiert?',
        timestamp: 720,
        key: "endometriosis-template-question-2",
        type: 'poll',
        options: [
            {
                key: "endometriosis-template-question-2-option-0",
                text: "Ja"
            },
            {
                key: "endometriosis-template-question-2-option-1",
                text: "Nein"
            },
            {
                key: "endometriosis-template-question-2-option-2",
                text: "Weiß nicht"
            }
        ]
    },
    {
        text: "Wie wichtig findest du es, dass mehr Menschen über Endometriose Bescheid wissen?",
        key: "endometriosis-template-question-3",
        type: 'poll',
        timestamp: 750,
        final: true,
        options: [
            {
                key: "endometriosis-template-question-3-option-0",
                text: "Sehr wichtig"
            },
            {
                key: "endometriosis-template-question-3-option-1",
                text: "Wichtig"
            },
            {
                key: "endometriosis-template-question-3-option-2",
                text: "Nicht wichtig"
            }
        ]
    }]
}

export const endometriosisVideoVotingTemplateEN = {
    name: "Endometriosis",
    key: "endometriosis-template",
    chapters: [
        {
            key: "chapter-0",
            name: "Chapter 1"
        },
        {
            key: "chapter-1",
            name: "Chapter 2"
        }
    ],
    questions: [{
        text: "Have you ever heard of endometriosis?",
        key: "endometriosis-template-question-0",
        type: 'poll',
        timestamp: 0,
        initial: true,
        options: [
            {
                key: "endometriosis-template-question-0-option-0",
                text: "Yes"
            },
            {
                key: "endometriosis-template-question-0-option-1",
                text: "No"
            }
        ]
    },
    {
        text: "How important is it to you that this illness and the suffering of those affected get recognized?",
        timestamp: 638,
        key: "endometriosis-template-question-1",
        type: 'poll',
        options: [
            {
                key: "endometriosis-template-question-1-option-0",
                text: "Very important, because endometriosis restricts the daily lives of those affected"
            },
            {
                key: "endometriosis-template-question-1-option-1",
                text: "Important, as with every illness"
            }
        ]
    },
    {
        text: "Is it right for the government to invest money in research into endometriosis?",
        timestamp: 720,
        key: "endometriosis-template-question-2",
        type: 'poll',
        options: [
            {
                key: "endometriosis-template-question-2-option-0",
                text: "Yes"
            },
            {
                key: "endometriosis-template-question-2-option-1",
                text: "No"
            },
            {
                key: "endometriosis-template-question-2-option-2",
                text: "I don't know"
            }
        ]
    },
    {
        text: "How important do you think it is that more people know about endometriosis?",
        key: "endometriosis-template-question-3",
        type: 'poll',
        timestamp: 750,
        final: true,
        options: [
            {
                key: "endometriosis-template-question-3-option-0",
                text: "Very important"
            },
            {
                key: "endometriosis-template-question-3-option-1",
                text: "Important"
            },
            {
                key: "endometriosis-template-question-3-option-2",
                text: "Not that important"
            }
        ]
    }]
}

export const howWeWantToLoveVideoVotingTemplateDE = {
    name: "Wie wir lieben wollen",
    key: "howWeWantToLove-template",
    questions: [{
        text: "Kannst du andere Leute verstehen, die ihre Beziehung öffnen?",
        key: "howWeWantToLove-template-question-0",
        timestamp: 0,
        type: "connected",
        subQuestions: [
            {
                initial: true,
                timestamp: 181.5,
                key: "howWeWantToLove-template-question-0-0",
            },
            {
                timestamp: 535,
                key: "howWeWantToLove-template-question-0-1",
            },
            {
                timestamp: 1136,
                key: "howWeWantToLove-template-question-0-2",
            },
            {
                timestamp: 1461,
                key: "howWeWantToLove-template-question-0-3",
            },
            {
                timestamp: 1675,
                key: "howWeWantToLove-template-question-0-4",
                final: true
            }
        ],
        options: [
            {
                key: "howWeWantToLove-template-question-0-option-0",
                text: "Ja"
            },
            {
                key: "howWeWantToLove-template-question-0-option-1",
                text: "Weiß nicht"
            },
            {
                key: "howWeWantToLove-template-question-0-option-2",
                text: "Nein"
            }
        ]
    }]
}

export const howWeWantToLoveVideoVotingTemplateEN = {
    name: "How we want to love",
    key: "howWeWantToLove-template",
    questions: [{
        text: "Can you understand other people who open up their relationship?",
        key: "howWeWantToLove-template-question-0",
        timestamp: 0,
        type: "connected",
        subQuestions: [
            {
                initial: true,
                timestamp: 181.5,
                key: "howWeWantToLove-template-question-0-0",
            },
            {
                timestamp: 535,
                key: "howWeWantToLove-template-question-0-1",
            },
            {
                timestamp: 1136,
                key: "howWeWantToLove-template-question-0-2",
            },
            {
                timestamp: 1461,
                key: "howWeWantToLove-template-question-0-3",
            },
            {
                timestamp: 1675,
                key: "howWeWantToLove-template-question-0-4",
                final: true
            }
        ],
        options: [
            {
                key: "howWeWantToLove-template-question-0-option-0",
                text: "Yes"
            },
            {
                key: "howWeWantToLove-template-question-0-option-1",
                text: "Don't know"
            },
            {
                key: "howWeWantToLove-template-question-0-option-2",
                text: "No"
            }
        ]
    }]
}

export const godzillaVideoVotingTemplateIndividual = {
    name: "How Much Do You Actually Know About Godzilla?",
    key: "godzilla-template-i",
    questions: [
        {
            text: "Do you believe Godzilla is the ultimate king of monsters?",
            key: "godzilla-template-question-0",
            timestamp: 0,
            initial: true,
            options: [
                {
                    key: "godzilla-template-question-0-option-0",
                    text: "Yes, all hail the King!"
                },
                {
                    key: "godzilla-template-question-0-option-1",
                    text: "Nah, Mothra’s got my vote."
                }
            ]
        },
        {
            text: "How important is it that the world recognizes the destruction Godzilla causes?",
            timestamp: 600,
            key: "godzilla-template-question-1",
            options: [
                {
                    key: "godzilla-template-question-1-option-0",
                    text: "Extremely important, cities aren't cheap to rebuild!"
                },
                {
                    key: "godzilla-template-question-1-option-1",
                    text: "Eh, it's just a bit of property damage, no biggie."
                }
            ]
        },
        {
            text: "Should the government fund research to understand why Godzilla has such a bad temper?",
            timestamp: 720,
            key: "godzilla-template-question-2",
            options: [
                {
                    key: "godzilla-template-question-2-option-0",
                    text: "Yes, maybe Godzilla just needs a hug."
                },
                {
                    key: "godzilla-template-question-2-option-1",
                    text: "No, we've got bigger issues—like actual volcanoes."
                },
                {
                    key: "godzilla-template-question-2-option-2",
                    text: "I don't know, ask Dr. Serizawa."
                }
            ]
        },
        {
            text: "How important is it for more people to know about Godzilla's legendary status?",
            key: "godzilla-template-question-3",
            timestamp: 750,
            final: true,
            options: [
                {
                    key: "godzilla-template-question-3-option-0",
                    text: "Super important, long live the legend!"
                },
                {
                    key: "godzilla-template-question-3-option-1",
                    text: "Important, but maybe we could let him chill in the ocean."
                },
                {
                    key: "godzilla-template-question-3-option-2",
                    text: "Not important, I'm more of a King Kong person."
                }
            ]
        }
    ]
}

export const godzillaVideoVotingTemplateConnected = {
    name: "Could You Handle a Godzilla Rampage?",
    key: "godzillaRampage-template",
    questions: [{
        text: "Could you keep your cool during a Godzilla rampage?",
        key: "godzillaRampage-template-question-0",
        type: "connected",
        subQuestions: [
            {
                initial: true,
                key: "godzillaRampage-template-question-0-0",
                timestamp: 180
            },
            {
                key: "godzillaRampage-template-question-0-1",
                timestamp: 535
            },
            {
                key: "godzillaRampage-template-question-0-2",
                timestamp: 1130
            },
            {
                key: "godzillaRampage-template-question-0-3",
                timestamp: 1460,
                final: true
            }
        ],
        options: [
            {
                key: "godzillaRampage-template-question-0-option-0",
                text: "Yes"
            },
            {
                key: "godzillaRampage-template-question-0-option-1",
                text: "Maybe"
            },
            {
                key: "godzillaRampage-template-question-0-option-2",
                text: "Nope"
            }
        ]
    }]
}
