import { ConversationScreen, Pad, PadBox } from "component/basics"
import { CTAButton, TextButton } from "component/button"
import { Heading } from "component/text"
import { useModulePublicData } from "util/datastore"
import { gotoInstanceScreen, pushSubscreen } from "../../../util/navigate"
import { VideoVotingEditor } from "../../../component/zdf/videovoting/videovotingEditor"
import { useScreenParams } from "../../../util/params"

export const VideoVotingListFeature = {
    name: 'Video Voting List',
    key: 'videovotinglist',
    subscreens: {
        videovotinglist: VideoVotingListScreen,
        createVoting: VideoVotingCreationScreen,
    },
    config: {
        quickLinks: [
            { label: 'Video Votings', screenKey: 'videovotinglist' },
        ]
    },
}

export function VideoVotingListScreen() {

    const votings = useModulePublicData("videovoting");

    const openVoting = (votingKey) => {
        gotoInstanceScreen({ structureKey: "videovoting", instanceKey: votingKey, screenKey: "edit" })
    }
    return <ConversationScreen pad>
        <Pad size={40} />
        <Heading type='large' label='Votings' />
        <Pad />
        <CTAButton onPress={() => { pushSubscreen("createVoting") }} label={"Create a new voting"} />
        <Pad />
        {Object.values(votings ?? {}).map((voting) => {
            return <PadBox key={voting.key} vert={10}>
                <TextButton key={voting.key} text={voting.name} onPress={() => { openVoting(voting.key) }} />
            </PadBox>
        })}
    </ConversationScreen>
}

export function VideoVotingCreationScreen() {
    const params = useScreenParams();
    const voting = useModulePublicData("videovoting", [params.key]);
    return <ConversationScreen pad>
        <Heading type="large" label={"Create a new voting"} />
        <VideoVotingEditor key={voting?.key ?? "new"} voting={voting} />
    </ConversationScreen>
}