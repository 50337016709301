import { useState } from "react";
import { StyleSheet } from "react-native";
import { View } from "react-native-web";
import { DocumentLevelComponent } from "../../platform-specific/popup";
import { UtilityText } from "../text";
import { colorWhite } from "../color";
import { ShadowBox } from "../basics";

const tooltipStyle = StyleSheet.create({
    toolTip: {
        position: "absolute",
        flexDirection: "column",
        height: "fit-content",
        width: "fit-content",
        borderRadius: 8,
        padding: 12,
        backgroundColor: colorWhite,
        boxShadow: "0px 10px 24px rgba(0, 0, 0, 0.1)",
        zIndex: 1
    },
    tooltipContainer: {
        width: "100%"
    }
})

export function ToolTip({ toolTipWidget, label, children, disabled, offset = 0 }) {
    const [isHovering, setHover] = useState(false)
    return (<View style={tooltipStyle.tooltipContainer} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
        {isHovering && !disabled && <View style={{position: "relative"}}>
            <View style={[tooltipStyle.toolTip, {bottom: offset}]}> 
                {toolTipWidget || <UtilityText label={label} />}  
            </View>
        </View>}
        {children}
    </View>
    )
}

const DocumentLevelTooltipStyle = StyleSheet.create({
    hoverableElement: {
        width: "fit-content",
    },
    tooltipContainer: {
        position: "absolute",
        width: "fit-content",
        zIndex: 2000,
    },
    tooltipBase: {
        position: "absolute",
    },
    defaultTooltip: {
        flexDirection: "column",
        borderRadius: 8,
        padding: 12,
        backgroundColor: colorWhite,
        zIndex: 1,
    },
});

export function DocumentLevelTooltip({ tooltipWidget = null, label, children, disabled, offset = 0, width = null }) {
    const s = DocumentLevelTooltipStyle;

    const [isHovering, setIsHovering] = useState(false);
    const [pos, setPos] = useState({ x: null, y: null });

    return (
        <View
            style={s.hoverableElement}
            onMouseEnter={(e) => {
                setPos({ x: e.clientX, y: e.clientY });
                setIsHovering(true);
            }}
            onMouseLeave={() => setIsHovering(false)}
        >
            {isHovering && !disabled && (
                <DocumentLevelComponent>
                    <View
                        style={[
                            s.tooltipContainer,
                            {
                                left: pos.x,
                                top: pos.y,
                                width: width || "fit-content",
                            },
                        ]}
                    >
                        <View style={[s.tooltipBase, { bottom: offset }]}>
                            {tooltipWidget || (
                                <ShadowBox>
                                    <View style={s.defaultTooltip}>
                                        <UtilityText label={label} />
                                    </View>
                                </ShadowBox>
                            )}
                        </View>
                    </View>
                </DocumentLevelComponent>
            )}
            {children}
        </View>
    );
}