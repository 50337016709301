import { useEffect } from 'react';
import { closeSidebar, useLiveUrl } from 'util/navigate.js';
import { getScreenStackForUrl, gotoInstance } from 'util/navigate.js';
import { ScreenStack, getStructureForKey, useStandardFonts } from 'util/instance.js';
import { addDefaultFeatures, addFeatures } from 'feature/index.js';
import { newDefaultFeatures, newFeatures, newRoles } from './feature';
import { setFirebaseConfig } from 'util/firebase.js';
import { extendRoles } from 'component/admin.js';
import { Text } from 'react-native';
import { registerLoginProviders } from 'structure/login.js';
import { cbcIntLogin, cbcLogin, rcIntLogin, rcLogin, rtbfLogin, zdfIntLogin } from 'util/loginproviders.js';
import useSSOLoginFromParent from './util/useSSOLoginFromParent';
import { FocusLoopView } from 'system/focustrap';
import { KeyboardShortcutHandler } from 'platform-specific/keyboardShortcutHandler';
import { useSetDocumentLang } from 'platform-specific/useSetDocumentLang';
import { getDeploymentConfig } from 'util/util';

const deploymentConfig = getDeploymentConfig();
console.log('Using server host', deploymentConfig.host);

setFirebaseConfig(deploymentConfig);
addFeatures(newFeatures);
addDefaultFeatures(newDefaultFeatures);
extendRoles(newRoles);
registerLoginProviders([rcIntLogin, rcLogin, cbcLogin, cbcIntLogin, zdfIntLogin, rtbfLogin]);

export default function App() {
    useStandardFonts();
    const url = useLiveUrl();
    const {siloKey, structureKey, instanceKey, screenStack} = getScreenStackForUrl(url);
    const structure = getStructureForKey(structureKey);

    console.log('App', { url, siloKey, structureKey, instanceKey, screenStack, structure });
    useSetDocumentLang(siloKey);
    useSSOLoginFromParent(siloKey);
    useEffect(() => {
        if (deploymentConfig.defaultToAdminDash && !structureKey) {
            gotoInstance({structureKey: 'admin', instanceKey: 'one'});
        }
    }, [url]);

    if (!structureKey) {
        return <Error message='You need a valid space URL to see a space' />
    } else if (!structure) {
        return <Error message={'Unknown structure: ' + structureKey} />
    } else if (!instanceKey) {
        return <Error message='Missing instance key' />
    } else {
        if (structure.hasFocusTrap) {
            return <FocusLoopView onCloseLoop={closeSidebar} index={0}>
                <ScreenStack url={url} screenStack={screenStack} siloKey={siloKey} structureKey={structureKey} instanceKey={instanceKey} />
                <KeyboardShortcutHandler />
            </FocusLoopView>;
        }
        else return <ScreenStack url={url} screenStack={screenStack} siloKey={siloKey} structureKey={structureKey} instanceKey={instanceKey} />;

    }
}

function Error({message}) {
    return <Text>{message}</Text>
}
