import { ConversationScreen, HorizBox, HoverView, Pad, PadBox, Separator, WindowTitle } from "component/basics.js"
import { Heading, Paragraph, TextField, UtilityText } from "component/text.js"
import { useModerationAuthors, useModerationQueue } from "../component/moderation";
import { CTAButton, IconButton, Tag } from "component/button.js"
import { useState } from "react";
import { CountedFilter } from "../component/filter";
import { View } from "react-native";
import { useIsAdmin } from "component/admin.js"
import { useDatastore, useSessionData } from "util/datastore.js"
import { colorBlueBackground, colorGreyBorder, colorGreyPopupBackground, colorLightGreen, colorPink } from "component/color.js"
import { Byline } from "component/people.js"
import { StyleSheet } from "react-native";
import { ArrowUpRight, Edit } from "@carbon/icons-react";
import { Banner } from "component/banner.js"
import { RichText } from "component/richtext.js"
import { useConfig } from "util/features.js"

export const ModerationDashStructure = {
    key: 'moderationdash',
    name: 'Moderation Dashboard',
    screen: ModerationDashScreen,
    defaultConfig:{pageTopWidgets: []},
    capabilities: ['judge'],
}

export function ModerationDashScreen() {
    const queue = useModerationQueue();
    const modAuthors = useModerationAuthors();
    const isAdmin = useIsAdmin();
    const titleLabel = isAdmin ? 'Moderation Queue' : 'Moderation Transparency Dashboard';
    const [stateFilter, setStateFilter] = useState('awaiting');
    const filteredQueue = filterQueue({queue, selection: stateFilter});
    const config = useConfig()
    return <ConversationScreen>
        <WindowTitle label={titleLabel} />
        {config.pageTopWidgets?.map((Item, index)=> (<Item key={index}/>))}
        <Pad />
        <Heading label={titleLabel} />
        <Pad />
        <ModFilter queue={queue} selection={stateFilter} setSelection={setStateFilter} />
        <Pad />
        {filteredQueue.map((modTask, idx) => 
            <ModTask key={modTask.key} idx={idx}modTask={modTask} modAuthor={modAuthors?.[modTask.key]} />
        )}
        <Pad size={80} />
    </ConversationScreen>
}

function ModFilter({queue, setSelection, selection}) {
    return <View>
        <CountedFilter label='Awaiting Decision' value='awaiting' 
            count={queue.filter(item => !item.judgement).length} 
            maxCount={queue.length}
            selection={selection} onChangeSelection={setSelection} />
        <Pad size={10} />
        <CountedFilter label='Approved' value='approve' 
            count={queue.filter(item => item.judgement == 'approve').length} 
            maxCount={queue.length}
            selection={selection} onChangeSelection={setSelection} />
        <Pad size={10} />
        <CountedFilter label='Rejected' value='reject' 
            count={queue.filter(item => item.judgement == 'reject').length} 
            maxCount={queue.length}
            selection={selection} onChangeSelection={setSelection} />

    </View>
}

function filterQueue({queue, selection}) {
    if (selection == 'awaiting') {
        return queue.filter(item => !item.judgement);
    } else if (selection == 'approve') {
        return queue.filter(item => item.judgement == 'approve');
    } else if (selection == 'reject') {
        return queue.filter(item => item.judgement == 'reject');
    } else {
        return queue;
    }
}

function ModTask({modTask, modAuthor, idx}) {
    const isAdmin = useIsAdmin();
    const selectedIdx = useSessionData(['modTask-selected-idx']);
    const isSelected = selectedIdx == idx;   
    const datastore = useDatastore();
    function onSelect() {
        datastore.setSessionData(['modTask-selected-idx'], idx);
    }

    return <PadBox top={20}><HoverSelectBox testID={modTask.key} selected={isSelected} onPress={isAdmin && onSelect}>       
        <HorizBox center spread>
            <JudgementTag modTask={modTask} idx={idx} />
            <HorizBox>
                <GotoThreadAction modTask={modTask} />
            </HorizBox>
        </HorizBox>
        <Pad />
        {isAdmin && modAuthor && <PadBox bottom={20}>
            <Byline type='large' userId={modAuthor.from} name={modAuthor.authorName} 
                time={modTask.time} photo={modAuthor.authorPhoto} />
        </PadBox>}
        <Paragraph numberOfLines={isSelected ? null : 3} type='small' text={modTask.text} />
        {isSelected &&
            <PadBox vert={20}><Banner color={colorGreyPopupBackground}>
                <RichText type='small' label='**Message to moderator**: {appealText}' formatParams={{appealText: modTask.appealText}} />
            </Banner></PadBox>
        }
        {isAdmin && isSelected && <Separator />}
        {isAdmin && isSelected && <PadBox top={20}><AdminCommands modTask={modTask} idx={idx} /></PadBox>}
    </HoverSelectBox></PadBox>    
}   

function HoverSelectBox({selected, onPress, testID, children}) {
    const s = HoverSelectBoxStyle;
    return <HoverView testID={testID} onPress={onPress} disabled={!onPress || selected} style={s.outline} hoverStyle={s.hover}>
        {children}
    </HoverView>
}
const HoverSelectBoxStyle = StyleSheet.create({
    hover: {
        borderColor: colorGreyBorder,
        borderWidth: 1,
        borderRadius: 8,
        padding: 20,
        boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.10)',
    },
    outline: {
        borderColor: colorGreyBorder,
        borderWidth: 1,
        borderRadius: 8,
        padding: 20
    }
})

function GotoThreadAction({modTask}) {
    const datastore = useDatastore();
    return <IconButton icon={ArrowUpRight} onPress={() => 
        datastore.gotoInstance({structureKey: modTask.structureKey, instanceKey: modTask.instanceKey})}
    />
}

function EditAction({idx}) {
    const datastore = useDatastore();
    function onPress() {
        datastore.setSessionData(['modTask-selected-idx'], idx);
    }

    return <IconButton icon={Edit} onPress={onPress} />
}

function JudgementTag({modTask}) {
    if (modTask.judgement) {
        const isReject = modTask?.judgement == 'reject';
        return <Tag label={isReject ? 'Rejected' : 'Approved'} color={isReject ? colorPink : colorLightGreen} />
    } else {
        return <Tag label='Awaiting Decision'  color={colorBlueBackground} />
    }
}

function AdminCommands({modTask, idx}) {
    const [reasoning, setReasoning] = useState('');
    const [inProgress, setInProgress] = useState(false);
    const datastore = useDatastore();
    async function setJudgement(judgement) {
        setInProgress(true);
        await datastore.callServerAsync('moderation', 'setJudgement', {
            key: modTask.key, judgement, reasoning: reasoning || modTask.reasoning
        });
        datastore.setSessionData(['modTask-selected-idx'], idx+1)
        setInProgress(false);
    }
    return <View>
        <UtilityText label='Moderator Explanation' caps weight='medium' />
        <Pad size={10} />
        {/* <Pad /> */}
        <HorizBox center spread>
            <View style={{flex: 1}}>
                <TextField testID='explanation' value={reasoning || modTask.reasoning} onChange={setReasoning} placeholder='Explain your decision...' />
            </View>
            <Pad />
            <HorizBox>
                <CTAButton disabled={inProgress} type='delete' label='Reject' onPress={() => setJudgement('reject')} />
                <Pad />
                <CTAButton disabled={inProgress} label='Approve' onPress={() => setJudgement('approve')}/>
            </HorizBox>

        </HorizBox>
    </View>
}
